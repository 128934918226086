/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import * as S from '../styles/MainContainer.style';
import MiniChart from '../components/MiniChart';
import SlidePost from '../components/SlidePost';
import TabList from '../components/TabList';
import node from '../utils/socket';
import {
  setChartHotList,
  setChartNewList,
  setCoinList,
} from '../store/slices/chart';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const settings = {
  dots: true,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: true,
};
function MainContainer() {
  const dispatch = useDispatch();

  const { chartHotList, chartNewList, coinList } = useSelector(
    state => state.chart,
  );
  const viewCoinType = 'tra';

  const { t } = useTranslation();

  // 소켓 데이터
  useEffect(() => {
    node.get_coin_price_info_main(function (data) {
      // const coinDatas = data;
      // coinDatas.forEach(function (_data, index) {
      //   if (index >= 10) return;
      //   const prcentPrice = _data.last_price - _data.yesterday_price;
      //   let prcent =
      //     ((_data.last_price - _data.yesterday_price) / _data.yesterday_price) *
      //     100;

      //   if (prcent === 'Infinity') {
      //     prcent = 0;
      //   }
      //   prcent = prcent.toFixed(2);

      //   let prcentColorCode = '';
      //   let prcentString = '';

      //   if (prcentPrice < 0) {
      //     prcentString = '';
      //     prcentColorCode = 'blue';
      //   } else {
      //     prcentString = '+';
      //     prcentColorCode = 'red';
      //   }

      //   if (prcent === 'NaN' || prcentPrice === '0') {
      //     prcentString = '';
      //     prcentColorCode = 'red';
      //     prcent = '0.00';
      //   }

      //   _data.prcentString = prcentString || null;
      //   _data.prcent = prcent;
      //   _data.prcentColorCode = prcentColorCode;
      // });

      const tData = [];

      data.forEach(function (_data, index) {
        if (index >= 10) return;
        if (_data.ccs_view_main === '1' && _data.type2 === viewCoinType) {
          tData.push(_data);
        }
      });

      getHotList(tData);
      getNewList(tData);
      dispatch(setCoinList(tData));
    });

    const getHotList = datas => {
      const arr = datas.sort((a, b) => {
        return b.amount - a.amount;
      });
      dispatch(setChartHotList(arr));
    };
    const getNewList = datas => {
      const arr = datas.sort(function (a, b) {
        return a.ccs_id > b.ccs_id ? -1 : a.ccs_id < b.ccs_id ? 1 : 0;
      });
      arr.sort(function (a, b) {
        return a.ccs_new_order > b.ccs_new_order
          ? -1
          : a.ccs_new_order < b.ccs_new_order
          ? 1
          : 0;
      });
      dispatch(setChartNewList(arr));
    };
  }, []);

  return (
    <>
      {/* <S.FullBgImg>
        <div>
          <p>{t('main1')}</p>
          <p>{t('main2')}</p>
        </div>
      </S.FullBgImg> */}
      <Slider {...settings}>
        <S.SlideWrap text={1}>
          <img src="/images/wadex_main2.jpg" alt="" />
          <div className="text">
            <p>Global Star Link & Investment</p>
            <p>Now Invest in Your Star</p>
          </div>
        </S.SlideWrap>
        <S.SlideWrap text={2}>
          <img src="/images/bg_visual.png" alt="" />
          <div className="text">
            <img src="/images/WADEX_logo.png" alt="" />
            <p>WORLD ARTIST DECENTRALIZED EXCHANGE</p>
          </div>
        </S.SlideWrap>
        <S.SlideWrap text={3}>
          <img src="/images/wadex_main3.jpg" alt="" />
          <div className="text">
            <p>The Investment Platform that Connects Artists and Fans</p>
            <p>Revitalizing the Fandom Economy</p>
          </div>
        </S.SlideWrap>
      </Slider>
      <S.NoticeMain>
        <div>
          <span>{t('main3')}</span>
          <Link to="/">{t('main4')}</Link>
        </div>
      </S.NoticeMain>
      <S.Disclosure>
        <SlidePost />
      </S.Disclosure>
      <S.MiniChartWrap>
        <MiniChart title="Hot Artist" data={chartHotList} />
        <MiniChart title="Issue & New" data={chartNewList} />
      </S.MiniChartWrap>
      <TabList coinList={coinList} />
      <S.SecurityMain>
        <div>
          <p>{t('main5')}</p>
          <ul>
            <li>
              <div>
                <img src="images/img_security1.png" alt="SecurityImg1" />
                <div>
                  <span>Encrypted Keys</span>
                  <span>{t('main6')}</span>
                </div>
              </div>
            </li>
            <li>
              <div>
                <img src="images/img_security2.png" alt="SecurityImg1" />
                <div>
                  <span>Password Security</span>
                  <span>{t('main7')}</span>
                </div>
              </div>
            </li>
            <li>
              <div>
                <img src="images/img_security3.png" alt="SecurityImg1" />
                <div>
                  <span>Secure Storage</span>
                  <span>{t('main8')}</span>
                </div>
              </div>
            </li>
            <li>
              <div>
                <img src="images/img_security4.png" alt="SecurityImg1" />
                <div>
                  <span>Data Transmission</span>
                  <span>{t('main9')}</span>
                </div>
              </div>
            </li>
            <li>
              <div>
                <img src="images/img_security5.png" alt="SecurityImg1" />
                <div>
                  <span>DDOS</span>
                  <span>{t('main10')}</span>
                </div>
              </div>
            </li>
            <li>
              <div>
                <img src="images/img_security6.png" alt="SecurityImg1" />
                <div>
                  <span>2FA Verification</span>
                  <span>{t('main11')}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </S.SecurityMain>
      <S.AppDownload>
        <div>
          <p>{t('main12')}</p>
          <p>{t('main13')}</p>
          <div>
            <Link to="/">
              <img src="/images/img_app1.png" alt="App Store" />
              <span>App Store</span>
            </Link>
            <Link to="/">
              <img src="/images/img_app2.png" alt="Google Play" />
              <span>Google Play</span>
            </Link>
          </div>
        </div>
      </S.AppDownload>
      <S.CsMain>
        <ul>
          <li>
            <div>
              <img src="/images/img_cs1.png" alt="" />
              <p>{t('main14')}</p>
            </div>
            <h3>1877-5040</h3>
            <p>
              <span>
                {t('main15')} 09:00~ 18:00
                <br />
              </span>
              <span>
                {t('main16')} 09:00~ 17:00
                <br />
              </span>
              <span>
                {t('main17')}
                <br />
              </span>
            </p>
          </li>
          <li>
            <div>
              <img src="/images/img_cs2.png" alt="" />
              <p>1:1 {t('main18')}</p>
            </div>
            <h3>{t('main19')}</h3>
            <p>
              <span>
                {t('main20')}
                <br />
              </span>
              <span>
                {t('main21')}
                <br />
              </span>
              <span>
                {t('main22')}
                <br />
              </span>
            </p>
          </li>
          <li>
            <div>
              <img src="/images/img_cs3.png" alt="" />
              <p>FAQ</p>
            </div>
            <h3>{t('main23')}</h3>
            <p>
              <span>
                {t('main24')}
                <br />
              </span>
              <span>
                {t('main25')}
                <br />
              </span>
              <span>
                {t('main26')}
                <br />
              </span>
            </p>
          </li>
        </ul>
      </S.CsMain>
    </>
  );
}

export default MainContainer;
