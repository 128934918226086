import React from 'react';
import ExchangeContainer from '../container/ExchangeContainer';
import FooterLayout from '../layout/FooterLayout';
import HeaderLayout from '../layout/HeaderLayout';

function Exchange() {
  return (
    <>
      <HeaderLayout />
      <ExchangeContainer />
      <FooterLayout />
    </>
  );
}

export default Exchange;
