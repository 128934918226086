/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const OrderLogBox = styled.div`
  height: 480px;
  border: 1px solid black;
  padding: 10px;
  font-size: ${({ theme }) => theme.fontSizes.xs};

  .content_wrap {
    height: 408px;
    overflow-y: scroll;
  }
`;

export const TabList = styled.div`
  border-bottom: 1px solid #ccc;
  margin-bottom: 5px;

  button {
    border: 0;
    background-color: transparent;
    padding: 10px 15px;

    &: nth-of-type(${({ tapState }) => tapState}) {
      border-bottom: 4px solid ${({ theme }) => theme.colors.orange1};
      color: ${({ theme }) => theme.colors.orange1};
    }
  }
`;

export const ContentHeader = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray3};

  span {
    width: calc(100% / 9);
    text-align: center;
  }
`;

export const Content = styled.div`
  height: 333px;
  color: ${({ theme }) => theme.colors.gray3};

  ul {
    padding: 10px;
    display: flex;
    align-items: center;

    &:nth-child(even) {
      background-color: ${({ theme }) => theme.backgroundColors.lightGray1};
    }

    li {
      width: calc(100% / 9);
      text-align: center;
    }
  }

  .close_btn {
    padding: 0 20px;
    border-radius: 5px;
    background-color: transparent;
    font-size: ${({ theme }) => theme.fontSizes.xs};
    border: 2px solid ${({ theme }) => theme.colors.pink1};
  }
`;
