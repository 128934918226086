import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MypageHeader from '../components/MypageHeader';
import { MypageSection } from '../styles/MypageSection';
import * as S from '../styles/ConfirmContainer.style';
import OtpRegistModal from '../components/OtpRegistModal';

function ConfirmContainer() {
  const [modal, setModal] = useState('none');

  const { t } = useTranslation();

  return (
    <>
      <MypageHeader />
      <MypageSection>
        {
          {
            none: (
              <S.ConfirmWrap>
                <div className="title">
                  <img src="/images/WADEX_C.png" alt="wadex_logo" />
                  <span>{t('mypage8')}</span>
                </div>
                <span>Please proceed to complete identity verification.</span>
                <div className="confirm_step">
                  <div className="step_info">
                    <img src="/images/confirm3.png" alt="" />
                    <p>{t('mypage9')}</p>
                    <span>{t('mypage10')}</span>
                    <button type="button" disabled>
                      {t('mypage11')}
                    </button>
                  </div>
                  <div className="step_info">
                    <img src="/images/confirm1.png" alt="" />
                    <p>{t('mypage12')}</p>
                    <span>{t('mypage13')}</span>
                    <button type="button" onClick={() => setModal('otpRegist')}>
                      {t('mypage14')}
                    </button>
                  </div>
                  <div className="step_info">
                    <img src="/images/confirm2.png" alt="" />
                    <p>{t('mypage15')}</p>
                    <span>{t('mypage16')}</span>
                    <button type="button" disabled>
                      {t('mypage17')}
                    </button>
                  </div>
                </div>
              </S.ConfirmWrap>
            ),
            otpRegist: <OtpRegistModal setModal={setModal} />,
          }[modal]
        }
      </MypageSection>
    </>
  );
}

export default ConfirmContainer;
