import { createSlice } from '@reduxjs/toolkit';

import { slidePost } from '../actions/post';

export const initialState = {
  slideList: [],
  loadListLoading: false,
  loadListDone: false,
  loadListError: null,
};

const postSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    setSlideList: (state, action) => {
      state.slideList = action.payload;
    },
  },
  extraReducers: builder =>
    builder
      // 공지 슬라이드
      .addCase(slidePost.pending, state => {
        state.loadListLoading = true;
        state.loadListDone = false;
        state.loadListError = null;
      })
      .addCase(slidePost.fulfilled, (state, action) => {
        state.loadListLoading = false;
        state.loadListDone = true;
        state.slideList = action.payload;
      })
      .addCase(slidePost.rejected, (state, action) => {
        state.loadListLoading = false;
        state.loadListError = action.error.message;
      }),
});

export const { setSlideList } = postSlice.actions;
export default postSlice.reducer;
