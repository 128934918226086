/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const ConfirmWrap = styled.div`
  border: 1px solid #ccc;
  padding: 50px 90px;

  .title {
    display: flex;
    margin-bottom: 20px;

    span {
      font-size: ${({ theme }) => theme.fontSizes.titleL};
      color: ${({ theme }) => theme.colors.blue3};
      font-weight: ${({ theme }) => theme.fontWeights.xl};
      padding-left: 10px;
    }
  }

  .confirm_step {
    margin-top: 60px;
    display:flex;

    .step_info {
      width: calc(100% / 3);
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width:40px;
        height:41px;
        object-fit:contain;
      }

      p {
        font-size: ${({ theme }) => theme.fontSizes.xxl};
        font-weight: ${({ theme }) => theme.fontWeights.xl};
        margin-top: 20px;
        margin-bottom: 20px;
      }

      span {
        text-align: center;
      }

      button {
        margin-top: 20px;
        width: 90px;
        height: 30px;
        border-radius: 5px;
        color: ${({ theme }) => theme.colors.blue3};
        border:1px solid ${({ theme }) => theme.colors.blue3};
        background-color:transparent;
    }
  }
`;
