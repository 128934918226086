/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const MemberOutWrap = styled.div`
  margin: 0 auto;
  width: 500px;
  border: 1px solid #ccc;
  padding: 30px;
  line-height: 1.2;

  p {
    font-size: ${({ theme }) => theme.fontSizes.titleL};
    font-weight: ${({ theme }) => theme.fontWeights.xl};
    color: ${({ theme }) => theme.colors.blue3};
    margin-bottom: 10px;
  }

  h4 {
    color: ${({ theme }) => theme.colors.blue3};
  }

  span {
    color: ${({ theme }) => theme.colors.gray2};
  }

  .modal_btns {
    display: flex;
    gap: 5px;
    margin-top: 50px;
    justify-content: flex-end;

    button {
      width: 80px;
      height: 40px;
      border: 0;
      color: white;

      &:nth-of-type(1) {
        background-color: ${({ theme }) => theme.backgroundColors.black1};
      }
      &:nth-of-type(2) {
        background-color: ${({ theme }) => theme.backgroundColors.blue2};
      }
    }
  }

  .total_money {
    margin-top: 20px;
    height: 80px;
    background-color: ${({ theme }) => theme.backgroundColors.gray1};
    padding: 10px;

    h4 {
      font-size: ${({ theme }) => theme.fontSizes.lg};
    }
  }

  .wadex_img {
    text-align: right;

    img {
      height: 20px;
    }
  }

  .pwChange_input {
    margin-top: 60px;

    p {
      font-size: ${({ theme }) => theme.fontSizes.title};
      font-weight: ${({ theme }) => theme.fontWeights.lg};
      color: ${({ theme }) => theme.colors.blue3};
    }

    input {
      width: 100%;
      height: 40px;
      margin-top: 30px;
      padding-left: 10px;
    }
  }

  .memberout_title,
  .memberout_text {
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.title};
    font-weight: ${({ theme }) => theme.fontWeights.lg};
    color: ${({ theme }) => theme.colors.blue3};
    margin-top: 40px;
  }

  .memberout_text {
    display: block;
    margin-top: 40px;
    font-size: ${({ theme }) => theme.fontSizes.xxxl};
  }
  .check_btn {
    display: block;
    margin: 0 auto;
    margin-top: 30px;
    width: 100px;
    height: 30px;
    border: 0;
    color: white;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.backgroundColors.blue2};
  }
`;
