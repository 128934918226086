import styled from 'styled-components';

export const Container = styled.section`
  padding-top: 30px;
  padding-bottom: 70px;
`;

export const ContentWrap = styled.div`
  ${({ theme }) => theme.defaultLayout};

  & > p {
    font-size: ${({ theme }) => theme.fontSizes.title};
    font-weight: ${({ theme }) => theme.fontWeights.xl};
    color: ${({ theme }) => theme.colors.black2};
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;
    margin-bottom: 40px;
  }
`;

export const FormTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  & > span:nth-of-type(1) {
    font-weight: ${({ theme }) => theme.fontWeights.xl};
    position: relative;
    padding-left: 12px;
    &:after {
      content: '·';
      position: absolute;
      left: 0;
      font-size: 16px;
    }
  }

  & > span:nth-of-type(2) {
    font-size: ${({ theme }) => theme.fontSizes.small};
    color: ${({ theme }) => theme.colors.orange1};
  }
`;

export const InfoInputWrap = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  border: 1px solid #eee;
  margin-bottom: 40px;

  & > div {
    display: flex;
    width: 100%;

    &:not(:last-of-type) {
      border-bottom: 1px solid #eee;
    }
  }

  ul {
    width: 100%;
    display: flex;
    align-items: center;

    li:nth-of-type(1) {
      height: 100%;
      flex: 2;
      padding-left: 15px;
      background-color: ${({ theme }) => theme.backgroundColors.lightGray3};
      display: flex;

      span {
        align-self: center;
      }
    }

    li:nth-of-type(2) {
      flex: 8;
      padding: 10px 15px;

      input[type='text'],
      input[type='password'] {
        width: 420px;
        height: 36px;
        line-height: 34px;
        border: 1px solid #e3e3e3;
        border-radius: 5px;
        margin-right: 10px;
        padding: 0 15px;
      }

      & > button {
        color: #fff;
        border: 0;
        background-color: ${({ theme }) => theme.backgroundColors.black1};
        min-width: 80px;
        padding: 7px 15px;
        font-size: ${({ theme }) => theme.fontSizes.small};
        border-radius: 5px;
      }

      & > p {
        color: ${({ theme }) => theme.colors.orange1};
        margin-top: 5px;
      }

      h4 {
        margin-top: 5px;
      }
    }
  }
`;

export const AgreeForm = styled.div`
  padding-top: 10px;

  &:first-of-type {
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      color: ${({ theme }) => theme.colors.orange1};
      background-color: transparent;
      border: 0;
      font-size: ${({ theme }) => theme.fontSizes.small};
      border-radius: 20px;
      padding: 3px 10px;
      font-size: ${({ theme }) => theme.fontSizes.xs};
      line-height: 1.2;
      border: 1px solid #ff8435;
    }
    label {
      display: block;
      margin-top: 10px;
      margin-bottom: 10px;

      span {
        color: ${({ theme }) => theme.colors.orange1};
        border: 0;
      }
    }
  }

  input[type='checkbox'] {
    vertical-align: -1px;
  }
`;

export const JoinBtns = styled.div`
  text-align: center;

  button {
    min-width: 150px;
    margin: 0 3px;
    padding: 12px 40px;
    border: 0;
    border-radius: 5px;
    color: ${({ theme }) => theme.colors.white1};
    font-size: ${({ theme }) => theme.fontSizes.base};

    &:nth-of-type(1) {
      background-color: ${({ theme }) => theme.backgroundColors.blue1};
    }

    &:nth-of-type(2) {
      background-color: ${({ theme }) => theme.backgroundColors.black1};
    }
  }
`;

export const WarningText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.small} !important;
  color: ${({ theme }) => theme.colors.red1} !important;
  border: 0 !important;
`;
