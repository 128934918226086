/* eslint-disable react/destructuring-assignment */
/* eslint-disable new-cap */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getParameterByName } from '../utils/exchange';
import { DATA_FEED_URL } from '../config/config';

function TVChartContainer(props) {
  const { coin, market, elementId } = props;

  useEffect(() => {
    const widgetOptions = {
      width: '100%',
      height: '400',
      symbol: `${coin.toUpperCase()}_${market.toUpperCase()}`,
      interval: ['30', '1', '5', '15', '60', 'D', '2D', '3D', 'W', '3W', 'M'],
      timezone: 'Etc/UTC',
      debug: false,
      theme: 'Light',
      container_id: elementId,
      datafeed: new Datafeeds.UDFCompatibleDatafeed(DATA_FEED_URL),
      library_path: '/chart/charting_library/',
      locale: getParameterByName('lang') || 'en',
      decimal_places: '1/100000000',
      overrides: {
        'mainSeriesProperties.showCountdown': true,
        'symbolWatermarkProperties.transparency': 90,
        'scalesProperties.textColor': '#AAA',

        'mainSeriesProperties.candleStyle.upColor': '#da402f',
        'mainSeriesProperties.candleStyle.downColor': '#277ede',
        'mainSeriesProperties.candleStyle.wickUpColor': '#da402f',
        'mainSeriesProperties.candleStyle.wickDownColor': '#277ede',
        'mainSeriesProperties.candleStyle.borderUpColor': '#da402f',
        'mainSeriesProperties.candleStyle.borderDownColor': '#277ede',
        'mainSeriesProperties.hollowCandleStyle.upColor': '#da402f',
        'mainSeriesProperties.hollowCandleStyle.downColor': '#277ede',
        'mainSeriesProperties.hollowCandleStyle.wickUpColor': '#da402f',
        'mainSeriesProperties.hollowCandleStyle.wickDownColor': '#277ede',
        'mainSeriesProperties.hollowCandleStyle.borderUpColor': '#da402f',
        'mainSeriesProperties.hollowCandleStyle.borderDownColor': '#277ede',
        'mainSeriesProperties.haStyle.upColor': '#da402f',
        'mainSeriesProperties.haStyle.downColor': '#277ede',
        'mainSeriesProperties.haStyle.wickUpColor': '#da402f',
        'mainSeriesProperties.haStyle.wickDownColor': '#277ede',
        'mainSeriesProperties.haStyle.borderUpColor': '#da402f',
        'mainSeriesProperties.haStyle.borderDownColor': '#277ede',
        'study_Overlay@tv-basicstudies.style': 1,
        'study_Overlay@tv-basicstudies.lineStyle.color': '#351c75',
        volumePaneSize: 'medium', // tiny, small, medium, large
      },

      studies_overrides: {
        'volume.volume.plottype': 'columns',
        'volume.volume.color.0': '#3c78d8',
        'volume.volume.color.1': '#e16d6d',
        'volume.volume.transparency': 10,
        'volume.volume ma.plottype': 'line',
        'volume.volume ma.color': '#9bba8e',
        'volume.volume ma.transparency': 50,
        'volume.volume ma.linewidth': 2,
        'volume.MA length': 15,
        'volume.show ma': true,
      },
    };

    const widget = new TradingView.widget(widgetOptions);
  }, [props]);

  return <div id={props.elementId} />;
}

TVChartContainer.defaultProps = {
  elementId: 'graph_view',
};

TVChartContainer.propTypes = {
  elementId: PropTypes.node,
  coin: PropTypes.string.isRequired,
  market: PropTypes.string.isRequired,
};

export default TVChartContainer;
