import React from 'react';
import { useTranslation } from 'react-i18next';
import WalletHeader from '../components/WalletHeader';
import * as S from '../styles/DepositContainer.style';

function DepositContainer() {
  const { t } = useTranslation();
  return (
    <>
      <WalletHeader />
      <S.Wrap>
        <p className="title">{t('exchange15')}</p>
        <div className="content">
          <div className="state">
            <p className="subtitle">{t('exchange16')}</p>
            <div>
              <span>{t('exchange17')}</span>
              <span>0 KLAY</span>
            </div>
            <div>
              <span>{t('exchange18')}</span>
              <select name="" id="">
                <option value="">{t('exchange19')}(KLAY)</option>
              </select>
            </div>
            <div>
              <span>{t('exchange20')} KLAY</span>
              <span>0 KLAY</span>
            </div>
          </div>
          <div className="deposit_adress">
            <p className="subtitle">{t('exchange21')}</p>
            <div className="deposit_info">
              <span>
                {t('exchange22')} <br />
                <input type="text" />{' '}
                <button type="button">{t('exchange23')}</button>
                <br />
                {t('exchange24')}
              </span>
            </div>
          </div>
        </div>
        <ul className="guide_text">
          <li>- {t('exchange25')}</li>
          <li>- {t('exchange26')}</li>
          <li>- {t('exchange27')}</li>
          <li>- {t('exchange28')}</li>
          <li>- {t('exchange29')}</li>
        </ul>
      </S.Wrap>
    </>
  );
}
export default DepositContainer;
