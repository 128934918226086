/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as S from '../styles/ExchangeSearchMarket.style';
import {
  setCoinList,
  setCurrentCoin,
  setTapCoinList,
  setTapFavorList,
} from '../store/slices/exchange';
import reg from '../utils/regexp';
import { getFavorInfo, updateFavor } from '../store/actions/exchange';

const tapList = [
  {
    id: 1,
    tapname: 'ALL',
  },
  {
    id: 2,
    tapname: 'KOR',
  },
  {
    id: 3,
    tapname: 'VNM',
  },
  {
    id: 4,
    tapname: 'PHL',
  },
  {
    id: 5,
    tapname: '즐겨찾기',
  },
];

function ExchangeSearchMarket() {
  const [tapState, setTapState] = useState(tapList[0]);
  const [highlight, setHighlight] = useState([]);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { coinList, tapCoinList, completeCoinInfo, allUpdateFavorDone } =
    useSelector(state => state.exchange);
  const { isLogin } = useSelector(state => state.user);

  const onClickTap = item => {
    setTapState(item);
    dispatch(setTapCoinList(item.tapname));
  };

  const onClickCurrentCoin = type => {
    dispatch(setCurrentCoin(type));
  };
  const onClickStar = (e, coin) => {
    if (isLogin) {
      e.target.classList.toggle('on');
      dispatch(updateFavor({ coin }));
      dispatch(setTapFavorList(coin));
    } else {
      alert('로그인 후 이용가능합니다.');
    }
  };

  useEffect(() => {
    if (completeCoinInfo) {
      setHighlight(Array(tapCoinList.length).fill(false));
      const idx = tapCoinList.findIndex(
        item => item.coin_name === completeCoinInfo.coin_name,
      );
      const copy = [...highlight];
      copy[idx] = true;
      setHighlight(copy);

      setTimeout(() => {
        setHighlight(Array(tapCoinList.length).fill(false));
      }, 600);
    }
  }, [completeCoinInfo]);

  useEffect(() => {
    dispatch(getFavorInfo());
  }, []);

  useEffect(() => {}, [allUpdateFavorDone]);

  return (
    <S.SearchMarketBox>
      <S.Search>
        <button type="button" className="search_ico" />
        <input
          type="text"
          placeholder="코인명/심볼 검색"
          className="search_input"
        />
      </S.Search>
      <S.TabList tapState={tapState.id}>
        {tapList.map(item => (
          <button type="button" key={item.id} onClick={() => onClickTap(item)}>
            {item.tapname}
          </button>
        ))}
      </S.TabList>
      <S.CoinListHeader>
        <span className="text_left">{t('exchange36')}</span>
        <span>{t('exchange37')}</span>
        <span className="text_right">{t('exchange38')}(%)</span>
      </S.CoinListHeader>
      <S.CoinList>
        {tapCoinList &&
          tapCoinList.map((item, idx) => (
            <S.Ul
              key={item.ccs_id}
              percent={item.prcent}
              percentString={item.prcent_sring}
              className="highlight"
              highlight={highlight[idx]}
            >
              <li>
                <button
                  type="button"
                  className={`star ${item.favor === '1' ? 'on' : 'off'}`}
                  onClick={e => onClickStar(e, item.type)}
                />
                <Link
                  to={`/exchange/${item.type2}_${item.type}`}
                  className="coin_name"
                  type="button"
                  onClick={() => onClickCurrentCoin(item.type)}
                >
                  {item.type.toUpperCase()}
                </Link>
              </li>
              <li>{reg.comma(item.last_price.toFixed(2))}</li>
              <li className="percent">
                {`${item.prcent_sring}${item.prcent}`}%
              </li>
            </S.Ul>
          ))}
      </S.CoinList>
    </S.SearchMarketBox>
  );
}

export default ExchangeSearchMarket;
