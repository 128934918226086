/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as S from '../styles/InfoPwModal.style';
import reg from '../utils/regexp';
import {
  certifyUserEmail,
  changeUserPassword,
  logout,
} from '../store/actions/user';
import Loading from './Loading';

function InfoPwModal({ setModal }) {
  const [newPwFormStep, setNewPwFormStep] = useState(0);
  const [email, setEmail] = useState('');
  const [certcode, setCertcode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [reNewPassword, setReNewPassword] = useState('');
  const [emailAction, setEmailAction] = useState('');
  const [changePwAction, setChangePwAction] = useState('');

  const { t } = useTranslation();
  const { me, emailDone, emailLoading, changePasswordDone } = useSelector(
    state => state.user,
  );
  const dispatch = useDispatch();

  // 이메일 인증 클릭 시
  const certifyEmail = () => {
    if (reg.email.test(email)) {
      dispatch(
        certifyUserEmail({
          mem_userid: email,
          type: 'update_pass',
        }),
      ); // 인증 요청
      setEmailAction(true);
    }
  };

  // 비밀번호 변경 클릭 시
  const onClickChangePassword = () => {
    if (reg.password.test(newPassword)) {
      dispatch(
        changeUserPassword({
          certcode,
          mem_password: newPassword,
          mem_password_re: reNewPassword,
          mem_userid: email,
        }),
      ); // 변경 요청
      setChangePwAction(true);
    }
  };

  // 이메일 인증 예외처리
  useEffect(() => {
    if (emailAction) {
      if (emailDone) {
        if (emailDone?.code === 1000) {
          setNewPwFormStep(1);
        } else if (emailDone?.code !== 1000) {
          alert('회원가입시 입력한 이메일과 일치하지 않습니다.');
        }
        setEmailAction(false);
      }
    }
  }, [emailDone]);

  // 비밀번호 변경 예외처리
  useEffect(() => {
    if (changePwAction) {
      if (changePasswordDone) {
        switch (changePasswordDone?.code) {
          case 1000:
            setNewPwFormStep(2);
            dispatch(logout(me?.mem_id));
            break;
          case 1008:
            alert('로그인 세션이 만료되었습니다.');
            dispatch(logout(me?.mem_id));
            break;
          default:
            alert('입력한 값을 확인해 주세요.');
            break;
        }
        setChangePwAction(false);
      }
    }
  }, [changePasswordDone]);

  return (
    <S.pwModalWrap>
      {
        {
          0: (
            <>
              <div className="wadex_img">
                <img src="/images/WADEX_C.png" alt="wadex_logo" />
              </div>
              <div className="pwChange_input">
                <p>비밀번호 변경</p>
                <span>회원가입시 이용한 이메일을 입력해주세요.</span>
                <br />
                <span>본인확인 인증번호를 전송합니다.</span>
                <br />
                <input
                  type="text"
                  placeholder="E-mail 주소"
                  onChange={e => setEmail(e.target.value)}
                />
                <S.WarningText>
                  {email === '' ? null : !reg.email.test(email) && t('signup6')}
                </S.WarningText>
                {emailLoading && <Loading width="10%" display="block" />}
              </div>
              <div className="modal_btns">
                <button type="button" onClick={() => setModal('none')}>
                  취소
                </button>
                <button
                  type="button"
                  onClick={e => certifyEmail(e.target.value)}
                >
                  전송
                </button>
              </div>
            </>
          ),
          1: (
            <>
              <div className="wadex_img">
                <img src="/images/WADEX_C.png" alt="wadex_logo" />
              </div>
              <div className="new_pw_input">
                <p className="text_input">인증번호 입력</p>
                <input
                  type="text"
                  placeholder="인증번호 6자리"
                  maxLength="6"
                  style={{ width: '40%' }}
                  onChange={e => setCertcode(e.target.value)}
                />
                <p className="text_input">새로운 비밀번호</p>
                <input
                  type="password"
                  onChange={e => setNewPassword(e.target.value)}
                />
                <S.WarningText>
                  {newPassword === ''
                    ? null
                    : !reg.password.test(newPassword) && t('signup17')}
                </S.WarningText>
                <p className="text_input">새로운 비밀번호 (재입력)</p>
                <input
                  type="password"
                  onChange={e => setReNewPassword(e.target.value)}
                />
                <S.WarningText>
                  {reNewPassword === ''
                    ? null
                    : newPassword !== reNewPassword && t('signup24')}
                </S.WarningText>
                <br />
                <span>
                  새로운 비밀번호를 입력해주세요. <br /> 비밀번호는 8자리
                  이상이어야 하며, 1개 이상의 대문자,
                  <br /> 1개 이상의 숫자, 1개 이상의 특수문자를 포함해야 합니다.
                </span>
              </div>
              <div className="modal_btns">
                <button type="button" onClick={() => setModal('none')}>
                  취소
                </button>
                <button type="button" onClick={onClickChangePassword}>
                  확인
                </button>
              </div>
            </>
          ),
          2: (
            <>
              <div className="wadex_img">
                <img src="/images/WADEX_C.png" alt="wadex_logo" />
              </div>
              <div className="complete_form">
                <p>비밀번호 변경 완료</p>
                <span>
                  비밀번호가 변경되었습니다. <br /> 새로운 비밀번호로 로그인
                  해주세요.
                  <br />
                </span>
                <Link
                  to="/login"
                  className="login_btn"
                  onClick={() => setModal('none')}
                >
                  로그인
                </Link>
              </div>
            </>
          ),
        }[newPwFormStep]
      }
    </S.pwModalWrap>
  );
}

InfoPwModal.propTypes = {
  setModal: PropTypes.func.isRequired,
};

export default InfoPwModal;
