import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// import i18next from '../config/lang/i18n';
import * as S from '../styles/HeaderLayout.style';
import { logout } from '../store/actions/user';
import { getMenuList } from '../store/actions/menu';

function HeaderLayout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { isLogin, me } = useSelector(state => state.user);
  const { menuList } = useSelector(state => state.menu);

  // const changeLanguage = lang => i18next.changeLanguage(lang);

  // 로그아웃
  const onClickLogout = () => {
    dispatch(logout(me?.mem_id));
    navigate('/');
  };

  // gnb menu 데이터 요청
  useEffect(() => {
    dispatch(getMenuList());
  }, []);
  return (
    <S.Header>
      <S.GnbLeft>
        <Link to="/">
          <img src="/images/WADEX_C.png" alt="wadex 이미지" />
        </Link>
        <S.GnbLeftUl>
          {menuList.map(menu => {
            return (
              <li key={menu[0].menu_id}>
                <Link to={menu[0].menu_desktop}>{menu[0].menu_name_eng}</Link>
                <div className="sub_menu">
                  {menu.map(subMenu => (
                    <Fragment key={subMenu.menu_id}>
                      {subMenu.menu_depth !== '0' && (
                        <p key={subMenu.menu_id}>
                          <Link to={subMenu.menu_desktop}>
                            {subMenu.menu_name_eng}
                          </Link>
                        </p>
                      )}
                    </Fragment>
                  ))}
                </div>
              </li>
            );
          })}
        </S.GnbLeftUl>
      </S.GnbLeft>
      <S.GnbRight>
        {isLogin ? (
          <>
            <span className="mytitle">
              <Link to="/">WELCOME! {me?.info?.mem_nickname} </Link>
            </span>
            <span className="mypage">
              <Link to="/mypage/info">{t('header8')}</Link>
            </span>
            <span className="logout">
              <button type="button" onClick={onClickLogout}>
                {t('header9')}
              </button>
            </span>
          </>
        ) : (
          <>
            <span>
              <Link to="/login">{t('header6')}</Link>
            </span>
            <span>
              <Link to="/signup">{t('header7')}</Link>
            </span>
          </>
        )}
        {/* <select onChange={e => changeLanguage(e.target.value)}>
          <option value="ko">KOR</option>
          <option value="en">ENG</option>
        </select> */}
      </S.GnbRight>
    </S.Header>
  );
}

export default HeaderLayout;
