import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as S from '../styles/FooterLayout.style';

function FooterLayout() {
  const { t } = useTranslation();
  return (
    <>
      <S.FooterLink>
        <ul>
          <li>
            <Link to="/">{t('main32')}</Link>
          </li>
          <li>
            <Link to="/">FAQ</Link>
          </li>
          <li>
            <Link to="/">{t('main33')}</Link>
          </li>
        </ul>
      </S.FooterLink>
      <S.FooterContent>
        <div>
          <div className="img_wrap">
            <img src="/images/WADEX_logo.png" alt="" />
          </div>
          <div className="copy_text">
            <span>WADEX FOUNDATION LTD</span>
            <br />
            <span>
              Business | Blockchain-based Global Artist Link & Investment
              Platform
            </span>
            <br />
            <span>Founder | Lee Jin Woo</span>
            <br />
            <span>Address | 534, Eonju-ro, Gangnam-gu, Seoul 5F</span>
            <br />
            <p>E-MAIL | wadex@wadx.io</p>
            <p>Telegram | WADEX Project</p>
            <span className="last_text">
              &copy; Copyright WADEX. All rights reserved.
            </span>
          </div>
        </div>
      </S.FooterContent>
    </>
  );
}

export default FooterLayout;
