import React from 'react';

function NotFound() {
  return (
    <div>
      <span>not found</span>
    </div>
  );
}

export default NotFound;
