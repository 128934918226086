/* eslint-disable no-alert */
/* eslint-disable no-sequences */
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { slidePost } from '../store/actions/post';
import { setSlideList } from '../store/slices/post';
import Loading from './Loading';

const SlideData = styled.div`
  height: 30px;
  line-height: 30px;
  margin-bottom: 15px;
  background-color: ${({ theme }) => theme.backgroundColors.lightGray1};

  div {
    width: 1360px;
    position: relative;
    height: 100%;
    overflow: hidden;
    margin: 0 auto;

    ul {
      position: absolute;
      transition: 1s all;
      top: 0;
    }
  }
`;

// 공지 슬라이드 컴포넌트
function Slide() {
  const listUl = useRef();
  const dispatch = useDispatch();
  const { loadListLoading, loadListDone, loadListError, slideList } =
    useSelector(state => state.post);

  // 공지 데이터 요청
  useEffect(() => {
    dispatch(slidePost());
  }, []);

  // 공지 슬라이드
  useEffect(() => {
    if (loadListError) alert(`${loadListError}\nSlide.js`);

    // 슬라이드 애니메이션 적용
    let nextList;
    let timer;
    let interval;

    if (loadListDone) {
      interval = setInterval(() => {
        listUl.current.style.transition = '1s all';
        listUl.current.style.top = '-30px';

        timer = setTimeout(() => {
          const firstData = slideList[0];
          nextList = slideList.filter(item => item !== slideList[0]);
          listUl.current.style.transition = '0s all';
          listUl.current.style.top = '0';
          dispatch(setSlideList(nextList));
          dispatch(setSlideList([...nextList, firstData]));
        }, 1000);
      }, 4000);
    }

    return () => {
      return clearTimeout(timer), clearInterval(interval);
    };
  }, [loadListDone, slideList, loadListError]);

  // if (loadListLoading) return <Loading width="2%" />;

  return (
    <SlideData>
      <div>
        <ul ref={listUl}>
          {loadListLoading ? (
            <Loading width="2%" />
          ) : (
            slideList.map(item => {
              return <li key={item.post_id}>{item.post_title}</li>;
            })
          )}
        </ul>
      </div>
    </SlideData>
  );
}

export default Slide;
