/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { coinIntroduce } from '../store/actions/exchange';
import * as S from '../styles/CoinInfoIntroduce.style';

function CoinInfoIntroduce() {
  const [detail, setDetail] = useState(false);
  const dispatch = useDispatch();
  const { currentCoin, coinInfoDone } = useSelector(state => state.exchange);

  const { t } = useTranslation();

  useEffect(() => {
    if (currentCoin?.coin_name) {
      dispatch(coinIntroduce(currentCoin?.coin_name));
    }
  }, [currentCoin]);
  return (
    <S.Contents>
      <div className="top_content">
        <img
          src={coinInfoDone?.info?.inf_image_path}
          alt="token_img"
          className="token_img"
        />
        <div className="content">
          <div className="text_wrap">
            <h4>{t('exchange65')}</h4>
            <span className="nation">{coinInfoDone?.info?.inf_nation}</span>
            <h4 className="margin_left">{t('exchange66')}</h4>
            <span>
              {coinInfoDone?.info?.inf_name}
              <br />({coinInfoDone?.info?.inf_token})
            </span>
          </div>
          <div className="text_wrap">
            <h4>{t('exchange67')}</h4>
            <span>
              {coinInfoDone?.info?.inf_profile.replaceAll('</br>', '\n')}
            </span>
          </div>
        </div>
      </div>
      <div className="bottom_content">
        <div className="text_wrap">
          <div className="left_text">
            <h4>{t('exchange68')}</h4>
            <span>{coinInfoDone?.info?.inf_initial_release.split(' ')[0]}</span>
          </div>
          <div className="right_text">
            <h4 className="margin_left">{t('exchange69')}</h4>
            <span>
              {Number(coinInfoDone?.info?.inf_max_supply).toLocaleString()}
            </span>
          </div>
        </div>
        <div className="text_wrap">
          <div className="left_text">
            <h4>{t('exchange70')}</h4>
            <span>
              {Number(coinInfoDone?.info?.inf_market_cap).toLocaleString()}
            </span>
          </div>
          <div className="right_text">
            <h4 className="margin_left">{t('exchange71')}</h4>
            <span>
              {Number(
                coinInfoDone?.info?.inf_circulating_supply,
              ).toLocaleString()}
            </span>
          </div>
        </div>
        <div className="text_wrap">
          <h4>{t('exchange72')}</h4>
          <span>{coinInfoDone?.info?.inf_introduce}</span>
        </div>
      </div>
      <div className="btns">
        <button
          type="button"
          className="detail_btn"
          onClick={() => setDetail(true)}
        >
          {t('exchange73')}
        </button>
        <button type="button" className="paper">
          {t('exchange74')}
        </button>
      </div>
      {detail && (
        <div className="detail">
          <div className="detail_content">
            <div className="top_content_wrap">
              <div className="top_content">
                <img
                  src={coinInfoDone?.info?.inf_image_path}
                  alt="token_img"
                  className="token_img"
                />
                <div className="content">
                  <div className="text_wrap">
                    <h4>{t('exchange65')}</h4>
                    <span className="nation">
                      {coinInfoDone?.info?.inf_nation}
                    </span>
                    <h4 className="margin_left">{t('exchange66')}</h4>
                    <span>
                      {coinInfoDone?.info?.inf_name}
                      <br />({coinInfoDone?.info?.inf_token})
                    </span>
                  </div>
                  <div className="text_wrap">
                    <h4>{t('exchange67')}</h4>
                    <span>
                      {coinInfoDone?.info?.inf_profile.replaceAll(
                        '</br>',
                        '\n',
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="bottom_content">
                <div className="text_wrap">
                  <div className="left_text">
                    <h4>{t('exchange68')}</h4>
                    <span>
                      {coinInfoDone?.info?.inf_initial_release.split(' ')[0]}
                    </span>
                  </div>
                  <div className="right_text">
                    <h4 className="margin_left">{t('exchange69')}</h4>
                    <span>
                      {Number(
                        coinInfoDone?.info?.inf_max_supply,
                      ).toLocaleString()}
                    </span>
                  </div>
                </div>
                <div className="text_wrap">
                  <div className="left_text">
                    <h4>{t('exchange70')}</h4>
                    <span>
                      {Number(
                        coinInfoDone?.info?.inf_market_cap,
                      ).toLocaleString()}
                    </span>
                  </div>
                  <div className="right_text">
                    <h4 className="margin_left">{t('exchange71')}</h4>
                    <span>
                      {Number(
                        coinInfoDone?.info?.inf_circulating_supply,
                      ).toLocaleString()}
                    </span>
                  </div>
                </div>
                <div className="text_wrap">
                  <h4>{t('exchange72')}</h4>
                  <span>{coinInfoDone?.info?.inf_introduce}</span>
                </div>
                <div className="text_wrap">
                  <h4>{t('exchange75')}</h4>
                  <span>
                    {coinInfoDone?.info?.inf_activity
                      .replaceAll('</br>', '\n')
                      .replaceAll('&nbsp', '\t')}
                  </span>
                </div>
                <div className="text_wrap">
                  <h4>{t('exchange76')}</h4>
                  <span>
                    {coinInfoDone?.info?.inf_activity
                      .replaceAll('</br>', '\n')
                      .replaceAll('&nbsp', '\t')}
                  </span>
                </div>
              </div>
            </div>
            <div className="close">
              <button type="button" onClick={() => setDetail(false)}>
                {t('exchange77')}
              </button>
            </div>
          </div>
        </div>
      )}
    </S.Contents>
  );
}

export default CoinInfoIntroduce;
