/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Wrap = styled.div`
  width: 1360px;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;

  img {
    width: 100%;
  }
`;
