/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Wrap = styled.div`
  ${({ theme }) => theme.defaultLayout()};

  .title {
    font-size: ${({ theme }) => theme.fontSizes.xxxl};
    color: ${({ theme }) => theme.colors.blue3};
    margin-top: 20px;
  }
`;

export const Chart = styled.div`
  margin-top: 10px;
  padding: 70px;
  border: 1px solid #ccc;
`;

export const Table = styled.table`
  width: 100%;
  color: ${({ theme }) => theme.colors.black3};
  font-size: ${({ theme }) => theme.fontSizes.small};
  td {
    width: calc(100% / 6);
    text-align: center;
  }

  thead tr {
    background-color: ${({ theme }) => theme.backgroundColors.gray2};
    height: 40px;
    line-height: 40px;
  }
  tbody tr {
    height: 50px;
  }

  .coin_name {
    span {
    }
  }
`;

export const ContentHeader = styled.div`
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.black3};
  font-size: ${({ theme }) => theme.fontSizes.small};

  span {
    display: inline-block;
    width: calc(100% / 6);
    text-align: center;
    background-color: ${({ theme }) => theme.backgroundColors.gray2};
    height: 40px;
    line-height: 40px;
  }
`;

export const Content = styled.div`
  ul {
    display: flex;
    align-items: center;
    text-align: center;
    height: 50px;

    li {
      width: calc(100% / 6);
    }
  }
`;
