/* eslint-disable no-unused-expressions */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { reToken } from './user';

// 매수, 매도 요청 액션
export const addOrder = createAsyncThunk(
  'exchamge/order',
  async (data, thunkAPI) => {
    try {
      const response = await axios.post('/order/add_order', data);
      response.data.code === 1007 && thunkAPI.dispatch(reToken(addOrder));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// 거래소 유저 정보 요청 액션
export const getUserInfo = createAsyncThunk(
  'exchamge/getInfo',
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(`/balance/get_info?coin=${data}`);
      response.data.code === 1007 && thunkAPI.dispatch(reToken(getUserInfo));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// 가레서 유저 즐겨찾기 정보 요청 액션
export const getFavorInfo = createAsyncThunk(
  'exchamge/getFavorInfo',
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(`/balance/get_info`);
      response.data.code === 1007 && thunkAPI.dispatch(reToken(getUserInfo));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// 코인별 소수점 데이터 요청 액션
export const cryptocurrency = createAsyncThunk(
  'exchamge/cryptocurrency',
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(`/cryptocurrency/get_info?coin=${data}`);
      response.data.code === 1007 && thunkAPI.dispatch(reToken(cryptocurrency));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// 코인 소개 데이터 요청 액션
export const coinIntroduce = createAsyncThunk(
  'exchamge/coinIntroduce',
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(
        `/influencer_info/get_list?coin=${data}`,
      );
      response.data.code === 1007 && thunkAPI.dispatch(reToken(coinIntroduce));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// 미체결주문 취소 요청 액션
export const closeOrder = createAsyncThunk(
  'exchamge/closeOrder',
  async (data, thunkAPI) => {
    try {
      const response = await axios.post('/order/cancel_order', data);
      response.data.code === 1007 && thunkAPI.dispatch(reToken(closeOrder));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// 코인 즐겨찾기 요청 액션
export const updateFavor = createAsyncThunk(
  'exchamge/updateFavor',
  async (data, thunkAPI) => {
    try {
      const response = await axios.post('/balance/update_favor', data);
      response.data.code === 1007 && thunkAPI.dispatch(reToken(closeOrder));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
