import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Header = styled.div`
  background-color: ${({ theme }) => theme.backgroundColors.blue2};
  height: 50px;

  .header_text {
    ${({ theme }) => theme.defaultLayout()};

    span {
      line-height: 50px;
      color: white;
      font-size: ${({ theme }) => theme.fontSizes.xxxl};
    }
  }
`;

function SubHeader({ children }) {
  return (
    <Header>
      <div className="header_text">
        <span>{children}</span>
      </div>
    </Header>
  );
}

SubHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SubHeader;
