/* eslint-disable no-alert */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import InfoMemberOutModal from '../components/InfoMemberOutModal';
import InfoPwModal from '../components/InfoPwModal';
import MypageHeader from '../components/MypageHeader';
import { MypageSection } from '../styles/MypageSection';
import * as S from '../styles/InfoContainer.style';
import { changeUserNickname } from '../store/actions/user';
import reg from '../utils/regexp';

function InfoContainer() {
  const [modal, setModal] = useState('none');
  const [nickname, setNickname] = useState('');
  const [nicknameCheck, setNicknameCheck] = useState('');
  const [action, setAction] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { me, changeNicknameDone } = useSelector(state => state.user);

  const onChangeNickname = val => {
    if (reg.nickname.test(nickname)) {
      setNicknameCheck(true);
    } else {
      setNicknameCheck(false);
    }
    setNickname(val);
  };

  const data = {
    mem_id: me?.mem_id,
    mem_nickname: nickname,
  };

  // 닉네임 변경 클릭 시
  const onClickChangeNickname = () => {
    if (reg.nickname.test(nickname)) {
      dispatch(changeUserNickname(data));
      setAction(true);
    } else {
      setNicknameCheck(false);
    }
  };

  // 닉네임 변경 예외처리
  useEffect(() => {
    if (action) {
      if (changeNicknameDone) {
        if (changeNicknameDone.code === 1000) {
          alert(t('mypage1'));
        } else if (changeNicknameDone.code !== 1000) {
          alert(t('mypage2'));
        }
        setAction(false);
      }
    }
  }, [changeNicknameDone]);

  return (
    <>
      <MypageHeader />
      <MypageSection>
        {
          {
            none: (
              <>
                <S.MemberInfo>
                  <p>{t('mypage3')}</p>
                  <div className="email_info">
                    <h4>E-mail</h4>
                    <span>{me?.info?.mem_userid}</span>
                  </div>
                  <div className="nickname_wrap">
                    <h4>{t('mypage4')}</h4>
                    <input
                      type="text"
                      maxLength="20"
                      onChange={e => onChangeNickname(e.target.value)}
                    />
                    <button type="button" onClick={onClickChangeNickname}>
                      {t('mypage5')}
                    </button>
                  </div>
                  {nicknameCheck === false && (
                    <S.WarningText>{t('signup14')}</S.WarningText>
                  )}
                </S.MemberInfo>
                <S.PasswordChange>
                  <p>{t('mypage6')}</p>
                  <button type="button" onClick={() => setModal('pwChange')}>
                    {t('mypage6')}
                  </button>
                </S.PasswordChange>
                <S.MemberOut>
                  <p>{t('mypage7')}</p>
                  <button type="button" onClick={() => setModal('memberOut')}>
                    {t('mypage7')}
                  </button>
                </S.MemberOut>
              </>
            ),
            pwChange: <InfoPwModal setModal={setModal} />,
            memberOut: <InfoMemberOutModal setModal={setModal} />,
          }[modal]
        }
      </MypageSection>
    </>
  );
}

export default InfoContainer;
