/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Wrap = styled.div`
  ${({ theme }) => theme.defaultLayout()};
  margin-bottom: 50px;

  .title {
    font-size: ${({ theme }) => theme.fontSizes.xxxl};
    color: ${({ theme }) => theme.colors.blue3};
    margin-top: 20px;
  }

  .content {
    display: flex;
    justify-content: space-between;
    .state {
      width: 47%;
      padding: 50px;
      border: 1px solid #ccc;
      margin-top: 40px;

      .subtitle {
        font-size: ${({ theme }) => theme.fontSizes.lg};
        padding-bottom: 10px;
        border-bottom: 1px solid #ccc;
      }

      div {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
      }
    }

    .deposit_adress {
      width: 47%;
      padding: 50px;
      border: 1px solid #ccc;
      margin-top: 40px;

      .subtitle {
        font-size: ${({ theme }) => theme.fontSizes.lg};
        padding-bottom: 10px;
        border-bottom: 1px solid #ccc;
      }

      .deposit_info {
        margin: 70px 50px;

        span {
          line-height: 2;
        }
      }
    }
  }

  .guide_text {
    margin-top: 30px;
    padding-left: 10px;
    line-height: 1.4;
  }
`;
