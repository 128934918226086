import { createSlice } from '@reduxjs/toolkit';
import { createOpenApi } from '../actions/openApi';

export const initialState = {
  registOpenApiLoading: false,
  registOpenApiDone: false,
  registOpenApiError: null,
};

const openApiSlice = createSlice({
  name: 'openApi',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder
      // openApi 생성 요청
      .addCase(createOpenApi.pending, state => {
        state.registOpenApiLoading = true;
        state.registOpenApiDone = false;
        state.registOpenApiError = null;
      })
      .addCase(createOpenApi.fulfilled, (state, action) => {
        state.registOpenApiLoading = false;
        state.registOpenApiDone = true;
        state.registOpenApiDone = action.payload;
      })
      .addCase(createOpenApi.rejected, (state, action) => {
        state.registOpenApiLoading = false;
        state.registOpenApiError = action.error.message;
      }),
});

// export const {  } = menuSlice.actions;
export default openApiSlice.reducer;
