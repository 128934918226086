/* eslint-disable no-nested-ternary */
import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  chartHotList: [],
  chartNewList: [],
  coinList: [],
};

const chartSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setChartHotList: (state, action) => {
      state.chartHotList = action.payload.slice(0, 5);
    },
    setChartNewList: (state, action) => {
      state.chartNewList = action.payload.slice(0, 5);
    },
    setCoinList: (state, action) => {
      state.coinList = action.payload.sort((a, b) => {
        return (
          b.last_price - b.yesterday_price - (a.last_price - a.yesterday_price)
        );
      });
    },
    sortCoinList: (state, action) => {
      const { type, sortState } = action.payload;
      switch (type) {
        case 'css_coin_name':
          state.coinList.sort((a, b) => {
            if (sortState === 'asc') {
              return a.ccs_coin_name < b.ccs_coin_name
                ? -1
                : a.ccs_coin_name > b.ccs_coin_name
                ? 1
                : 0;
            }
            return a.ccs_coin_name > b.ccs_coin_name
              ? -1
              : a.ccs_coin_name < b.ccs_coin_name
              ? 1
              : 0;
          });
          break;

        case 'last_price':
          state.coinList.sort((a, b) => {
            if (sortState === 'asc') {
              return a.last_price - b.last_price;
            }
            return b.last_price - a.last_price;
          });
          break;

        case 'chang_price':
          state.coinList.sort((a, b) => {
            if (sortState === 'asc') {
              return (
                a.last_price -
                a.yesterday_price -
                (b.last_price - b.yesterday_price)
              );
            }
            return (
              b.last_price -
              b.yesterday_price -
              (a.last_price - a.yesterday_price)
            );
          });
          break;

        case 'value':
          state.coinList.sort((a, b) => {
            if (sortState === 'asc') {
              return a.value - b.value;
            }
            return b.value - a.value;
          });
          break;

        default:
          break;
      }
    },
  },
});
export const { setChartHotList, setChartNewList, setCoinList, sortCoinList } =
  chartSlice.actions;
export default chartSlice.reducer;
