import React from 'react';
import PropTypes from 'prop-types';

function Loading({ width, display }) {
  return (
    <div style={{ display }}>
      <img
        src="/images/Spinner.gif"
        alt="로딩중"
        width={width}
        style={{ verticalAlign: 'middle' }}
      />
    </div>
  );
}

Loading.defaultProps = {
  display: 'block',
};

Loading.propTypes = {
  width: PropTypes.string.isRequired,
  display: PropTypes.string,
};

export default Loading;
