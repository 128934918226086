/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Wrap = styled.div`
  .title {
    font-size: ${({ theme }) => theme.fontSizes.title};
    color: ${({ theme }) => theme.colors.blue3};
  }

  .require_write {
    width: 800px;
    line-height: 1.5;
  }

  .regist {
    text-align: right;

    button {
      margin: 0 5px;
      padding: 5px 15px;
      color: white;
      background-color: ${({ theme }) => theme.backgroundColors.blue2};
    }
  }

  .otpModal {
    width: 500px;
    height: 500px;
    border: 1px solid #ccc;
    margin: 0 auto;
    padding: 30px 40px;

    .modal_title1,
    .modal_title2 {
      font-size: ${({ theme }) => theme.fontSizes.title};
      color: ${({ theme }) => theme.colors.blue3};
    }

    .modal_title1 {
      margin-bottom: 50px;
    }

    .modal_title2 {
      margin-bottom: 10px;
    }

    span {
      color: ${({ theme }) => theme.colors.gray2};
    }

    .otp_input {
      margin-top: 20px;
      width: 100%;
      height: 40px;
    }

    .btn {
      display: block;
      margin-top: 30px;
      margin-left: auto;
      padding: 5px 15px;
      color: white;
      background-color: ${({ theme }) => theme.backgroundColors.blue2};
    }
  }

  .keyModal {
    width: 400px;
    border: 1px solid #ccc;
    margin: 0 auto;
    padding: 30px 40px;

    .key_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      span {
        font-size: ${({ theme }) => theme.fontSizes.xl};
      }

      button {
        color: white;
        padding: 3px 10px;
        background-color: ${({ theme }) => theme.backgroundColors.blue2};
      }
    }

    input {
      width: 100%;
      height: 40px;
      margin-bottom: 30px;
    }

    .check_btn {
      display: block;
      padding: 5px 40px;
      margin: 0 auto;
      margin-top: 20px;
      background-color: ${({ theme }) => theme.backgroundColors.blue2};
      color: white;
    }

    .require_write2 {
      line-height: 1.5;
    }
  }
`;

export const ApiSetting = styled.div`
  width: 400px;
  margin-top: 30px;
  padding: 20px;
  background-color: ${({ theme }) => theme.backgroundColors.gray2};

  p {
    font-size: ${({ theme }) => theme.fontSizes.xxl};
    color: ${({ theme }) => theme.colors.blue3};
    margin-bottom: 10px;
  }

  input {
    width: 100%;
    height: 40px;
  }
`;

export const ApiSetting2 = styled(ApiSetting)`
  width: 800px;
  margin-bottom: 20px;

  .checkboxs {
    border: 1px solid #ccc;
    flex: 6;
    padding: 15px;

    label {
      display: inline-block;
      margin-right: 30px;

      input {
        vertical-align: -2px;
        width: 20px;
        height: 20px;
      }
    }
  }
`;

export const ApiSetting3 = styled(ApiSetting)`
  width: 800px;
`;
