import React from 'react';
import { useTranslation } from 'react-i18next';
import WalletHeader from '../components/WalletHeader';
import * as S from '../styles/WithdrawContainer.style';

function WithdrawContainer() {
  const { t } = useTranslation();
  return (
    <>
      <WalletHeader />
      <S.Wrap>
        <p className="title">{t('wallet30')}</p>
        <div className="my_status">
          <p>{t('wallet31')}</p>
          <div className="contents">
            <ul>
              <li>{t('wallet32')}</li>
              <li>0 KLAY</li>
            </ul>
            <ul>
              <li>{t('wallet33')}</li>
              <li>
                <select name="" id="">
                  <option value="">{t('wallet34')}(KLAY)</option>
                </select>
              </li>
            </ul>
            <ul>
              <li>{t('wallet35')} KLAY</li>
              <li>0 KLAY</li>
            </ul>
          </div>
        </div>
        <div className="request">
          <p>{t('wallet36')}</p>
          <div className="contents">
            <h1>{t('wallet37')}</h1>
            <S.CoinInfo>
              <div>
                <span>- {t('wallet38')}</span>
                <span>0 TRA</span>
              </div>
              <div>
                <span>- {t('wallet39')}</span>
                <span>10 TRA</span>
              </div>
            </S.CoinInfo>
            <S.WithdrawInfo>
              <div>
                <span>1.{t('wallet40')}</span>
                <span>1 WAT</span>
              </div>
              <div>
                <span>- {t('wallet41')}</span>
                <span>5 WAT</span>
              </div>
            </S.WithdrawInfo>
            <S.RequestInfo>
              <ul>
                <li>{t('wallet42')}</li>
                <li>0.00000 TRA</li>
              </ul>
              <ul>
                <li>{t('wallet43')}</li>
                <li>
                  <input type="text" />
                </li>
              </ul>
              <ul>
                <li>{t('wallet44')}</li>
                <li>
                  <input type="text" placeholder="최소 10 TRA" />
                  <button type="button">{t('wallet45')}</button>
                </li>
              </ul>
              <ul>
                <li>{t('wallet46')}</li>
                <li>0 TRA</li>
              </ul>
            </S.RequestInfo>
            <p className="otp_text">2. {t('wallet47')}</p>
            <span>{t('wallet48')}</span>
            <ul className="guide_text">
              <li>- {t('wallet49')}</li>
              <li>- {t('wallet50')}</li>
              <li>- {t('wallet51')}</li>
              <li>- {t('wallet52')}</li>
              <li>- {t('wallet53')}</li>
            </ul>
          </div>
        </div>
      </S.Wrap>
    </>
  );
}
export default WithdrawContainer;
