/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import styled, { css } from 'styled-components';

export const SlideWrap = styled.div`
  position: relative;

  .text {
    img {
      width: 200px;
    }

    ${({ text }) =>
      text === 1
        ? css`
            color: #3196ff;
          `
        : text === 2
        ? css`
            color: white;
          `
        : text === 3 &&
          css`
            color: black;
          `}
    display:flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;

    p:nth-of-type(1) {
      font-size: ${({ theme }) => theme.fontSizes.titleXL};
      font-weight: ${({ theme }) => theme.fontWeights.xl};
      margin-bottom: 30px;
    }
    p:nth-of-type(2) {
      font-size: ${({ theme }) => theme.fontSizes.xxxl};
      font-weight: ${({ theme }) => theme.fontWeights.xl};
    }
  }
`;

export const FullBgImg = styled.section`
  height: 500px;
  background-image: url('/images/bg_visual.png');
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;

  div {
    ${({ theme }) => theme.defaultLayout};
    padding-top: 170px;
    color: ${({ theme }) => theme.colors.white1};
    text-align: center;

    p:nth-of-type(1) {
      font-size: ${({ theme }) => theme.fontSizes.titleL};
      font-weight: ${({ theme }) => theme.fontWeights.xl};
      margin-bottom: 30px;
    }
    p:nth-of-type(2) {
      font-size: ${({ theme }) => theme.fontSizes.small};
      font-weight: ${({ theme }) => theme.fontWeights.base};
    }
  }
`;

export const NoticeMain = styled.section`
  height: 90px;
  background-color: #0047bb;

  div {
    ${({ theme }) => theme.defaultLayout};
    color: ${({ theme }) => theme.colors.white1};
    line-height: 90px;
    text-align: center;

    span:nth-of-type(1) {
      font-size: ${({ theme }) => theme.fontSizes.lg};
      font-weight: ${({ theme }) => theme.fontWeights.lg};
    }

    a {
      background-color: white;
      color: ${({ theme }) => theme.colors.black1};
      margin-left: 20px;
      border-radius: 20px;
      padding: 2px 10px;
      font-size: ${({ theme }) => theme.fontSizes.xs};
    }
  }
`;

export const Disclosure = styled.section`
  height: 30px;
  line-height: 30px;
  margin-bottom: 15px;
  background-color: ${({ theme }) => theme.backgroundColors.lightGray1};

  div {
    ${({ theme }) => theme.defaultLayout};
  }
`;

export const MiniChartWrap = styled.section`
  background-color: ${({ theme }) => theme.backgroundColors.lightGray2};
`;

export const SecurityMain = styled.section`
  background-color: ${({ theme }) => theme.backgroundColors.lightGray1};
  padding: 55px 0 50px;
  margin: 4rem 0;

  div {
    ${({ theme }) => theme.defaultLayout};

    p {
      font-size: ${({ theme }) => theme.fontSizes.title};
      font-weight: ${({ theme }) => theme.fontWeights.xl};
      color: ${({ theme }) => theme.colors.black2};
      margin-bottom: 50px;
      text-align: center;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 50px 0;

      li {
        width: 31%;
        height: 190px;
        border-radius: 1rem;
        background-color: white;
        border: 1px solid #e3e3e3;
        padding: 50px 40px;

        & > div {
          display: flex;
          gap: 20px;
          align-items: center;
        }
        img {
          width: 80px;
          object-fit: contain;
        }

        div {
          span {
            display: block;
          }
          span:nth-of-type(1) {
            font-size: ${({ theme }) => theme.fontSizes.lg};
            font-weight: ${({ theme }) => theme.fontWeights.xl};
            margin-bottom: 20px;
          }
        }
      }
    }
  }
`;

export const AppDownload = styled.section`
  height: 415px;
  background: url(../images/bg_app.png);
  background-color: ${({ theme }) => theme.backgroundColors.lightGray2};
  background-position: center;
  background-repeat: no-repeat;
  text-align: left;

  & > div {
    ${({ theme }) => theme.defaultLayout};
    padding-top: 120px;

    p {
      color: ${({ theme }) => theme.colors.black2};
    }
    p:nth-of-type(1) {
      margin-bottom: 20px;
      font-size: ${({ theme }) => theme.fontSizes.title};
      font-weight: ${({ theme }) => theme.fontWeights.xl};
    }

    div {
      margin-top: 60px;

      a {
        margin-right: 10px;
        color: ${({ theme }) => theme.colors.gray2};
        border-color: ${({ theme }) => theme.colors.gray2};
        min-width: 140px;
        padding: 14px 15px;
        font-size: 15px;
        border: 1px solid #cdcdcd;
        border-radius: 10px;

        img {
          vertical-align: middle;
        }
      }
    }
  }
`;

export const CsMain = styled.section`
  ul {
    ${({ theme }) => theme.defaultLayout};
    margin: 0 auto;
    display: flex;

    li {
      flex: 3.3;
      padding: 60px 0;

      div {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 30px;

        p {
          font-size: ${({ theme }) => theme.fontSizes.lg};
        }
      }

      h3 {
        font-size: ${({ theme }) => theme.fontSizes.xl};
        font-weight: ${({ theme }) => theme.fontWeights.lg};
        margin-bottom: 20px;
      }

      & > p {
        line-height: 1.5;
      }
    }
  }
`;
