/* eslint-disable import/named */
import React from 'react';
import PropTypes from 'prop-types';
import * as S from '../styles/ExchangeChart.style';
import TVChartContainer from './TVChartContainer';

function ExchangeChart({ coin, market }) {
  return (
    <S.ChartBox>
      <TVChartContainer coin={coin} market={market} />
    </S.ChartBox>
  );
}

ExchangeChart.propTypes = {
  coin: PropTypes.string.isRequired,
  market: PropTypes.string.isRequired,
};

export default ExchangeChart;
