/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const TransactionLogBox = styled.div`
  height: 430px;
  border: 1px solid black;
  font-size: ${({ theme }) => theme.fontSizes.xs};

  .tab {
    padding: 0 10px;

    button {
      border: 0;
      background-color: transparent;
      padding: 5px 20px;
      border-bottom: 4px solid ${({ theme }) => theme.colors.orange1};
      color: ${({ theme }) => theme.colors.orange1};
    }
  }
  .list_wrap {
    height: 395px;
    overflow-y: scroll;
  }
  .list_header {
    padding: 10px;
    background-color: ${({ theme }) => theme.backgroundColors.lightGray3};

    span {
      display: inline-block;
      width: calc(100% / 3);
      text-align: center;
    }
  }

  .list {
    ul {
      padding: 10px;

      &:nth-child(even) {
        background-color: ${({ theme }) => theme.backgroundColors.lightGray1};
      }

      li {
        display: inline-block;
        width: calc(100% / 3);
        text-align: center;
      }
    }
  }

  .text_left {
    text-align: left !important;
  }

  .text_right {
    text-align: right !important;
  }
`;
