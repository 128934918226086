/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';

export const SearchMarketBox = styled.div`
  border: 1px solid black;
  font-size: ${({ theme }) => theme.fontSizes.xs};
`;

export const Search = styled.div`
  position: relative;
  padding: 10px;

  .search_ico {
    border: 0;
    position: absolute;
    left: 11px;
    top: 11px;
    width: 40px;
    height: 37px;
    border-radius: 5px;
    background: url(/images/ico_sch.jpg) center center no-repeat;
  }

  .search_input {
    border-radius: 5px;
    width: 100%;
    height: 40px;
    padding-left: 40px;
    border: 1px solid #ccc;
  }

  .btn-light {
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
    vertical-align: -2px;
    margin-left: 3px;
  }
`;

export const TabList = styled.div`
  border-bottom: 1px solid #ccc;

  button {
    border: 0;
    background-color: transparent;
    padding: 10px 15px;

    &: nth-of-type(${({ tapState }) => tapState}) {
      border-bottom: 4px solid ${({ theme }) => theme.colors.orange1};
      color: ${({ theme }) => theme.colors.orange1};
    }
  }
`;

export const CoinListHeader = styled.div`
  padding: 10px;
  background-color: ${({ theme }) => theme.backgroundColors.lightGray3};

  span {
    display: inline-block;
    width: calc(100% / 3);
    text-align: center;
  }
`;

export const CoinList = styled.div``;

export const Ul = styled.ul`
  ${({ highlight }) =>
    highlight &&
    css`
      border: 2px solid red;
    `}

  padding: 10px;

  &:nth-child(even) {
    background-color: ${({ theme }) => theme.backgroundColors.lightGray1};
  }

  li {
    display: inline-block;
    width: calc(100% / 3);
    text-align: center;

    .star {
      background: url(/images/img_star_outline.png) center center no-repeat;
      width: 15px;
      height: 15px;
      border: 0;
      margin-right: 5px;
    }

    .coin_name {
      border: 0;
      background-color: transparent;
    }
  }

  .percent {
    ${({ percent, percentString }) => {
      if (percent.indexOf('-') === 0) {
        return css`
          color: ${({ theme }) => theme.colors.blue1};
        `;
      }
      if (percentString === '+') {
        return css`
          color: ${({ theme }) => theme.colors.pink1};
        `;
      }
      return css`
        color: black;
      `;
    }}
  }

  .on {
    background: url(/images/img_star.png) center center no-repeat !important;
  }
`;
