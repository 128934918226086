import React from 'react';
import { useTranslation } from 'react-i18next';
import SubHeader from '../components/SubHeader';
import * as S from '../styles/BoardContainer.style';

function BoardContainer() {
  const { t } = useTranslation();
  return (
    <>
      <SubHeader>{t('board1')}</SubHeader>
      <S.SelectAndSearch>
        <select>
          <option>{t('board2')}</option>
          <option>2</option>
          <option>3</option>
        </select>
        <div className="search_wrap">
          <select>
            <option>{t('board3')}</option>
            <option>{t('board4')}</option>
            <option>{t('board5')}</option>
          </select>
          <input type="text" placeholder="검색" />
          <button type="button">{t('board6')}</button>
        </div>
      </S.SelectAndSearch>
      <S.TableWrap>
        <table>
          <thead>
            <tr>
              <td>{t('board4')}</td>
              <td>{t('board3')}</td>
              <td>{t('board7')}</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="coin_name">
                <div className="img_wrap">
                  <img
                    src="/images/KRW_BTS.png"
                    alt="img"
                    className="coin_img"
                  />
                </div>
                <div className="text">
                  <span>
                    BTS
                    <br />
                  </span>
                  <span>BTS</span>
                </div>
              </td>
              <td>{t('board8')}</td>
              <td>2022.08.17</td>
            </tr>
          </tbody>
        </table>
      </S.TableWrap>
    </>
  );
}

export default BoardContainer;
