import React from 'react';
import FooterLayout from '../layout/FooterLayout';
import HeaderLayout from '../layout/HeaderLayout';

function Notice() {
  return (
    <>
      <HeaderLayout />
      <div>
        <p>notice</p>
      </div>
      <FooterLayout />
    </>
  );
}

export default Notice;
