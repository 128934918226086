/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useState } from 'react';
import uuid from 'react-uuid';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as S from '../styles/TabList.style';
import { sortCoinList } from '../store/slices/chart';

// 탭 리스트 출력 컴포넌트
function TabList({ coinList }) {
  const [curIdx, setCurIdx] = useState(0);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const tabMenus = [t('main27')];

  const onClickTab = hitIdx => {
    setCurIdx(hitIdx);
  };

  const onClickSort = (type, e) => {
    const sortState = e.target.dataset.sort;
    if (sortState === 'asc') {
      e.target.dataset.sort = 'desc';
    } else {
      e.target.dataset.sort = 'asc';
    }
    dispatch(sortCoinList({ type, sortState }));
  };

  return (
    <section>
      <S.MarketListWrap>
        <S.MarketListTap>
          <S.MarketTapLeft curIdx={curIdx}>
            {tabMenus.map((item, idx) => (
              <li key={uuid()}>
                <button type="button" onClick={() => onClickTab(idx)}>
                  {item}
                </button>
              </li>
            ))}
          </S.MarketTapLeft>
        </S.MarketListTap>
        <div>
          <S.MarketListTitle>
            <ul>
              <li>
                {t('main28')}
                <img
                  src="/images/ico_arrow.png"
                  alt="sort"
                  className="ico_arrow"
                  role="button"
                  data-sort="asc"
                  onKeyPress={onClickSort}
                  onClick={e => onClickSort('css_coin_name', e)}
                />
              </li>
              <li>
                {t('main29')}
                <img
                  src="/images/ico_arrow.png"
                  alt="sort"
                  className="ico_arrow"
                  role="button"
                  data-sort="asc"
                  onKeyPress={onClickSort}
                  onClick={e => onClickSort('last_price', e)}
                />
              </li>
              <li>
                {t('main30')}(%)
                <img
                  src="/images/ico_arrow.png"
                  alt="sort"
                  className="ico_arrow"
                  role="button"
                  data-sort="asc"
                  onKeyPress={onClickSort}
                  onClick={e => onClickSort('chang_price', e)}
                />
              </li>
              <li>
                {t('main31')}(24H)
                <img
                  src="/images/ico_arrow.png"
                  alt="sort"
                  className="ico_arrow"
                  role="button"
                  data-sort="asc"
                  onKeyPress={onClickSort}
                  onClick={e => onClickSort('value', e)}
                />
              </li>
            </ul>
          </S.MarketListTitle>
          <S.MarketListView>
            {coinList &&
              coinList.map(item => (
                <ul key={item.ccs_id}>
                  <li>
                    <p>
                      <span>{item.ccs_coin_name_eng}</span>
                      <span>
                        {item.type.toUpperCase()} / {item.type2.toUpperCase()}
                      </span>
                    </p>
                  </li>
                  <li>
                    {item.last_price} {item.type2.toUpperCase()}
                  </li>
                  <li className="percent">
                    {item.last_price - item.yesterday_price}
                    {item.type2.toUpperCase()}
                    (0.00) %
                  </li>
                  <li>
                    {item.value} {item.type2.toUpperCase()}
                  </li>
                </ul>
              ))}
          </S.MarketListView>
        </div>
      </S.MarketListWrap>
    </section>
  );
}

TabList.propTypes = {
  coinList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TabList;
