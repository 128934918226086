/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as S from '../styles/ExchangeTransactionLog.style';
import reg from '../utils/regexp';

function ExchangeTransactionLog() {
  const { transactionLog } = useSelector(state => state.exchange);

  const { t } = useTranslation();
  return (
    <S.TransactionLogBox>
      <div className="tab">
        <button type="button">{t('exchange32')}</button>
      </div>
      <div className="list_wrap">
        <div className="list_header">
          <span className="text_left">{t('exchange33')}</span>
          <span>{t('exchange34')}(TRA)</span>
          <span className="text_right">{t('exchange35')}</span>
        </div>
        <div className="list">
          {transactionLog &&
            transactionLog.map((item, idx) => (
              <ul key={idx}>
                <li className="text_left">{item.date1}</li>
                <li>{reg.comma(item.price.toFixed(2))}</li>
                <li className="text_right">
                  {reg.comma(item.amount.toFixed(2))}
                </li>
              </ul>
            ))}
        </div>
      </div>
    </S.TransactionLogBox>
  );
}

export default ExchangeTransactionLog;
