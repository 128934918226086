import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/user';
import postReducer from './slices/post';
import tokenReducer from './slices/token';
import chartReducer from './slices/chart';
import menuReducer from './slices/menu';
import openApiReducer from './slices/openApi';
import exchangeRedcer from './slices/exchange';

export default configureStore({
  reducer: {
    user: userReducer,
    post: postReducer,
    token: tokenReducer,
    chart: chartReducer,
    menu: menuReducer,
    openApi: openApiReducer,
    exchange: exchangeRedcer,
  },
  devTools: process.env.NODE_ENV !== 'production',
});
