/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const OpenapiWrap = styled.div`
  text-align: center;
  border: 1px solid #ccc;
  padding: 50px 0;
  p {
    font-size: ${({ theme }) => theme.fontSizes.titleL};
    font-weight: ${({ theme }) => theme.fontWeights.xl};
    color: ${({ theme }) => theme.colors.blue3};
    margin-bottom: 30px;
  }

  span {
    color: ${({ theme }) => theme.colors.blue3};
  }

  button {
    margin-top: 30px;
    padding: 10px 40px;
    border: 0;
    color: white;
    background-color: ${({ theme }) => theme.backgroundColors.blue2};
  }
`;
