/* eslint-disable no-alert */
/* eslint-disable consistent-return */
/* eslint-disable no-useless-escape */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loading from '../components/Loading';
import {
  certifyUserEmail,
  checkUserNickname,
  signup,
} from '../store/actions/user';
import * as S from '../styles/SignUpContainer.style';
import reg from '../utils/regexp';

// 회원가입 컴포넌트
function SignUpContainer() {
  const { t } = useTranslation();

  const checkboxDatas = [
    {
      id: 'agree1',
      title: '이용약관 동의',
      essential: true,
    },
    {
      id: 'agree2',
      title: '개인정보 수집 및 이용 동의',
      essential: true,
    },
    {
      id: 'mem_receive_sms',
      title: '마케팅, 이벤트 등 혜택 / 정보 수신 동의 (선택)',
      essential: false,
    },
    {
      id: 'mem_open_profile',
      title: '개인정보 유효기간 `회원탈퇴시까지 유효` 지정 동의 (선택)',
      essential: false,
    },
  ];

  const [email, setEmail] = useState('');
  const [nickname, setNickname] = useState('');
  const [isCheckNickname, setIsCheckNickname] = useState(false);
  const [idList, setIdList] = useState([]);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const {
    emailLoading,
    emailDone,
    emailError,
    nicknameLoading,
    nicknameDone,
    nicknameError,
    signupLoading,
    signupDone,
    signupError,
  } = useSelector(state => state.user);

  // react-hook-form 라이브러리 사용
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    setFocus,
  } = useForm();

  //  전체 체크박스 클릭 시
  const onCheckedAll = checked => {
    if (checked) {
      const idListArray = [];
      checkboxDatas.forEach(list => {
        idListArray.push(list.id);
        setValue(list.id, 1);
      });
      setIdList(idListArray);
    } else {
      setIdList([]);
      checkboxDatas.forEach(list => {
        setValue(list.id, 0);
      });
    }
  };

  // 개별 체크박스 클릭 시
  const onCheckedElement = (checked, list) => {
    if (checked) {
      setIdList([...idList, list.id]);
      setValue(list.id, 1);
    } else {
      setIdList(idList.filter(el => el !== list.id && el));
      setValue(list.id, 0);
    }
  };

  // 이메일 인증 클릭 시
  const certifyEmail = () => {
    if (reg.email.test(email)) {
      dispatch(
        certifyUserEmail({
          mem_userid: email,
          type: 'sign_up',
        }),
      ); // 인증 요청
    } else {
      setFocus('email');
    }
  };

  // 닉네임 중복체크 클릭 시
  const checkNickname = () => {
    if (reg.nickname.test(nickname)) {
      dispatch(checkUserNickname(nickname)); // 인증 요청
    } else {
      setFocus('mem_nickname');
    }
  };

  // 회원가입 클릭 시
  const onSubmit = datas => {
    if (!isCheckNickname) return alert('닉네임 중복체크를 해주세요');
    const formData = { ...datas };
    dispatch(signup(formData));
  };

  // 이메일 인증 예외처리
  useEffect(() => {
    switch (emailDone.code) {
      case 1000:
        alert('인증메일이 전송되었습니다.');
        break;
      case 5003:
        alert('이메일 형식이 올바르지 않습니다.');
        break;
      case 1003:
        alert('이미 사용중인 이메일 입니다.');
        break;
      default:
    }
    if (emailError) alert(emailError.msg);
  }, [emailDone, emailError]);

  // 닉네임 중복체크 예외처리
  useEffect(() => {
    switch (nicknameDone.code) {
      case 1000:
        alert('사용가능한 닉네임 입니다.');
        setIsCheckNickname(true);
        break;
      case 5003:
        alert('사용불가능한 닉네임 입니다.');
        break;
      default:
    }
    if (nicknameError) alert(nicknameError.msg);
  }, [nicknameDone, nicknameError]);

  // 회원가입 예외처리
  useEffect(() => {
    switch (signupDone.code) {
      case 1000:
        alert('회원가입이 완료 되었습니다.');
        navigate('/');
        break;
      case 2002:
        alert('인증코드가 일치하지 않습니다.');
        break;
      default:
    }
    if (signupError) alert(signupError.msg);
  }, [signupDone, signupError]);

  return (
    <S.Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <S.ContentWrap>
          <p>{t('signup')}</p>

          <S.FormTitle>
            <span>{t('signup1')}</span>
            <span>{t('signup2')}</span>
          </S.FormTitle>
          <S.InfoInputWrap>
            <div>
              <ul>
                <li>
                  <span>{t('signup3')}</span>
                </li>
                <li>
                  <input
                    {...register('mem_userid', {
                      required: true,
                    })}
                    type="text"
                    placeholder={t('signup4')}
                    maxLength="50"
                    onChange={e => setEmail(e.target.value)}
                  />
                  {emailLoading ? (
                    <Loading width="4%" display="inline" />
                  ) : (
                    <button type="button" onClick={certifyEmail}>
                      {t('signup5')}
                    </button>
                  )}

                  <S.WarningText>
                    {email === ''
                      ? null
                      : !reg.email.test(email) && t('signup6')}
                  </S.WarningText>
                  <p>{t('signup7')}</p>
                </li>
              </ul>
            </div>

            <div>
              <ul>
                <li>
                  <span>{t('signup8')}</span>
                </li>
                <li>
                  <input
                    type="text"
                    placeholder={t('signup9')}
                    maxLength={6}
                    {...register('certcode', { required: true })}
                  />
                  <h4>{t('signup10')}</h4>
                </li>
              </ul>
            </div>

            <div>
              <ul>
                <li>
                  <span>{t('signup11')}</span>
                </li>
                <li>
                  <input
                    type="text"
                    placeholder={t('signup12')}
                    maxLength={20}
                    {...register('mem_nickname', {
                      required: true,
                    })}
                    onChange={e => {
                      setNickname(e.target.value);
                      setIsCheckNickname(false);
                    }}
                  />
                  {nicknameLoading ? (
                    <Loading width="4%" display="inline" />
                  ) : (
                    <button type="button" onClick={checkNickname}>
                      {t('signup13')}
                    </button>
                  )}

                  <S.WarningText>
                    {nickname === ''
                      ? null
                      : !reg.nickname.test(nickname) && t('signup14')}
                  </S.WarningText>
                </li>
              </ul>
            </div>

            <div>
              <ul>
                <li>
                  <span>{t('signup15')}</span>
                </li>
                <li>
                  <input
                    type="password"
                    placeholder={t('signup16')}
                    maxLength="20"
                    {...register('mem_password', {
                      required: true,
                      pattern: {
                        value: reg.password,
                        message: t('signup17'),
                      },
                    })}
                  />
                  <S.WarningText>{errors.mem_password?.message}</S.WarningText>
                  <p>{t('signup18')}</p>
                  <p>{t('signup19')}</p>
                  <p>{t('signup20')}</p>
                  <p>{t('signup21')}</p>
                </li>
              </ul>
            </div>

            <div>
              <ul>
                <li>
                  <span>{t('signup22')}</span>
                </li>
                <li>
                  <input
                    type="password"
                    placeholder={t('signup23')}
                    {...register('mem_password_re', {
                      required: true,
                      validate: val => {
                        if (watch('mem_password') !== val) {
                          return t('signup24');
                        }
                      },
                    })}
                  />
                  <S.WarningText>
                    {errors.mem_password_re?.message}
                  </S.WarningText>
                </li>
              </ul>
            </div>

            <div>
              <ul>
                <li>
                  <span>{t('signup25')}</span>
                </li>
                <li>
                  <input
                    type="password"
                    placeholder={t('signup26')}
                    maxLength="4"
                    {...register('mem_code', {
                      required: true,
                      minLength: {
                        value: 4,
                        message: t('signup27'),
                      },
                    })}
                  />
                  <S.WarningText>{errors.security?.message}</S.WarningText>
                  <h4>{t('signup28')}</h4>
                  <h4>{t('signup29')}</h4>
                </li>
              </ul>
            </div>

            <div>
              <ul>
                <li>
                  <span>{t('signup30')}</span>
                </li>
                <li>
                  <input
                    type="password"
                    placeholder={t('signup31')}
                    maxLength="4"
                    {...register('mem_code_re', {
                      required: true,
                      validate: val => {
                        if (watch('mem_code') !== val) {
                          return t('signup32');
                        }
                      },
                    })}
                  />
                  <S.WarningText>{errors.mem_code_re?.message}</S.WarningText>
                </li>
              </ul>
            </div>
          </S.InfoInputWrap>

          <S.FormTitle>
            <span>{t('signup33')}</span>
          </S.FormTitle>
          <S.InfoInputWrap>
            <div>
              <ul>
                <li>
                  <span>{t('signup34')}</span>
                </li>
                <li>
                  <S.AgreeForm>
                    <label htmlFor="allcheck">
                      <input
                        type="checkbox"
                        id="allcheck"
                        onChange={e => onCheckedAll(e.target.checked)}
                        checked={idList.length === checkboxDatas.length}
                      />
                      {t('signup35')}
                    </label>
                    <h4>{t('signup36')}</h4>
                    <h4>{t('signup37')}</h4>
                    <h4>{t('signup38')}</h4>
                  </S.AgreeForm>
                  <S.AgreeForm>
                    {checkboxDatas.map(item => {
                      return (
                        <Fragment key={item.id}>
                          <div key={item.id}>
                            <label htmlFor={item.id}>
                              <input
                                type="checkbox"
                                id={item.id}
                                {...(item.essential
                                  ? {
                                      ...register(item.id, {
                                        required: {
                                          value: true,
                                          message: t('signup39'),
                                        },
                                      }),
                                    }
                                  : null)}
                                onChange={e =>
                                  onCheckedElement(e.target.checked, item)
                                }
                                checked={idList.includes(item.id)}
                              />
                              {item.title}
                              {item.essential && <span>{t('signup40')}</span>}
                            </label>
                            <button type="button">{t('signup41')}</button>
                          </div>

                          {item.essential && (
                            <S.WarningText>
                              {errors[item.id]?.message}
                            </S.WarningText>
                          )}
                        </Fragment>
                      );
                    })}
                  </S.AgreeForm>
                  {t('signup42')}
                </li>
              </ul>
            </div>
          </S.InfoInputWrap>
          <S.JoinBtns>
            {signupLoading && <Loading width="5%" />}
            <button type="submit">{t('signup43')}</button>
            <button type="button">{t('signup44')}</button>
          </S.JoinBtns>
        </S.ContentWrap>
      </form>
    </S.Container>
  );
}

export default SignUpContainer;
