/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getMenuList = createAsyncThunk(
  'main_menu/get',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get('menu_main/get_menu_main');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
