/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { closeOrder } from '../store/actions/exchange';
import * as S from '../styles/ExchangeOrderLog.style';
import reg from '../utils/regexp';

function ExchangeOrderLog({ coin }) {
  const { t } = useTranslation();

  const tapList = [
    {
      id: 1,
      tapname: t('exchange43'),
    },
    {
      id: 2,
      tapname: t('exchange44'),
    },
  ];
  const [tapState, setTapState] = useState(tapList[0]);

  const { conclusionOrder, notConclusionOrder, currentCoin } = useSelector(
    state => state.exchange,
  );
  const dispatch = useDispatch();

  // 주문 취소
  const onClickClose = id => {
    if (window.confirm(t('exchange45'))) {
      dispatch(
        closeOrder({
          order_id: id,
          market: currentCoin?.type2,
          coin: currentCoin?.coin_name,
        }),
      );
    }
  };

  return (
    <S.OrderLogBox>
      <S.TabList tapState={tapState.id}>
        {tapList.map(item => (
          <button type="button" key={item.id} onClick={() => setTapState(item)}>
            {item.tapname}
          </button>
        ))}
      </S.TabList>
      {
        {
          'Pending order': (
            <div className="content_wrap">
              <S.ContentHeader>
                <span>{t('exchange46')}</span>
                <span>{t('exchange47')}</span>
                <span>{t('exchange48')}</span>
                <span>{t('exchange49')}</span>
                <span>{t('exchange50')}</span>
                <span>{t('exchange51')}</span>
                <span>{t('exchange52')}</span>
                <span>{t('exchange53')}</span>
              </S.ContentHeader>
              <S.Content>
                {notConclusionOrder &&
                  notConclusionOrder.map(item => (
                    <ul key={item.od_idx}>
                      <li>{item.reg_date.split('.')[0].replace('T', ' ')}</li>
                      <li>{item.coin_type.toUpperCase()}</li>
                      <li>{item.od_type}</li>
                      <li>{reg.comma(item.price.toFixed(2).toString())} WAT</li>
                      <li>{item.quantity.toFixed(2)} BTS</li>
                      <li>
                        {reg.comma(item.real_price.toFixed(2))}
                        WAT
                      </li>
                      <li>2.5WAT</li>
                      <li>
                        {item.od_type === t('exchange54')
                          ? item.real_price - 2.5
                          : item.real_price + 2.5}{' '}
                        WAT
                      </li>
                      <li>
                        <button
                          type="button"
                          className="close_btn"
                          onClick={() => onClickClose(item.od_idx)}
                        >
                          {t('exchange55')}
                        </button>
                      </li>
                    </ul>
                  ))}
              </S.Content>
            </div>
          ),
          'Executed order': (
            <div className="content_wrap">
              <S.ContentHeader>
                <span>{t('exchange56')}</span>
                <span>{t('exchange57')}</span>
                <span>{t('exchange58')}</span>
                <span>{t('exchange59')}</span>
                <span>{t('exchange60')}</span>
                <span>{t('exchange61')}</span>
                <span>{t('exchange62')}</span>
                <span>{t('exchange63')}</span>
                <span>{t('exchange64')}</span>
              </S.ContentHeader>
              <S.Content>
                {conclusionOrder &&
                  conclusionOrder.map(item => (
                    <ul key={item.od_idx}>
                      <li>
                        {item.complete_date.split('.')[0].replace('T', ' ')}
                      </li>
                      <li>{coin.toUpperCase()}</li>
                      <li>{item.od_type}</li>
                      <li>{reg.comma(item.price.toFixed(2))} WAT</li>
                      <li>
                        {reg.comma(item.quantity.toFixed(2))}{' '}
                        {coin.toUpperCase()}
                      </li>
                      <li>
                        {reg.comma(Number(item.real_price).toFixed(2))} WAT
                      </li>
                      <li>2.5 WAT</li>
                      <li>997.5WAT</li>
                      <li>{item.reg_date.split('.')[0].replace('T', ' ')}</li>
                    </ul>
                  ))}
              </S.Content>
            </div>
          ),
        }[tapState.tapname]
      }
    </S.OrderLogBox>
  );
}

ExchangeOrderLog.propTypes = {
  coin: PropTypes.node.isRequired,
};
export default ExchangeOrderLog;
