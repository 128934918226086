/* eslint-disable no-alert */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as S from '../styles/MyOpenApis.style';
import CreateMyOpenApi from './CreateMyOpenApi';

function MyOpenApis() {
  const [myOpenApiState, setMyOpenApiState] = useState('main');

  const { t } = useTranslation();

  const { me } = useSelector(state => state.user);

  return (
    <S.MyOpenApiWrap>
      {
        {
          main: (
            <>
              <div className="create_api_wrap">
                <p className="title">{t('mypage26')}</p>
                <button
                  type="button"
                  onClick={() =>
                    me?.mem_is_otp === 0
                      ? alert(t('mypage27'))
                      : setMyOpenApiState('create')
                  }
                >
                  {t('mypage26')}
                </button>
              </div>
              <div className="my_apikey_title">
                <p className="title">{t('mypage28')} Open API Key</p>
              </div>
              <S.MyOpenApi>
                <div className="header">
                  <span>{t('mypage29')}</span>
                  <button type="button">{t('mypage30')}</button>
                </div>
                <div className="info">
                  <div className="key_wrap">
                    <h4>Access Key</h4>
                    <span>
                      Wek32r23iofweijfkeqkfqiwefjkdqflwkejflqwefijwefkjqkwfqke
                    </span>
                  </div>
                  <div className="checkboxs">
                    <label htmlFor="menu1">
                      <input type="checkbox" id="menu1" />
                      <span>{t('mypage31')}</span>
                    </label>
                    <label htmlFor="menu2">
                      <input type="checkbox" id="menu2" />
                      <span>{t('mypage32')}</span>
                    </label>
                    <label htmlFor="menu3">
                      <input type="checkbox" id="menu3" />
                      <span>{t('mypage33')}</span>
                    </label>
                    <label htmlFor="menu4">
                      <input type="checkbox" id="menu4" />
                      <span>{t('mypage34')}</span>
                    </label>
                    <label htmlFor="menu5">
                      <input type="checkbox" id="menu5" />
                      <span>{t('mypage35')}</span>
                    </label>
                    <label htmlFor="menu6">
                      <input type="checkbox" id="menu6" />
                      <span>{t('mypage36')}</span>
                    </label>
                  </div>
                </div>
                <div className="ip_info">
                  <span>{t('mypage37')}</span>
                  <button type="button">{t('mypage38')}</button>
                  <div className="ips">
                    <span>111.111.111</span>
                  </div>
                </div>
              </S.MyOpenApi>
            </>
          ),
          create: <CreateMyOpenApi setMyOpenApiState={setMyOpenApiState} />,
        }[myOpenApiState]
      }
    </S.MyOpenApiWrap>
  );
}

export default MyOpenApis;
