/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
// import { reToken } from './user';

// openApi 생성 요청
export const createOpenApi = createAsyncThunk(
  'OpenApi/create',
  async (data, thunkAPI) => {
    try {
      const response = await axios.post('/openapi/insert_openapi', data);
      // response.data.code === 1007 && thunkAPI.dispatch(reToken(createOpenApi));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
