import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MyOpenApis from '../components/MyOpenApis';
import MypageHeader from '../components/MypageHeader';
import { MypageSection } from '../styles/MypageSection';
import * as S from '../styles/OpenapiContainer.style';

function OpenapiContainer() {
  const [guideModal, setGuideModal] = useState(true);

  const { t } = useTranslation();
  return (
    <>
      <MypageHeader />
      <MypageSection>
        {guideModal ? (
          <S.OpenapiWrap>
            <p>Open API</p>
            <span>{t('mypage23')}</span>
            <br />
            <br />

            <span>{t('mypage24')}</span>
            <br />
            <button type="button" onClick={() => setGuideModal(false)}>
              {t('mypage25')}
            </button>
          </S.OpenapiWrap>
        ) : (
          <MyOpenApis />
        )}
      </MypageSection>
    </>
  );
}

export default OpenapiContainer;
