/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/style-prop-object */
/* eslint-disable react/self-closing-comp */
import React, { useState } from 'react';
import styled from 'styled-components';

const Wrap = styled.div`
  position: absolute;
  top: 200px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  img {
    width: 200px;
  }

  input {
    width: 250px;
    height: 30px;
  }

  button {
    width: 200px;
    height: 40px;
    background: skyblue;
    border: 0;
    color: white;
    cursor: pointer;
  }
`;
function PasswordInput() {
  const [password, setPassword] = useState('');
  const onSubmit = () => {
    if (password === '12341') {
      sessionStorage.setItem('passwordinput', 'true');
    }
  };
  return (
    <form onSubmit={() => onSubmit()}>
      <Wrap>
        <img src="/images/WADEX_C.png" alt="" />
        <input
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <button type="submit">Access</button>
      </Wrap>
    </form>
  );
}

export default PasswordInput;
