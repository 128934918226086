/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const CoinInfoBox = styled.div`
  padding: 10px;
  height: 480px;
  border: 1px solid black;
  font-size: ${({ theme }) => theme.fontSizes.small};
  position: relative;
`;

export const TabList = styled.div`
  border-bottom: 1px solid #ccc;
  margin-bottom: 5px;

  button {
    border: 0;
    background-color: transparent;
    padding: 10px 15px;

    &: nth-of-type(${({ tapState }) => tapState}) {
      border-bottom: 4px solid ${({ theme }) => theme.colors.orange1};
      color: ${({ theme }) => theme.colors.orange1};
    }
  }
`;
