/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Wrap = styled.div`
  ${({ theme }) => theme.defaultLayout()};

  .title {
    font-size: ${({ theme }) => theme.fontSizes.xxxl};
    color: ${({ theme }) => theme.colors.blue3};
    margin-top: 20px;
  }

  .my_status {
    width: 600px;
    margin-top: 20px;

    p {
      font-size: ${({ theme }) => theme.fontSizes.lg};
      font-weight: ${({ theme }) => theme.fontWeights.xl};
      border-bottom: 1px solid #ccc;
      padding-bottom: 10px;
    }

    .contents {
      padding: 30px;

      ul {
        display: flex;
        margin-bottom: 50px;
        justify-content: space-between;
      }
    }
  }

  .request {
    width: 1000px;
    margin-top: 20px;

    p {
      font-size: ${({ theme }) => theme.fontSizes.lg};
      font-weight: ${({ theme }) => theme.fontWeights.xl};
      border-bottom: 1px solid #ccc;
      padding-bottom: 10px;
    }

    .contents {
      padding: 20px 50px;

      h1 {
        background-color: ${({ theme }) => theme.backgroundColors.beige1};
        padding: 10px;
        margin-bottom: 20px;
      }

      .otp_text {
        border: 0;
      }

      .guide_text {
        margin-top: 30px;
        line-height: 1.5;
      }
    }
  }
`;

export const CoinInfo = styled.div`
  width: 70%;
  display: flex;
  gap: 50px;
  div {
    width: 50%;
    display: flex;
    justify-content: space-between;
  }
`;

export const WithdrawInfo = styled(CoinInfo)`
  margin-top: 40px;
  font-size: ${({ theme }) => theme.fontSizes.lg};
  font-weight: ${({ theme }) => theme.fontWeights.xl};
`;

export const RequestInfo = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;
  border: 1px solid #eee;

  ul {
    display: flex;

    &:not(:last-child) {
      border-bottom: 1px solid #eee;
    }

    li {
      padding: 20px;
      &:nth-of-type(1) {
        width: 30%;
        background-color: ${({ theme }) => theme.backgroundColors.beige2};
      }
      &:nth-of-type(2) {
        width: 70%;
      }
    }
  }
`;
