/* eslint-disable no-alert */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as S from '../styles/OtpRegistModal.style';
import {
  certifyUserEmail,
  createUserOtp,
  logout,
  registUserOtp,
} from '../store/actions/user';
import Loading from './Loading';

function OtpRegistModal({ setModal }) {
  const [otpRegistStep, setOtpRegistStep] = useState(0);
  const [certCode, setCertCode] = useState('');
  const [otpCode, setOtpCode] = useState('');
  const [action1, setAction1] = useState(false);
  const [action2, setAction2] = useState(false);

  const navigate = useNavigate();
  const { createOtpDone, me, emailDone, emailLoading, registOtpDone } =
    useSelector(state => state.user);
  const dispatch = useDispatch();

  // 다음 클릭 시 이메일인증 요청
  const certifyEmail = () => {
    dispatch(
      certifyUserEmail({
        mem_userid: me?.info?.mem_userid,
        type: 'regist_otp',
      }),
    ); // 인증 요청
    setAction1(true);
  };

  // otp 등록 요청
  const onClickRegistOtp = () => {
    dispatch(
      registUserOtp({
        certcode: certCode,
        otpcode: otpCode,
      }),
    ); // 등록 요청
    setAction2(true);
  };

  // otp 생성 요청
  useEffect(() => {
    dispatch(createUserOtp());
  }, []);

  // 이메일 인증 예외처리
  useEffect(() => {
    if (action1) {
      if (emailDone) {
        if (emailDone?.code === 1000) {
          setOtpRegistStep(1);
        } else if (emailDone?.code !== 1000) {
          alert('error');
        }
        setAction1(false);
      }
    }
  }, [emailDone]);

  // otp 등록 예외처리
  useEffect(() => {
    if (action2) {
      if (registOtpDone) {
        if (registOtpDone?.code === 1000) {
          dispatch(logout(me?.mem_id));
          setOtpRegistStep(2);
        } else if (registOtpDone?.code === 2002) {
          alert('이메일 인증번호를 확인해 주세요');
        } else if (registOtpDone?.code === 8004) {
          alert('OTP 인증 코드를 확인해 주세요');
        } else if (registOtpDone?.code === 1008) {
          alert('로그인 세션이 만료되었습니다');
          dispatch(logout(me?.mem_id));
          navigate('/login');
        }
        setAction2(false);
      }
    }
  }, [registOtpDone]);
  return (
    <S.OtpFormWrap>
      {
        {
          0: (
            <>
              <div className="title">
                <p>Google OTP 등록</p>
                <img src="/images/WADEX_C.png" alt="wadex_logo" />
              </div>
              <h4 className="subtitle">1. Google OTP 인증 앱 설치</h4>
              <h5 className="guide_text">
                Google Play에선 'Google OTP', App store에선 <br />
                'Google Authenticator' 앱을 설치하세요.
              </h5>
              <h4 className="subtitle">2. 앱 실행 후 OTP키 생성</h4>
              <h5 className="guide_text">
                다운받은 OTP앱에서 QR코드를 스캔하세요. <br />
                혹은 앱에서 하단의 코드를 직접 입력하세요.
              </h5>
              <div className="qr_wrap">
                {createOtpDone && (
                  <>
                    <img
                      src={`https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=200x200&chld=M|0&cht=qr&chl=otpauth://totp/user2@host.com%3Fsecret%3D${createOtpDone?.mem_otp}`}
                      alt=""
                    />
                    <span className="qrcode">{createOtpDone?.mem_otp}</span>
                  </>
                )}

                <button
                  type="button"
                  className="regist_email"
                  style={{ marginTop: '10px' }}
                >
                  복사하기
                </button>
              </div>
              <div className="btns">
                <button type="button" onClick={() => setModal('none')}>
                  취소
                </button>
                <button type="button" onClick={certifyEmail}>
                  다음
                </button>
              </div>
              {emailLoading && <Loading width="10%" />}
            </>
          ),
          1: (
            <>
              <div className="title">
                <p>Google OTP 등록</p>
                <img src="/images/WADEX_C.png" alt="wadex_logo" />
              </div>
              <h4 className="subtitle">3. 인증 코드 입력</h4>
              <h5 className="guide_text">
                이메일로 인증 번호를 발송했습니다. 번호 6자리를 정확히
                입력해주세요.
                <br /> Google OTP 앱에 표시된 인증 코드를 입력 후 다음 버튼을
                눌러주세요.
              </h5>
              <h6 className="input_text">Email 인증번호 입력</h6>
              <input
                type="text"
                maxLength="6"
                className="email_input"
                onChange={e => setCertCode(e.target.value)}
              />
              <h6 className="input_text">OTP 인증 코드 입력</h6>
              <input
                type="text"
                className="otp_input"
                onChange={e => setOtpCode(e.target.value)}
                maxLength="6"
              />
              <div className="btns">
                <button type="button" onClick={() => setModal('none')}>
                  취소
                </button>
                <button type="button" onClick={onClickRegistOtp}>
                  다음
                </button>
              </div>
            </>
          ),
          2: (
            <>
              <div className="title">
                <p>Google OTP 등록</p>
                <img src="/images/WADEX_C.png" alt="wadex_logo" />
              </div>
              <h4 className="subtitle">4. 인증 완료</h4>
              <h5 className="guide_text">
                발급된 16자리 코드를 따로 저장하세요. <br />
                코드는 OTP의 복구를 위해 사용됩니다.
              </h5>
              <input
                type="text"
                maxLength="6"
                className="copy_otpcode"
                readOnly
                value={createOtpDone?.mem_otp}
              />
              <button type="button" className="regist_email">
                복사하기
              </button>
              <div className="btns">
                <button
                  type="button"
                  className="next"
                  onClick={() => navigate('/login')}
                >
                  완료
                </button>
              </div>
            </>
          ),
        }[otpRegistStep]
      }
    </S.OtpFormWrap>
  );
}

OtpRegistModal.propTypes = {
  setModal: PropTypes.func.isRequired,
};

export default OtpRegistModal;
