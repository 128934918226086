import React from 'react';
import SubHeader from '../components/SubHeader';
import * as S from '../styles/StakingContainer.style';

function StakingContainer() {
  return (
    <>
      <SubHeader>WBD</SubHeader>
      <S.Wrap>
        <img src="/images/staking_en.jpg" alt="" />
      </S.Wrap>
    </>
  );
}

export default StakingContainer;
