/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable import/named */
/* eslint-disable import/no-cycle */
/* eslint-disable no-unsafe-optional-chaining */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { setToken } from '../slices/token';
import setAuthorizationToken from '../../utils/setAuthorizationToken';
import { getCookie, removeCookie, setCookie } from '../../utils/cookie';
import { isLoginCheck } from '../slices/user';

// token 재발행 액션
export const reToken = createAsyncThunk(
  'user/retoken',
  async (data, thunkAPI) => {
    try {
      setAuthorizationToken(getCookie('refresh_token'));
      const response = await axios.post('/auth/make_acc_to_ref', {
        token: getCookie('access_token'),
      });
      const { accessToken } = response.data?.info;
      setCookie('access_token', accessToken);
      setAuthorizationToken(accessToken);
      thunkAPI.dispatch(data());
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

// 이메일 인증 요청 액션
export const certifyUserEmail = createAsyncThunk(
  'user/email',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/auth/ajax_auth_email', data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// 닉네임 중복체크 요청 액션
export const checkUserNickname = createAsyncThunk(
  'user/checkNickname',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/auth/ajax_nickname_check', {
        mem_nickname: data,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// 닉네임 변경 요청 액션
export const changeUserNickname = createAsyncThunk(
  'user/changeNickname',
  async (data, thunkAPI) => {
    try {
      const response = await axios.post('/member_info/update_nickname', data);
      response.data.code === 1007 &&
        thunkAPI.dispatch(reToken(changeUserNickname));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// 비밀번호 변경 요청 액션
export const changeUserPassword = createAsyncThunk(
  'user/changePassword',
  async (data, thunkAPI) => {
    try {
      const response = await axios.post('/member_info/update_password', data);
      response.data.code === 1007 &&
        thunkAPI.dispatch(reToken(changeUserPassword));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// 회원가입 요청 액션
export const signup = createAsyncThunk(
  'user/signup',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/auth/sign_up', data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// 로그인 요청 액션
export const login = createAsyncThunk('user/login', async (data, thunkAPI) => {
  try {
    const response = await axios.post('/auth/sign_in', data);
    if (response.data.code === 1000) {
      const { accessToken, refreshToken } = response.data?.info;
      thunkAPI.dispatch(isLoginCheck(response.data.code));
      thunkAPI.dispatch(setToken(accessToken));
      setAuthorizationToken(accessToken);
      setCookie('refresh_token', refreshToken);
      setCookie('access_token', accessToken);
    }

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

// 로그인시 otp인증 요청 액션
export const loginOtp = createAsyncThunk(
  'user/loginOtp',
  async (data, thunkAPI) => {
    try {
      const response = await axios.post('/auth/auth_otp', data);
      if (response.data.code === 1000) {
        const { accessToken, refreshToken } = response.data?.info;
        thunkAPI.dispatch(isLoginCheck(response.data.code));
        thunkAPI.dispatch(setToken(accessToken));
        setAuthorizationToken(accessToken);
        setCookie('refresh_token', refreshToken);
        setCookie('access_token', accessToken);
      }

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

// 로그아웃 요청 액션
export const logout = createAsyncThunk(
  'user/logout',
  async (data, thunkAPI) => {
    try {
      setAuthorizationToken(getCookie('refresh_token'));
      const response = await axios.post('/auth/sign_out', {
        mem_id: data,
      });
      thunkAPI.dispatch(isLoginCheck(false));
      removeCookie('refresh_token');
      removeCookie('access_token');
      setAuthorizationToken();
      // window.location.reload();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

// 접속관리 로그 요청 액션
export const userLoginLog = createAsyncThunk(
  'user/loginLog',
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(
        `/member_info/get_login_log?page=${data}`,
      );
      thunkAPI.dispatch(isLoginCheck(response.data.code));
      response.data.code === 1007 && thunkAPI.dispatch(reToken(userLoginLog));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
// otp 생성 요청 액션
export const createUserOtp = createAsyncThunk(
  'user/createOtp',
  async (data, thunkAPI) => {
    try {
      const response = await axios.post('/auth/create_otp');
      thunkAPI.dispatch(isLoginCheck(response.data.code));
      response.data.code === 1007 && thunkAPI.dispatch(reToken(userLoginLog));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

// otp 등록 요청 액션
export const registUserOtp = createAsyncThunk(
  'user/registOtp',
  async (data, thunkAPI) => {
    try {
      const response = await axios.post('/auth/regist_otp', data);
      thunkAPI.dispatch(isLoginCheck(response.data.code));
      response.data.code === 1007 && thunkAPI.dispatch(reToken(userLoginLog));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

// 유저정보 요청 액션
export const userInfo = createAsyncThunk(
  'user/info',
  async (data, thunkAPI) => {
    try {
      const response = await axios.get('/member_info/get_mem_info');
      thunkAPI.dispatch(isLoginCheck(response.data.code));
      response.data.code === 1007 && thunkAPI.dispatch(reToken(userInfo));
      response.data.code === 1008 && thunkAPI.dispatch(logout());
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
