import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as S from '../styles/ExchangeHeader.style';

function ExchangeHeader() {
  const { currentCoin } = useSelector(state => state.exchange);

  const { t } = useTranslation();

  return (
    <S.HeaderBox>
      <ul className="header_info">
        <li>
          <p className="title">
            {currentCoin && currentCoin.type.toUpperCase()} /{' '}
            {currentCoin && currentCoin.type2.toUpperCase()}
          </p>
          <button type="button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-sticky-fill"
              viewBox="0 0 16 16"
            >
              <path d="M2.5 1A1.5 1.5 0 0 0 1 2.5v11A1.5 1.5 0 0 0 2.5 15h6.086a1.5 1.5 0 0 0 1.06-.44l4.915-4.914A1.5 1.5 0 0 0 15 8.586V2.5A1.5 1.5 0 0 0 13.5 1h-11zm6 8.5a1 1 0 0 1 1-1h4.396a.25.25 0 0 1 .177.427l-5.146 5.146a.25.25 0 0 1-.427-.177V9.5z" />
            </svg>
            {currentCoin && currentCoin.type.toUpperCase()}
          </button>
        </li>
        <li>
          <p>{t('exchange7')}</p>
          <p>
            {currentCoin && currentCoin.last_price}{' '}
            {currentCoin && currentCoin.type2.toUpperCase()}
          </p>
        </li>
        <li>
          <p>{t('exchange8')}</p>
          <p className="font_red">{currentCoin && currentCoin.prcent} %</p>
        </li>
        <li>
          <p>{t('exchange9')}</p>
          <p>
            {currentCoin && currentCoin.value}{' '}
            {currentCoin && currentCoin.type.toUpperCase()}
          </p>
        </li>
        <li>
          <p>{t('exchange10')}</p>
          <p> {currentCoin && currentCoin.max_price}</p>
        </li>
        <li>
          <p>{t('exchange11')}</p>
          <p>{currentCoin && currentCoin.min_price}</p>
        </li>
      </ul>
    </S.HeaderBox>
  );
}

export default ExchangeHeader;
