/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const pwModalWrap = styled.div`
  margin: 0 auto;
  width: 500px;
  border: 1px solid #ccc;
  padding: 30px;
  line-height: 1.2;

  .wadex_img {
    text-align: right;

    img {
      height: 20px;
    }
  }

  .pwChange_input {
    margin-top: 60px;

    p {
      font-size: ${({ theme }) => theme.fontSizes.title};
      font-weight: ${({ theme }) => theme.fontWeights.lg};
      color: ${({ theme }) => theme.colors.blue3};
    }

    input {
      width: 100%;
      height: 40px;
      margin-top: 30px;
      padding-left: 10px;
    }
  }

  .new_pw_input {
    margin-top: 60px;

    .text_input {
      font-size: ${({ theme }) => theme.fontSizes.xxl};
      font-weight: ${({ theme }) => theme.fontWeights.lg};
      color: ${({ theme }) => theme.colors.blue3};
      margin-bottom: 5px;
      margin-top: 30px;
    }

    input {
      width: 100%;
      height: 40px;
    }
  }

  .complete_form {
    margin-top: 60px;
    text-align: center;

    p {
      font-size: ${({ theme }) => theme.fontSizes.title};
      font-weight: ${({ theme }) => theme.fontWeights.lg};
      color: ${({ theme }) => theme.colors.blue3};
      margin-bottom: 30px;
    }

    .login_btn {
      display: inline-block;
      margin-top: 40px;
      width: 120px;
      height: 40px;
      line-height: 40px;
      background-color: ${({ theme }) => theme.backgroundColors.blue2};
      color: white;
    }
  }

  .modal_btns {
    display: flex;
    gap: 5px;
    margin-top: 100px;
    justify-content: flex-end;

    button {
      width: 80px;
      height: 40px;
      border: 0;
      color: white;

      &:nth-of-type(1) {
        background-color: ${({ theme }) => theme.backgroundColors.black1};
      }
      &:nth-of-type(2) {
        background-color: ${({ theme }) => theme.backgroundColors.blue2};
      }
    }
  }
`;
export const WarningText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.small} !important;
  color: ${({ theme }) => theme.colors.red1} !important;
  border: 0 !important;
  margin-top: 10px;
`;
