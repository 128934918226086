/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint-disable func-names */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  setCoinList,
  setCompleteCoinInfo,
  setConclusionOrder,
  setNotConclusionOrder,
  setOrderBook,
  setTransactionLog,
} from '../store/slices/exchange';
import { SOCKET_URL } from '../config/config';

function ExchangeDatas({ coin, market }) {
  const socket = io(SOCKET_URL);
  socket.connect();
  const node = {};

  const dispatch = useDispatch();
  const { allUpdateFavorDone, currentCoin } = useSelector(
    state => state.exchange,
  );
  const { me } = useSelector(state => state.user);

  // 소켓함수 선언
  // 오더북, 거래기록
  node.get_real_coin_info = function (
    coinName,
    marketName,
    callback1,
    callback2,
  ) {
    socket.on('connectionOk', function () {
      socket.emit('joinRoom', coinName, marketName);
    });
    socket.on('hoga', function (data) {
      callback1(data);
    });
    socket.on('his', function (data) {
      callback2(data);
    });
  };

  // 유저, coin, market 체크
  node.user_login = function (userIdx, coinType, marketType) {
    socket.emit('user_login', userIdx, coinType, marketType);
  };

  // 미체결 주문
  node.order_out_updata = function (callback) {
    socket.on('order_out_updata', function (data) {
      callback(data);
    });
  };

  // 체결 주문
  node.order_updata = function (callback) {
    socket.on('order_updata', callback);
  };

  // 코인리스트
  node.get_coin_price_info = function (callback) {
    socket.emit('get_coin_price_info', function (data) {
      callback(data);
    });
  };

  // 체결된 코인 정보
  node.get_coin_price_info_one = function (callback) {
    socket.on('get_coin_price_info_one', callback);
  };

  useEffect(() => {
    // 호출
    node.get_real_coin_info(
      coin,
      market,
      data => {
        node.user_login(
          me?.info?.mem_id,
          coin.toUpperCase(),
          market.toUpperCase(),
        );
        dispatch(setOrderBook(data));
      },
      data => {
        dispatch(setTransactionLog(data));
      },
    );

    node.order_out_updata(function (data) {
      dispatch(setNotConclusionOrder(data));
    });

    node.order_updata(function (data) {
      dispatch(setConclusionOrder(data));
    });

    node.get_coin_price_info(data2 => {
      const coin_list = data2;

      if (allUpdateFavorDone) {
        coin_list.forEach(_data => {
          const prcent_price = _data.last_price - _data.yesterday_price;
          let prcent =
            ((_data.last_price - _data.yesterday_price) /
              _data.yesterday_price) *
            100;
          if (prcent === 'Infinity') {
            prcent = 0;
          }

          prcent = prcent.toFixed(2);

          let prcent_sring = '';

          if (prcent_price < 0) {
            // prcent_sring = '-';
          } else {
            prcent_sring = '+';
          }
          if (prcent === '0.00') {
            prcent_sring = '';
          }

          if (prcent === 'NaN' || prcent_price === '0') {
            prcent_sring = '';
            prcent = '0.00';
          }

          _data.prcent_sring = prcent_sring;
          _data.prcent = prcent;
        });

        const favorCoinList = [];
        coin_list.forEach(item => {
          let favor = '';
          allUpdateFavorDone?.info?.forEach(subItem => {
            if (item.type.toUpperCase() === subItem.bal_token) {
              favor = subItem.bal_favor === '1' ? '1' : '0';
            }
          });
          favorCoinList.push({ ...item, favor });
        });
        dispatch(setCoinList({ data: favorCoinList, type: coin }));

        // dispatch(setCoinList({ data: coin_list, type: coin }));
      }
    });

    node.get_coin_price_info_one(function (data) {
      const coin_list = data;

      const prcent_price = coin_list.last_price - coin_list.yesterday_price;
      let prcent =
        ((coin_list.last_price - coin_list.yesterday_price) /
          coin_list.yesterday_price) *
        100;
      if (prcent === 'Infinity') {
        prcent = 0;
      }

      prcent = prcent.toFixed(2);

      let prcent_sring = '';

      if (prcent_price < 0) {
        prcent_sring = '-';
      } else {
        prcent_sring = '+';
      }

      if (prcent === '0.00') {
        prcent_sring = '';
      }

      if (prcent === 'NaN' || prcent_price === '0') {
        prcent_sring = '';
        prcent = '0.00';
      }

      coin_list.prcent_sring = prcent_sring;
      coin_list.prcent = prcent;
      coin_list.favor = currentCoin.favor === '1' ? '1' : '0';
      dispatch(setCompleteCoinInfo(coin_list));
    });

    return () => {
      socket.close();
    };
  }, [me, coin, allUpdateFavorDone]);

  return <div />;
}

ExchangeDatas.propTypes = {
  coin: PropTypes.string.isRequired,
  market: PropTypes.string.isRequired,
};

export default ExchangeDatas;
