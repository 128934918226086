/* eslint-disable no-alert */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { login, loginOtp } from '../store/actions/user';
import * as S from '../styles/LoginContainer.style';
import Loading from '../components/Loading';
import InfoPwModal from '../components/InfoPwModal';
import { resetMe } from '../store/slices/user';

function LoginContainer() {
  const [modal, setModal] = useState('none');
  const [action, setAction] = useState(false);
  const [otpAction, setOtpAction] = useState(false);
  const [otpInput, setOtpInput] = useState('');
  const { t } = useTranslation();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loginLoading, loginError, me, createOtpDone, createOtpError } =
    useSelector(state => state.user);

  // 로그인 요청
  const onSubmit = datas => {
    dispatch(login(datas));
    setAction(true);
  };

  // otp 인증 요청
  const onClickOtpCheck = () => {
    dispatch(
      loginOtp({
        mem_userid: me?.info?.mem_userid,
        otpcode: otpInput,
        type: 'sign_in',
      }),
    );
    setOtpAction(true);
  };

  // 로그인 요청시 예외처리
  useEffect(() => {
    if (action) {
      if (me) {
        if (me?.code === 1000) {
          navigate('/');
        } else if (me?.code === 8005) {
          setModal('otpCheck');
        } else if (me?.code === 1006 || me?.code === 1005) {
          alert('아이디 혹은 비밀번호를 확인해주세요');
          dispatch(resetMe());
        }
        setAction(false);
      }
      if (loginError) {
        alert('아이디 혹은 비밀번호를 확인해주세요');
        setAction(false);
      }
    }
  }, [me, loginError, action]);

  // otp 인증 요청시 예외처리
  useEffect(() => {
    if (otpAction) {
      if (createOtpDone) {
        if (createOtpDone?.code === 1000) {
          navigate('/');
        } else if (createOtpDone?.code !== 1000) {
          alert('입력한 값을 확인해주세요');
        }
        setOtpAction(false);
      }
      if (createOtpError) {
        alert('입력한 값을 확인해주세요');
        setOtpAction(false);
      }
    }
  }, [createOtpDone, createOtpError]);

  return (
    <S.Container>
      {
        {
          none: (
            <form onSubmit={handleSubmit(onSubmit)}>
              <S.LoginWrap>
                <p>{t('login')}</p>
                <input
                  type="text"
                  placeholder="Email"
                  {...register('userid', {
                    required: {
                      value: true,
                      message: t('email1'),
                    },
                  })}
                />
                <S.WarningText>{errors.userid?.message}</S.WarningText>
                <input
                  type="password"
                  placeholder="Password"
                  {...register('password', {
                    required: {
                      value: true,
                      message: t('password1'),
                    },
                  })}
                />
                <S.WarningText>{errors.password?.message}</S.WarningText>
                {loginLoading && <Loading width="5%" />}
                <button type="submit">{t('login')}</button>
                <S.Btns>
                  <button type="button">{t('signup')}</button>
                  <button type="button" onClick={() => setModal('pwChange')}>
                    {t('password2')}
                  </button>
                </S.Btns>
              </S.LoginWrap>
            </form>
          ),
          pwChange: <InfoPwModal setModal={setModal} />,
          otpCheck: (
            <div className="otpModal">
              <p className="modal_title1">Open API 생성</p>
              <p className="modal_title2">OTP 인증번호 입력</p>
              <span>
                Google OTP / Google Authenticator 앱에 표시된
                <br />
                OTP인증번호 6자리를 시간내에 입력해주세요.
              </span>
              <input
                type="text"
                className="otp_input"
                onChange={e => setOtpInput(e.target.value)}
                maxLength="6"
              />
              <button type="button" className="btn" onClick={onClickOtpCheck}>
                완료
              </button>
            </div>
          ),
        }[modal]
      }
    </S.Container>
  );
}

export default LoginContainer;
