/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const OrderBookBox = styled.div`
  height: 430px;
  border: 1px solid black;
  font-size: ${({ theme }) => theme.fontSizes.xs};

  .tab {
    padding: 0 10px;

    button {
      border: 0;
      background-color: transparent;
      padding: 5px 20px;
      border-bottom: 4px solid ${({ theme }) => theme.colors.orange1};
      color: ${({ theme }) => theme.colors.orange1};
    }
  }
  .list_wrap {
    height: 395px;
    overflow-y: scroll;
  }
  .list_header {
    padding: 10px 0;
    background-color: ${({ theme }) => theme.backgroundColors.lightGray3};

    span {
      display: inline-block;
      width: 50%;
      text-align: center;
    }
  }

  .list {
    .sell_box {
      background-color: ${({ theme }) => theme.backgroundColors.blue3};
    }

    .buy_box {
      background-color: ${({ theme }) => theme.backgroundColors.pink2};
    }

    ul {
      padding-left: 10px;
      position: relative;

      li {
        display: inline-block;
        width: 50%;
        padding: 10px 0;
      }

      .price {
        font-size: ${({ theme }) => theme.fontSizes.base};
        color: ${({ theme }) => theme.colors.pink1};
        font-weight: ${({ theme }) => theme.fontWeights.lg};
      }

      .change_percent {
        width: 0;
        position: absolute;
        left: 100px;
        top: 5px;
        color: ${({ theme }) => theme.colors.pink1};
        font-weight: ${({ theme }) => theme.fontWeights.lg};
      }

      .count {
        font-size: ${({ theme }) => theme.fontSizes.base};
        text-align: center;
      }
    }
  }
`;
