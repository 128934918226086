import styled from 'styled-components';

export const Container = styled.section`
  padding: 100px 0;

  .otpModal {
    width: 500px;
    height: 500px;
    border: 1px solid #ccc;
    margin: 0 auto;
    padding: 30px 40px;

    .modal_title1,
    .modal_title2 {
      font-size: ${({ theme }) => theme.fontSizes.title};
      color: ${({ theme }) => theme.colors.blue3};
    }

    .modal_title1 {
      margin-bottom: 50px;
    }

    .modal_title2 {
      margin-bottom: 10px;
    }

    span {
      color: ${({ theme }) => theme.colors.gray2};
    }

    .otp_input {
      margin-top: 20px;
      width: 100%;
      height: 40px;
    }

    .btn {
      display: block;
      margin-top: 30px;
      margin-left: auto;
      padding: 5px 15px;
      color: white;
      background-color: ${({ theme }) => theme.backgroundColors.blue2};
    }
  }
`;
export const LoginWrap = styled.div`
  max-width: 480px;
  padding: 50px 25px 35px;
  margin: 0 auto;
  border: 1px solid #e3e3e3;
  border-radius: 15px;

  & > p {
    margin-bottom: 50px;
    font-size: ${({ theme }) => theme.fontSizes.title};
    font-weight: ${({ theme }) => theme.fontWeights.xl};
    text-align: center;
  }

  input {
    width: 100%;
    border: 1px solid #e3e3e3;
    margin: 10px 0;
    padding-left: 15px;
    height: 46px;
    line-height: 44px;
    background-color: ${({ theme }) => theme.backgroundColors.lightGray1};
    border-radius: 5px;

    &:focus {
      background-color: white;
    }
  }
  & > button {
    font-size: ${({ theme }) => theme.fontSizes.base};
    width: 100%;
    padding: 11px 0;
    margin-top: 10px;
    color: ${({ theme }) => theme.colors.white1};
    background-color: #0d7de7;
    border-radius: 5px;
    border: 0;
  }
`;

export const Btns = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  button {
    border: 0;
    background-color: transparent;
    font-weight: ${({ theme }) => theme.fontWeights.lg};
  }
  button:nth-of-type(1) {
    color: ${({ theme }) => theme.colors.blue2};
  }
`;

export const WarningText = styled.h4`
  color: ${({ theme }) => theme.colors.red1} !important;
  margin: 0 !important;
`;
