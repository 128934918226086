import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import axios from 'axios';
import GlobalStyle from './styles/GlobalStyle';
import theme from './styles/Theme';
import store from './store/store';
import Routes from './routes/Routes';
import { BASE_URL } from './config/config';
import './config/lang/i18n';
import setAuthorizationToken from './utils/setAuthorizationToken';
import { getCookie } from './utils/cookie';
import { userInfo } from './store/actions/user';
import PasswordInput from './components/PasswordInput';

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

setAuthorizationToken(getCookie('access_token'));
const userId = store.getState().user.me;
store.dispatch(userInfo(userId));

if (!sessionStorage.getItem('passwordinput')) {
  sessionStorage.setItem('passwordinput', 'false');
}

const isPassWord = sessionStorage.getItem('passwordinput');

if (isPassWord === 'false') {
  ReactDOM.render(
    <PasswordInput />,

    document.getElementById('root'),
  );
} else {
  ReactDOM.render(
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Routes />
        </Provider>
      </ThemeProvider>
    </>,

    document.getElementById('root'),
  );
}
