import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from '../styles/ExchangeCoinInfo.style';
import CoinInfoCommunity from './CoinInfoCommunity';
import CoinInfoIntroduce from './CoinInfoIntroduce';
import CoinInfoNews from './CoinInfoNews';

function ExchangeCoinInfo() {
  const { t } = useTranslation();

  const tapList = [
    {
      id: 1,
      tapname: t('exchange40'),
    },
    {
      id: 2,
      tapname: t('exchange41'),
    },
    {
      id: 3,
      tapname: t('exchange42'),
    },
  ];
  const [tapState, setTapState] = useState(tapList[0]);

  return (
    <S.CoinInfoBox>
      <S.TabList tapState={tapState.id}>
        {tapList.map(item => (
          <button type="button" key={item.id} onClick={() => setTapState(item)}>
            {item.tapname}
          </button>
        ))}
      </S.TabList>
      {
        {
          Introduction: <CoinInfoIntroduce />,
          'Announcement & News': <CoinInfoNews />,
          Community: <CoinInfoCommunity />,
        }[tapState.tapname]
      }
    </S.CoinInfoBox>
  );
}

export default ExchangeCoinInfo;
