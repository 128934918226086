import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Header = styled.div`
  background-color: ${({ theme }) => theme.backgroundColors.blue2};
`;
const HeaderContent = styled.div`
  ${({ theme }) => theme.defaultLayout()};
  display: flex;
  a {
    display: block;
    color: white;
    width: 20%;
    text-align: center;
    padding: 12px 0;
  }
`;

function WalletHeader() {
  return (
    <Header>
      <HeaderContent>
        <Link to="/wallet/asset">Asset status</Link>
        <Link to="/wallet/deposit">deposit</Link>
        <Link to="/wallet/withdraw">withdraw</Link>
        <Link to="/wallet/record">record</Link>
        <Link to="/wallet/dwhistory">Deposit and withdrawal details</Link>
      </HeaderContent>
    </Header>
  );
}

export default WalletHeader;
