import React from 'react';
import HeaderLayout from '../layout/HeaderLayout';
import FooterLayout from '../layout/FooterLayout';
import SignUpContainer from '../container/SignUpContainer';

function SignUp() {
  return (
    <>
      <HeaderLayout />
      <SignUpContainer />
      <FooterLayout />
    </>
  );
}

export default SignUp;
