/* eslint-disable no-shadow */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MypageHeader from '../components/MypageHeader';
import Paging from '../components/Paging';
import { userLoginLog } from '../store/actions/user';
import { MypageSection } from '../styles/MypageSection';
import * as S from '../styles/SecurityContainer.style';
import Loading from '../components/Loading';

function SecurityContainer() {
  const [page, setPage] = useState(1);

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { userLoginLogDone, userLoginLogLoading } = useSelector(
    state => state.user,
  );

  const onSetPage = useCallback(
    page => {
      dispatch(userLoginLog(page));
      setPage(page);
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(userLoginLog(page));
  }, []);
  return (
    <>
      <MypageHeader />
      <MypageSection>
        <S.SecurityWrap>
          <p>{t('mypage18')}</p>
          <span>{t('mypage19')}</span>
          <table>
            <thead>
              <tr>
                <td>{t('mypage20')}</td>
                <td>IP</td>
                <td>{t('mypage21')}</td>
              </tr>
            </thead>
            <tbody>
              {userLoginLogLoading && (
                <tr>
                  <td colSpan="3">
                    <Loading width="3%" display="block" />
                  </td>
                </tr>
              )}
              {userLoginLogDone.info &&
                userLoginLogDone.info.map(item => (
                  <tr key={item.mll_id}>
                    <td>{item.mll_nation}</td>
                    <td>{item.mll_ip}</td>
                    <td>{item.mll_datetime}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          <button type="button" className="logout_btn">
            {t('mypage22')}
          </button>
          <Paging
            page={page}
            onSetPage={onSetPage}
            perCnt={userLoginLogDone?.perCnt}
            totalCnt={userLoginLogDone?.totalCnt}
          />
        </S.SecurityWrap>
      </MypageSection>
    </>
  );
}

export default SecurityContainer;
