import React from 'react';
import { useTranslation } from 'react-i18next';
import WalletHeader from '../components/WalletHeader';
import * as S from '../styles/AssetContainer.style';

function AssetContainer() {
  const { t } = useTranslation();
  return (
    <>
      <WalletHeader />
      <S.Wrap>
        <p className="title">{t('wallet1')}</p>
        <S.Chart>
          <div className="chart_text">
            <div>
              <span>{t('wallet2')} KLAY</span>
              <span>0 KLAY</span>
              <span>{t('wallet3')}</span>
              <span>100,000,000,000 KLAY</span>
            </div>
            <div>
              <span>{t('wallet4')}</span>
              <span>100,000,000,000 KLAY</span>
              <span>{t('wallet5')}</span>
              <span>100,000,000,000 KLAY</span>
            </div>
            <div>
              <span>{t('wallet6')}</span>
              <span>100,000,000,000KLAY</span>
              <span>{t('wallet7')}</span>
              <span>3,4522%</span>
            </div>
          </div>
        </S.Chart>
        <S.ContentHeader>
          <span>{t('wallet8')}</span>
          <span>{t('wallet9')}</span>
          <span>{t('wallet10')}</span>
          <span>{t('wallet11')}</span>
          <span>{t('wallet12')}</span>
          <span>{t('wallet13')}(%)</span>
        </S.ContentHeader>
        <S.Content>
          <ul>
            <li className="coin_name">
              <span>{t('wallet14')}</span>
              <br />
              <span>BTS/KLAY</span>
            </li>
            <li>10000000 BTS</li>
            <li>30 KLAY</li>
            <li>297,232,232 KLAY</li>
            <li>100,000,00 KLAY</li>
            <li>+2,324,234</li>
          </ul>
        </S.Content>
      </S.Wrap>
    </>
  );
}
export default AssetContainer;
