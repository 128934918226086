/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import { getCookie } from '../../utils/cookie';
import {
  certifyUserEmail,
  changeUserNickname,
  changeUserPassword,
  checkUserNickname,
  createUserOtp,
  login,
  loginOtp,
  logout,
  registUserOtp,
  reToken,
  signup,
  userInfo,
  userLoginLog,
} from '../actions/user';

export const initialState = {
  isLogin: false,
  me: null, // 내 정보
  reTokenState: '',
  userInfoState: '',
  pagenation: '',
  emailLoading: false, // 이메일 인증 요청중
  emailDone: false,
  emailError: null,
  nicknameLoading: false, // 닉네임 중복체크 요청중
  nicknameDone: false,
  nicknameError: null,
  changePasswordLoading: false, // 비밀번호 변경 요청중
  changePasswordDone: false,
  changePasswordError: null,
  changeNicknameLoading: false, // 닉네임 변경 요청중
  changeNicknameDone: false,
  changeNicknameError: false,
  userInfo: null, // 유저 정보
  loadMyInfoLoading: false, // 로그인 정보 조회
  loadMyInfoDone: false,
  loadMyInfoError: null,
  loadUserLoading: false, // 유저 정보 조회
  loadUserDone: false,
  loadUserError: null,
  loginLoading: false, // 로그인 시도중
  loginDone: false,
  loginError: null,
  logoutLoading: false, // 로그아웃 시도중
  logoutDone: false,
  logoutError: null,
  signupLoading: false, // 회원가입 시도중
  signupDone: false,
  signupError: null,
  userLoginLogLoading: false, // 접속로그 조회
  userLoginLogDone: false,
  userLoginLogError: null,
  createOtpLoading: false, // OTP 생성 요청중
  createOtpDone: false,
  createOtpError: null,
  registOtpLoading: false, // OTP 등록 요청중
  registOtpDone: false,
  registOtpError: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    isLoginCheck: (state, action) => {
      state.isLogin =
        action.payload === 1000 ? true : !!getCookie('refresh_token');
    },
    setUserInfoState: (state, action) => {
      state.userInfoState = action.payload;
    },
    resetMe: state => {
      state.me = null;
    },
  },
  extraReducers: builder =>
    builder
      // 이메일 인증 요청
      .addCase(certifyUserEmail.pending, state => {
        state.emailLoading = true;
        state.emailDone = false;
        state.emailError = null;
      })
      .addCase(certifyUserEmail.fulfilled, (state, action) => {
        state.emailLoading = false;
        state.emailDone = action.payload;
      })
      .addCase(certifyUserEmail.rejected, (state, action) => {
        state.emailLoading = false;
        state.emailError = action.payload;
      })
      // 닉네임 중복체크 요청
      .addCase(checkUserNickname.pending, state => {
        state.nicknameLoading = true;
        state.nicknameDone = false;
        state.nicknameError = null;
      })
      .addCase(checkUserNickname.fulfilled, (state, action) => {
        state.nicknameLoading = false;
        state.nicknameDone = action.payload;
      })
      .addCase(checkUserNickname.rejected, (state, action) => {
        state.nicknameLoading = false;
        state.nicknameError = action.payload;
      })
      // 닉네임 변경 요청
      .addCase(changeUserNickname.pending, state => {
        state.changeNicknameLoading = true;
        state.changeNicknameDone = false;
        state.changeNicknameError = null;
      })
      .addCase(changeUserNickname.fulfilled, (state, action) => {
        state.changeNicknameLoading = false;
        state.changeNicknameDone = action.payload;
      })
      .addCase(changeUserNickname.rejected, (state, action) => {
        state.changeNicknameLoading = false;
        state.changeNicknameError = action.payload;
      })
      // 비밀번호 변경 요청
      .addCase(changeUserPassword.pending, state => {
        state.changePasswordLoading = true;
        state.changePasswordDone = false;
        state.changePasswordError = null;
      })
      .addCase(changeUserPassword.fulfilled, (state, action) => {
        state.changePasswordLoading = false;
        state.changePasswordDone = action.payload;
      })
      .addCase(changeUserPassword.rejected, (state, action) => {
        state.changePasswordLoading = false;
        state.changePasswordError = action.payload;
      })
      // 로그인 요청
      .addCase(login.pending, state => {
        state.loginLoading = true;
        state.loginDone = false;
        state.loginError = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loginLoading = false;
        state.me = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.loginLoading = false;
        state.loginError = action.payload;
      })
      // 로그아웃 요청
      .addCase(logout.pending, state => {
        state.logoutLoading = true;
        state.logoutDone = false;
        state.logoutError = null;
      })
      .addCase(logout.fulfilled, state => {
        state.logoutLoading = false;
        state.me = null;
        state.isLogin = false;
      })
      .addCase(logout.rejected, (state, action) => {
        state.logoutLoading = false;
        state.logoutError = action.payload;
      })
      // 회원가입 요청
      .addCase(signup.pending, state => {
        state.signupLoading = true;
        state.signupDone = false;
        state.signupError = null;
      })
      .addCase(signup.fulfilled, (state, action) => {
        state.signupLoading = false;
        state.signupDone = action.payload;
      })
      .addCase(signup.rejected, (state, action) => {
        state.signupLoading = false;
        state.signupError = action.payload;
      })
      // 접속관리 로그 요청
      .addCase(userLoginLog.pending, state => {
        state.userLoginLogLoading = true;
        state.userLoginLogDone = false;
        state.userLoginLogError = null;
      })
      .addCase(userLoginLog.fulfilled, (state, action) => {
        state.userLoginLogLoading = false;
        state.userLoginLogDone = action.payload;
      })
      .addCase(userLoginLog.rejected, (state, action) => {
        state.userLoginLogLoading = false;
        state.userLoginLogError = action.payload;
      })
      // 유저정보 요청
      .addCase(userInfo.pending, state => {
        state.loadUserLoading = true;
        state.loadUserDone = false;
        state.loadUserError = null;
      })
      .addCase(userInfo.fulfilled, (state, action) => {
        state.me = action.payload.code !== 1001 ? action.payload : null;
      })
      .addCase(userInfo.rejected, (state, action) => {
        state.signupLoading = false;
        state.loadUserError = action.payload;
      })
      // OTP 생성 요청
      .addCase(createUserOtp.pending, state => {
        state.createOtpLoading = true;
        state.createOtpDone = false;
        state.createOtpError = null;
      })
      .addCase(createUserOtp.fulfilled, (state, action) => {
        state.createOtpLoading = false;
        state.createOtpDone = action.payload;
      })
      .addCase(createUserOtp.rejected, (state, action) => {
        state.createOtpLoading = false;
        state.createOtpError = action.payload;
      })
      // OTP 등록 요청
      .addCase(registUserOtp.pending, state => {
        state.registOtpLoading = true;
        state.registOtpDone = false;
        state.registOtpError = null;
      })
      .addCase(registUserOtp.fulfilled, (state, action) => {
        state.registOtpDone = action.payload;
      })
      .addCase(registUserOtp.rejected, (state, action) => {
        state.registOtpLoading = false;
        state.registOtpError = action.payload;
      })
      // OTP 인증 요청
      .addCase(loginOtp.pending, state => {
        state.createOtpLoading = true;
        state.createOtpDone = false;
        state.createOtpError = null;
      })
      .addCase(loginOtp.fulfilled, (state, action) => {
        state.createOtpLoading = false;
        state.me = action.payload;
        state.createOtpDone = action.payload;
      })
      .addCase(loginOtp.rejected, (state, action) => {
        state.createOtpLoading = false;
        state.createOtpError = action.payload;
      })
      // 토큰 재발행 요청
      .addCase(reToken.fulfilled, (state, action) => {
        state.reTokenState = action.payload;
      }),
});
export const { isLoginCheck, setUserInfoState, resetMe } = userSlice.actions;
export default userSlice.reducer;
