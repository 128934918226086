import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export const setCookie = (key, value) => {
  const today = new Date();
  const expireDate = today.setDate(today.getDate() + 7);

  return cookies.set(key, value, {
    sameSite: 'strict',
    path: '/',
    expires: new Date(expireDate),
  });
};

export const getCookie = key => {
  return cookies.get(key);
};

export const removeCookie = key => {
  return cookies.remove(key, { sameSite: 'strict', path: '/' });
};
