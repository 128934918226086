/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-alert */
/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import * as S from '../styles/CreateMyOpenApi.style';
import { createOpenApi } from '../store/actions/openApi';
import { logout } from '../store/actions/user';
import reg from '../utils/regexp';

const checkboxList = [
  {
    id: 'menu1',
    text: '자산조회',
    name: 'is_balance',
    checked: 0,
  },
  {
    id: 'menu2',
    text: '주문조회',
    name: 'is_order_info',
    checked: 0,
  },
  {
    id: 'menu3',
    text: '주문하기',
    name: 'is_order',
    checked: 0,
  },
  {
    id: 'menu4',
    text: '출금조회',
    name: 'is_withdraw_info',
    checked: 0,
  },
  {
    id: 'menu5',
    text: '출금하기',
    name: 'is_withdraw',
    checked: 0,
  },
  {
    id: 'menu6',
    text: '입금조회',
    name: 'is_deposit_info',
    checked: 0,
  },
];

function CreateMyOpenApi({ setMyOpenApiState }) {
  const [createStep, setCreateStep] = useState(0);
  const [apiName, setApiName] = useState('');
  const [registIp, setRegistIp] = useState('');
  const [checkboxDatas, setCheckboxDatas] = useState('');
  const [sliceCheckboxDatas, setSliceCheckboxDatas] = useState('');
  const [otpInput, setOtpInput] = useState('');
  const [action, setAction] = useState(false);

  const dispatch = useDispatch();
  const { registOpenApiDone } = useSelector(state => state.openApi);
  const { me } = useSelector(state => state.user);

  // 체크박스 선택 여부
  const onChangeChecked = e => {
    const newArr = checkboxDatas.map(item => {
      if (e.target.checked) {
        if (e.target.name === item.name) {
          return { ...item, checked: 1 };
        }
      } else if (e.target.name === item.name) {
        return { ...item, checked: 0 };
      }
      return item;
    });
    setCheckboxDatas(newArr);
  };

  // Open Api 필수값 검토
  const onClickNextStep = () => {
    // ip 정규식 체크
    let ipCheck = false;
    registIp.split(',').forEach(item => {
      if (!reg.ipAdress.test(item)) {
        ipCheck = false;
      } else {
        ipCheck = true;
      }
    });

    // 체크박스 필수 한개이상 체크
    let checkboxCheck = false;
    for (const data of Object.entries(sliceCheckboxDatas)) {
      if (data[1] === 1) {
        checkboxCheck = true;
        break;
      }
    }

    // 등록 버튼 클릭 시 체크
    if (apiName === '') {
      alert('Open API 이름을 입력하세요.');
    } else if (!checkboxCheck) {
      alert('허용범위는 한개이상 선택해주세요.');
    } else if (registIp === '') {
      alert('허용 IP주소를 입력해주세요.');
    } else if (!ipCheck) {
      alert('정확한 IP주소를 입력하세요.');
    } else if (registIp.split(',').length > 5) {
      alert('ip주소 등록은 최대 5개까지만 가능합니다.');
    } else {
      setCreateStep(1);
    }
  };

  // openApi 생성 요청
  const onClickCreateOpenApi = () => {
    dispatch(
      createOpenApi({
        api_name: apiName,
        api_permit_ip: registIp,
        ...sliceCheckboxDatas,
        otpcode: otpInput,
      }),
    );
    setAction(true);
  };

  // 체크박스 리스트 조회
  useEffect(() => {
    setCheckboxDatas(checkboxList);
  }, []);

  // 데이터 요청에 필요한 체크박스 리스트 분리
  useEffect(() => {
    const abc = {};
    checkboxDatas &&
      checkboxDatas.forEach(item => {
        abc[item.name] = item.checked;
      });
    setSliceCheckboxDatas(abc);
  }, [checkboxDatas]);

  // openApi 생성 요청 예외처리
  useEffect(() => {
    if (action) {
      if (registOpenApiDone) {
        if (registOpenApiDone.code === 1000) {
          setCreateStep(2);
        } else if (registOpenApiDone.code === 1008) {
          alert('로그인 세션이 만료되었습니다.');
          dispatch(logout(me?.mem_id));
        } else if (registOpenApiDone.code !== 1008) {
          alert('입력한 값을 확인해주세요.');
        }
        setAction(false);
      }
    }
  }, [registOpenApiDone]);

  return (
    <S.Wrap>
      {
        {
          0: (
            <>
              <p className="title">Open API 생성</p>
              <S.ApiSetting>
                <p>Open API 이름</p>
                <input
                  type="text"
                  maxLength="20"
                  onChange={e => setApiName(e.target.value)}
                />
              </S.ApiSetting>
              <S.ApiSetting2>
                <p>Open API 허용범위</p>
                <div className="checkboxs">
                  {checkboxDatas &&
                    checkboxDatas.map(item => (
                      <label htmlFor={item.id} key={item.id}>
                        <input
                          type="checkbox"
                          id={item.id}
                          name={item.name}
                          onChange={e => onChangeChecked(e)}
                        />
                        <span>{item.text}</span>
                      </label>
                    ))}
                </div>
              </S.ApiSetting2>
              <div className="require_write">
                <span>
                  ※ Open API를 사용하기 위해선 기능 선택과 사용하실 IP 주소를
                  반드시 입력해야 Open API Key 발급이 가능합니다.
                  <br /> ※ Open API Key 발급 당시 입력한 IP 주소로만 접속해야
                  Open API 사용이 가능하며, Key당 최대 5개까지 등록할 수
                  있습니다.
                  <br />※ Secret key는 최초 1회에 한해 발급되며 추가로 확인하실
                  수 없으니, 발급받으신 Secret key는 반드시 안전한 곳에 별도로
                  보관하여 주시기 바랍니다.
                  <br />※ Open API Key 토큰의 유효 기간은 1년이며 연장은
                  불가합니다.
                  <br />
                  ※ 유효 기간 만료 후 Open API Key를 삭제하여 추가로 발급받아
                  주시기 바랍니다.
                  <br />※ Open API Key 발급, 수정 및 삭제 시에는 추가 인증이
                  필요합니다.
                </span>
              </div>
              <S.ApiSetting3>
                <p>허용 IP주소 등록</p>
                <input
                  type="text"
                  placeholder='","로 구분하여 최대 5개의 IP 주소 등록가능'
                  onChange={e => setRegistIp(e.target.value)}
                />
              </S.ApiSetting3>
              <div className="regist">
                <button type="button" onClick={() => setMyOpenApiState('main')}>
                  이전
                </button>
                <button type="button" onClick={onClickNextStep}>
                  등록
                </button>
              </div>
            </>
          ),
          1: (
            <div className="otpModal">
              <p className="modal_title1">Open API 생성</p>
              <p className="modal_title2">OTP 인증번호 입력</p>
              <span>
                Google OTP / Google Authenticator 앱에 표시된
                <br />
                OTP인증번호 6자리를 시간내에 입력해주세요.
              </span>
              <input
                type="text"
                className="otp_input"
                onChange={e => setOtpInput(e.target.value)}
                maxLength="6"
              />
              <button
                type="button"
                className="btn"
                onClick={onClickCreateOpenApi}
              >
                완료
              </button>
            </div>
          ),
          2: (
            <div className="keyModal">
              <div className="key_box">
                <span>API Key</span>
                <button type="button">복사</button>
              </div>
              <input
                type="text"
                readOnly
                value={
                  registOpenApiDone.info
                    ? registOpenApiDone.info?.api_accesskey
                    : ''
                }
              />
              <div className="key_box">
                <span>Secret Key</span>
                <button type="button">복사</button>
              </div>
              <input
                type="text"
                readOnly
                value={
                  registOpenApiDone.info
                    ? registOpenApiDone.info?.api_secretkey
                    : ''
                }
              />
              <div className="require_write2">
                <span>
                  ※ Secret Key 분실 혹은 유출 시, 기존 Open API Key를 삭제 후
                  재생성 하여야 합니다.
                  <br />※ Open API Key를 절대 타인에게 양도하지 마시기 바랍니다.
                  <br /> ※ 그에 따라 발생하는 피해에 대한 책임은 본인에게
                  있습니다.
                  <br /> ※ Open API Key 미삭제로 발생하는 금전적 손실의 책임은
                  본인에게 있습니다.
                  <br />※ 회원 탈퇴시에는 Open API Key가 삭제됩니다.
                </span>
              </div>
              <button
                type="button"
                className="check_btn"
                onClick={() => setMyOpenApiState('main')}
              >
                확인
              </button>
            </div>
          ),
        }[createStep]
      }
    </S.Wrap>
  );
}

CreateMyOpenApi.propTypes = {
  setMyOpenApiState: PropTypes.func.isRequired,
};

export default CreateMyOpenApi;
