import styled, { css } from 'styled-components';

const GnbDefaultCss = css`
  display: flex;
  gap: 30px;
  align-items: center;
`;

export const Header = styled.div`
  max-width: ${({ theme }) => theme.layoutWidths.defaultWidth};
  margin: 0 auto;
  display: flex;
  height: 80px;
  justify-content: space-between;
`;
export const GnbLeft = styled.div`
  display: flex;

  img {
    width: 90px;
    height: 100%;
    object-fit: contain;
  }
`;
export const GnbLeftUl = styled.ul`
  ${GnbDefaultCss};
  margin-left: 50px;

  li {
    height: 100%;
    position: relative;
    & > a {
      display: block;
      line-height: 80px;
      height: 100%;
    }

    &:hover {
      .sub_menu {
        display: block;
      }
    }
    .sub_menu {
      display: none;
      width: 200px;
      position: absolute;
      background-color: ${({ theme }) => theme.backgroundColors.lightGray2};
      border-radius: 10px;

      a {
        display: block;
        padding: 20px;
        cursor: pointer;
        width: 100%;
        height: 100%;

        &:hover {
          background-color: ${({ theme }) => theme.backgroundColors.black1};
        }
      }
    }
  }
`;

export const GnbRight = styled.div`
  ${GnbDefaultCss};
  font-size: ${({ theme }) => theme.fontSizes.small};

  .mytitle {
    a {
      color: ${({ theme }) => theme.colors.orange1};
    }
  }

  .mypage {
    a {
      background-color: ${({ theme }) => theme.backgroundColors.blue2};
      padding: 5px 10px;
      border-radius: 5px;
      color: white;
    }
  }

  .logout {
    button {
      border: 0;
      background-color: transparent;
      background-color: ${({ theme }) => theme.backgroundColors.blue2};
      padding: 5px 10px;
      border-radius: 5px;
      color: white;
    }
  }
`;
