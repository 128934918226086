/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const SecurityWrap = styled.div`
  margin-top: 50px;

  p {
    font-size: ${({ theme }) => theme.fontSizes.titleL};
    font-weight: ${({ theme }) => theme.fontWeights.xl};
    color: ${({ theme }) => theme.colors.blue3};
    margin-bottom: 5px;
  }

  span {
    display: block;
    color: ${({ theme }) => theme.colors.blue3};
    padding-left: 5px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
  }

  table {
    width: 100%;
    margin-top: 20px;
    border-top: 1px solid #ccc;

    thead {
      background-color: #eee;
    }

    tr {
      border-bottom: 1px solid #ccc;
      height: 40px;
      line-height: 40px;
    }

    td {
      width: 20%;
      text-align: center;
    }

    .text_left {
      text-align: left;
      padding-left: 20px;
    }
  }

  .logout_btn {
    display: block;
    margin-top: 20px;
    margin-left: auto;
    width: 200px;
    border: 0;
    border-radius: 5px;
    height: 40px;
    color: white;
    background-color: ${({ theme }) => theme.backgroundColors.blue2};
  }
`;
