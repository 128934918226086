import styled from 'styled-components';

export const FooterLink = styled.div`
  background-color: ${({ theme }) => theme.backgroundColors.lightGray2};

  ul {
    ${({ theme }) => theme.defaultLayout};
    display: flex;

    li {
      a {
        display: block;
        padding: 20px;
        font-size: ${({ theme }) => theme.fontSizes.small};
      }
    }
  }
`;

export const FooterContent = styled.div`
  padding: 50px 0;
  background-color: #3e3e3e;

  & > div {
    ${({ theme }) => theme.defaultLayout};
    font-size: ${({ theme }) => theme.fontSizes.base};
    color: ${({ theme }) => theme.colors.gray3};

    .img_wrap {
      display: flex;
      width: 170px;
      height: 45px;
      justify-content: center;
      align-items: center;

      img {
        width: 140px;
        height: 35px;
      }
    }

    .copy_text {
      padding: 20px;

      span {
        display: inline-block;
        padding-top: 10px;
      }

      p {
        padding-top: 30px;
        color: #cbcbcb;
      }

      .last_text {
        padding-top: 50px;
      }
    }
  }
`;
