import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Board from '../pages/Board';
import Exchange from '../pages/Exchange';
import Login from '../pages/Login';
import Main from '../pages/Main';
import Mypage from '../pages/Mypage';
import NotFound from '../pages/NotFound';
import Notice from '../pages/Notice';
import SignUp from '../pages/SignUp';
import Staking from '../pages/Staking';
import Wallet from '../pages/Wallet';
import Wbd from '../pages/Wbd';

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/notice" element={<Notice />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/exchange/:coinname" element={<Exchange />} />
        <Route path="/mypage/*" element={<Mypage />} />
        <Route path="/board" element={<Board />} />
        <Route path="/wallet/*" element={<Wallet />} />
        <Route path="/staking" element={<Staking />} />
        <Route path="/wbd" element={<Wbd />} />
        <Route path="/*" element={<NotFound />} />
        <Route path="/notfound" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
