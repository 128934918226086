import React from 'react';
import * as S from '../styles/StakingContainer.style';
import SubHeader from '../components/SubHeader';

function WbdContainer() {
  return (
    <>
      <SubHeader>WBD</SubHeader>
      <S.Wrap>
        <img src="/images/wbd_guide_1.png" alt="" />
        <img src="/images/wbd_guide_2.png" alt="" />
      </S.Wrap>
    </>
  );
}

export default WbdContainer;
