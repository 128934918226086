import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from '../styles/CoinInfoNews.style';

function CoinInfoNews() {
  const { t } = useTranslation();
  return (
    <>
      <S.ContentHeader>
        <span>{t('board3')}</span>
        <span>{t('board7')}</span>
        <span>{t('exchange5')}</span>
      </S.ContentHeader>
      <S.Content>
        <ul>
          <li className="text_left">[공시]공시내용 1234</li>
          <li>15:50</li>
          <li>1234</li>
        </ul>
      </S.Content>
    </>
  );
}

export default CoinInfoNews;
