/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  addOrder,
  cryptocurrency,
  getUserInfo,
} from '../store/actions/exchange';
import * as S from '../styles/ExchangeTrade.style';
import reg from '../utils/regexp';

const percentList = [
  {
    id: 1,
    percent: '10%',
  },
  {
    id: 2,
    percent: '25%',
  },
  {
    id: 3,
    percent: '50%',
  },
  {
    id: 4,
    percent: '100%',
  },
];

function ExchangeTrade({ coin }) {
  const { t } = useTranslation();

  const [tradeState, setTradeState] = useState(t('exchange12'));
  const [percent, setPercent] = useState(percentList[0]);
  const [orderPrice, setOrderPrice] = useState('');
  const [maxCount, setMaxCount] = useState('');
  const [orderCount, setOrderCount] = useState('');
  const [total, setTotal] = useState(0);

  const dispatch = useDispatch();
  const { currentCoin, userInfoDone, tradeInfoDone } = useSelector(
    state => state.exchange,
  );
  const { me, isLogin } = useSelector(state => state.user);

  // 가격 변경
  const onChangePrice = val => {
    if (!isLogin) {
      alert('로그인 후 이용 가능합니다.');
      return;
    }
    const v = val.replace(reg.number, '');
    setOrderPrice(v);
    setTotal(v * orderCount);

    // 최대 매수 가능 수량
    // ex) 보유 WAT / (수량 * 1 + 수수료 * 0.01)
    const max =
      userInfoDone &&
      userInfoDone?.info[0]?.bal_available /
        (val *
          (1 +
            (tradeInfoDone &&
              tradeInfoDone?.info?.ccs_commission_rate * 0.01)));
    setMaxCount(max);
  };

  // 수량 변경
  const onChangeCount = val => {
    if (!isLogin) {
      alert('로그인 후 이용 가능합니다.');
      return;
    }
    const v = val.replace(reg.number, '');
    setOrderCount(v);
    setTotal(orderPrice * v);
  };

  // 퍼센트 변경
  const onClickPercent = item => {
    setPercent(item);
    setOrderCount(
      userInfoDone?.info[tradeState === t('exchange12') ? 0 : 1]
        ?.bal_available *
        (item.percent.split('%')[0] / 100),
    );
  };

  // 주문
  const onClickOrder = () => {
    const type = tradeState === t('exchange12') ? 'buy' : 'sell';

    if (
      orderPrice === '' ||
      orderCount === '' ||
      orderPrice === 0 ||
      orderCount === 0
    ) {
      alert(t('exchange14'));
      return;
    }

    if (
      orderPrice.toString().split('.').length > 2 ||
      orderCount.toString().split('.').length > 2
    ) {
      alert(t('exchange15'));
      return;
    }

    switch (tradeState) {
      case t('exchange12'):
        if (total > Number(userInfoDone?.info[0]?.bal_available).toFixed(2)) {
          alert(t('exchange16'));
          return;
        }
        break;
      case t('exchange13'):
        if (total > Number(userInfoDone?.info[1]?.bal_available).toFixed(2)) {
          // alert(`보유 ${coin.toUpperCase()}를 확인해주세요.`);
          alert(t('exchange17'));
          return;
        }
        break;

      default:
    }

    // 가격, 수량 소수점 변환
    const priceDecimalCount = Number(
      `1${'0'.repeat(tradeInfoDone?.info?.ccs_price_decimal)}`,
    );
    const countDecimalCount = Number(
      `1${'0'.repeat(tradeInfoDone?.info?.ccs_amount_decimal)}`,
    );

    const priceDecimal =
      Math.floor(orderPrice * priceDecimalCount) / priceDecimalCount;
    const countDecimal =
      Math.floor(orderCount * countDecimalCount) / countDecimalCount;

    setOrderPrice(priceDecimal);
    setOrderCount(countDecimal);

    // if (me?.info?.mem_is_otp === 1) {
    setTimeout(() => {
      if (
        window.confirm(
          `${tradeState} ${t(
            'exchange18',
          )} : ${priceDecimal} \n${tradeState} ${t(
            'exchange19',
          )} : ${countDecimal} \n${t('exchange20')} `,
        )
      ) {
        // 주문 요청
        dispatch(
          addOrder({
            type,
            price: orderPrice,
            qtt: orderCount,
            coin: currentCoin.type,
            market: currentCoin.type2,
          }),
        );
      }
    }, 100);
    // } else {
    //   alert('otp 인증후 거래 가능합니다.');
    // }
  };

  // 거래소 유저 정보 요청
  useEffect(() => {
    dispatch(getUserInfo(currentCoin?.coin_name));
    dispatch(cryptocurrency(currentCoin?.coin_name));
  }, [currentCoin]);

  // total 값 변경
  useEffect(() => {
    setTotal(orderPrice * orderCount);
  }, [orderPrice, orderCount]);

  // 탭 변경시 값 초기화
  useEffect(() => {
    setOrderPrice('');
    setOrderCount('');
    setTotal('');
    setMaxCount('');
  }, [tradeState]);

  return (
    <S.TradeBox>
      <S.TabList tradeState={tradeState}>
        <button type="button" onClick={() => setTradeState(t('exchange12'))}>
          {t('exchange12')}
        </button>
        <button type="button" onClick={() => setTradeState(t('exchange13'))}>
          {t('exchange13')}
        </button>
      </S.TabList>
      <S.OrderForm percent={percent.id}>
        <div className="content">
          <span>{t('exchange21')}</span>
          <span>
            {userInfoDone?.info
              ? reg.comma(
                  Number(
                    userInfoDone?.info[tradeState === t('exchange12') ? 0 : 1]
                      ?.bal_available,
                  )
                    .toFixed(2)
                    .toString(),
                )
              : 0}{' '}
            {tradeState === t('exchange12') ? 'WAT' : coin.toUpperCase()}
          </span>
        </div>
        <div className="content">
          <span>{t('exchange22')}</span>
          <div className="input_wrap">
            <input
              type="text"
              className="order_price"
              onChange={e => onChangePrice(e.target.value)}
              value={orderPrice}
            />
          </div>
        </div>
        <span className="max_count">
          {t('exchange23')} {tradeState} {t('exchange24')} :{' '}
          {tradeState === t('exchange12')
            ? reg.comma(Number(maxCount).toFixed(2))
            : userInfoDone?.info &&
              reg.comma(
                Number(userInfoDone?.info[1]?.bal_available)
                  .toFixed(2)
                  .toString(),
              )}{' '}
          {coin.toUpperCase()}
        </span>
        <div className="content">
          <span>{t('exchange25')}</span>
          <div className="input_wrap">
            <input
              type="text"
              className="order_count"
              onChange={e => onChangeCount(e.target.value)}
              value={orderCount}
            />
            <span> {coin.toUpperCase()}</span>
          </div>
        </div>
        <div className="content">
          <span />
          <div className="percent">
            {percentList.map(item => (
              <button
                type="button"
                onClick={() => onClickPercent(item)}
                key={item.id}
              >
                {item.percent}
              </button>
            ))}
          </div>
        </div>
      </S.OrderForm>
      <S.SubmitInfo tradeState={tradeState}>
        <div className="info">
          <span>{t('exchange19')}</span>
          <span>
            {total && reg.comma(total.toFixed(2).toString())}{' '}
            {tradeState === t('exchange12') ? 'WAT' : coin.toUpperCase()}
          </span>
        </div>
        <div className="info">
          <span>{t('exchange26')}</span>
          <span>
            {tradeInfoDone &&
              reg.comma(
                (total * tradeInfoDone?.info?.ccs_commission_rate * 0.01)
                  .toFixed(2)
                  .toString(),
              )}
            {tradeState === t('exchange12') ? 'WAT' : coin.toUpperCase()}
          </span>
        </div>
        <span className="guide_text">
          {t('exchange27')}: 5 WAT {t('exchange26')}({t('exchange28')}):{' '}
          {tradeInfoDone && tradeInfoDone?.info?.ccs_commission_rate}%
        </span>
        <button type="button" className="submit" onClick={onClickOrder}>
          {tradeState === t('exchange12') ? t('exchange12') : t('exchange13')}
        </button>
      </S.SubmitInfo>
    </S.TradeBox>
  );
}

ExchangeTrade.propTypes = {
  coin: PropTypes.node.isRequired,
};

export default ExchangeTrade;
