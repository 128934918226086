import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as S from '../styles/MiniChart.style';

// 메인페이지 차트 출력 컴포넌트
function MiniChart({ title, data }) {
  return (
    <S.ChartBox title={title}>
      <p>{title}</p>
      <S.Charts>
        {data &&
          data.map(item => (
            <li key={item.ccs_id}>
              <Link to="/">
                <S.ChartContent img={item.ccs_coin_img}>
                  <span>{item.ccs_coin_name_eng}</span>
                  <span>
                    {item.type.toUpperCase()} / {item.type2.toUpperCase()}
                  </span>
                  <p>{item.last_price + item.type2.toUpperCase()}</p>
                  <span>{item.prcent}%</span>
                </S.ChartContent>
              </Link>
            </li>
          ))}
      </S.Charts>
    </S.ChartBox>
  );
}

MiniChart.propTypes = {
  title: PropTypes.node.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default MiniChart;
