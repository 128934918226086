import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import * as S from '../styles/InfoMemberOutModal.style';

function InfoMemberOutModal({ setModal }) {
  const [memberOutStep, setMemberOutStep] = useState(0);

  return (
    <S.MemberOutWrap>
      {
        {
          0: (
            <>
              <p>회원탈퇴</p>
              <h4>회원탈퇴 후 현재 이메일로 다시 회원가입 할 수 없습니다.</h4>
              <span>작성하신 게시글과 댓글도 수정 및 삭제할 수 없습니다.</span>
              <br />
              <br />

              <h4>회원탈퇴 불가능 사유</h4>
              <span>
                보유자산이 남아있다면 출금 후 회원탈퇴를 진행해 주세요. <br />
                탈퇴 시 보유하신 잔고 정보가 삭제되어 복구가 불가능합니다.{' '}
                <br />
                아래 사항중 하나 이상 해당 할 경우 회원 탈퇴가 불가합니다.{' '}
                <br />
                아래 사항 중 하나 이상 해당 할 경우 회원 탈퇴가 불가합니다.{' '}
                <br />
                * 거래가 진행 중인 경우 <br />
                * 자산 입출금이 진행 중인 경우 <br />
                * 잔ㅇ여 보유자산이 10,000원 이상인 경우 <br />
                * Wadex 상품 서비스를 이용 중인 경우 <br />
              </span>
              <br />

              <h4>소멸 및 서비스 불가</h4>
              <span>
                * 탈퇴한 계정으로 오입금되는 가상 자산은 회수 불가합니다. <br />
                * 이용 중인 유/무료 서비스는 자동 소멸됩니다.
              </span>
              <br />
              <br />

              <h4>소액 자산 처리</h4>
              <span>
                * 10,000원 미만의 소액 자산은 권리 포기합니다. <br />* 출금
                가능한 자산인지 탈퇴 전 반드시 확인해 주세요.
              </span>
              <br />
              <br />

              <h5>위 내용을 확인했고, 동의합니다.</h5>

              <div className="modal_btns">
                <button type="button" onClick={() => setModal('none')}>
                  취소
                </button>
                <button type="button" onClick={() => setMemberOutStep(1)}>
                  다음
                </button>
              </div>
            </>
          ),
          1: (
            <>
              <p>총 보유자산 확인</p>
              <span>
                보유자산이 남아있는 상태로 탈퇴를 진행하면 모든 잔고가 함께
                삭제됩니다.
                <br />
                잔고를 확인하고 출금 후 회원탈퇴를 진행해주세요.
              </span>
              <div className="total_money">
                <h4>회원님의 총 보유자산</h4>
              </div>
              <div className="modal_btns">
                <button type="button" onClick={() => setMemberOutStep(0)}>
                  뒤로
                </button>
                <button type="button" onClick={() => setMemberOutStep(2)}>
                  다음
                </button>
              </div>
            </>
          ),
          2: (
            <>
              <div className="wadex_img">
                <img src="/images/WADEX_C.png" alt="wadex_logo" />
              </div>
              <div className="pwChange_input">
                <p>회원탈퇴</p>
                <span>회원가입시 이용한 이메일을 입력해주세요.</span>
                <br />
                <span>본인확인 인증번호를 전송합니다.</span>
                <br />
                <input type="text" placeholder="E-mail 주소" />
              </div>
              <div className="modal_btns">
                <button type="button" onClick={() => setModal('none')}>
                  취소
                </button>
                <button type="button" onClick={() => setMemberOutStep(3)}>
                  전송
                </button>
              </div>
            </>
          ),
          3: (
            <>
              <div className="wadex_img">
                <img src="/images/WADEX_C.png" alt="wadex_logo" />
              </div>
              <div className="pwChange_input">
                <p>인증번호 입력</p>
                <span>이메일로 전송한 인증번호 6자리를 정확히 입력 후,</span>
                <br />
                <span>확인버튼을 눌러주세요</span>
                <br />
                <input type="text" placeholder="인증번호 6자리" />
              </div>
              <div className="modal_btns">
                <button type="button" onClick={() => setModal('none')}>
                  취소
                </button>
                <button type="button" onClick={() => setMemberOutStep(4)}>
                  전송
                </button>
              </div>
            </>
          ),
          4: (
            <>
              <div className="wadex_img">
                <img src="/images/WADEX_C.png" alt="wadex_logo" />
              </div>
              <p className="memberout_title">회원을탈퇴 하시겠습니까?</p>
              <span className="memberout_text">
                확인을 클릭하시면
                <br /> 모든 정보가 사라지고
                <br /> 탈퇴가 완료됩니다.
              </span>
              <button
                type="button"
                className="check_btn"
                onClick={() => setMemberOutStep(5)}
              >
                확인
              </button>
            </>
          ),
          5: (
            <>
              <div className="wadex_img">
                <img src="/images/WADEX_C.png" alt="wadex_logo" />
              </div>
              <p className="memberout_title">회원을탈퇴 하시겠습니까?</p>
              <span className="memberout_text">
                확인을 클릭하시면
                <br /> 모든 정보가 사라지고
                <br /> 탈퇴가 완료됩니다.
              </span>
              <button
                type="button"
                className="check_btn"
                onClick={() => setMemberOutStep(5)}
              >
                확인
              </button>
            </>
          ),
        }[memberOutStep]
      }
    </S.MemberOutWrap>
  );
}

InfoMemberOutModal.propTypes = {
  setModal: PropTypes.func.isRequired,
};

export default InfoMemberOutModal;
