/* eslint-disable no-shadow */
import styled, { css } from 'styled-components';

const HitMenu = idx => {
  return css`
    &:nth-of-type(${idx}) button {
      color: ${({ theme }) => theme.colors.orange1};
      position: relative;

      &::after {
        width: 100%;
        background-color: #ff8435;
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 3px;
        transition: all 0.3s ease;
      }
    }
  `;
};

const CommonMarketList = css`
  ul {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;

    li {
      flex: 1 1 0;
      text-align: center;
      padding: 20px;
    }
  }
`;

export const MarketListWrap = styled.div`
  ${({ theme }) => theme.defaultLayout};
  margin: 0 auto;
`;

export const MarketListTap = styled.div`
  display: flex;
  height: 56px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
`;

export const MarketTapLeft = styled.div`
  li {
    display: inline-block;

    ${HitMenu(({ curIdx }) => curIdx + 1)}

    button {
      font-size: ${({ theme }) => theme.fontSizes.base};
      font-weight: ${({ theme }) => theme.fontWeights.base};
      color: ${({ theme }) => theme.colors.black3};
      padding: 20px 30px;
      border: 0;
      background-color: transparent;
    }
  }
`;

export const MarketListTitle = styled.div`
  ${CommonMarketList}

  ul {
    height: 42px;
    background-color: ${({ theme }) => theme.backgroundColors.lightGray3};
    color: ${({ theme }) => theme.colors.gray1};
    font-size: ${({ theme }) => theme.fontSizes.small};

    .ico_arrow {
      height: 12px;
      margin-left: 5px;
      vertical-align: middle;
      cursor: pointer;
    }
  }
`;

export const MarketListView = styled.div`
  ${CommonMarketList}

  ul {
    height: 73px;
    font-size: ${({ theme }) => theme.fontSizes.small};
    li:nth-of-type(1) {
      text-align: left;
      display: flex;

      p {
        span:nth-of-type(1) {
          display: block;
          margin-bottom: 5px;
          font-weight: ${({ theme }) => theme.fontWeights.base};
          font-size: ${({ theme }) => theme.fontSizes.base};
        }
        span:nth-of-type(2) {
          display: block;
          font-size: ${({ theme }) => theme.fontSizes.xs};
          color: ${({ theme }) => theme.colors.gray1};
        }
      }
    }

    .percent {
      color: ${({ theme }) => theme.colors.pink1};
    }
  }
`;
