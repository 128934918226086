/* eslint-disable import/prefer-default-export */
/* eslint-disable no-undef */

import { SOCKET_URL } from '../config/config';

// /* eslint-disable func-names */
// export const dataFeedUri = DATA_FEED_URL;
const socket = io(SOCKET_URL);
// socket.connect();

const node = {};

node.get_coin_price_info_main = function (callback) {
  socket.emit('get_coin_price_info_main', function (data) {
    callback(data);
  });
  socket.on('get_coin_price_info_main', function (data) {
    callback(data);
  });
};
// node.get_txhash = function (coinType, txhash, userIdx, callback) {
//   socket.emit(`get_txhash_${coinType}`, txhash, userIdx, callback);
// };

// node.price_update = function (callback) {
//   socket.on('price_update', function (data) {
//     callback(data);
//   });
// };

// node.get_real_coin_info = function (coinName, market, callback1, callback2) {
//   socket.on('connectionOk', function () {
//     socket.emit('joinRoom', coinName, market);
//   });
//   socket.on('hoga', function (data) {
//     callback1(data);
//   });
//   socket.on('his', function (data) {
//     callback2(data);
//   });
// };

// node.eth_wallet_check = function (address, callback) {
//   socket.emit('eth_wallet_check', address, function (data) {
//     callback(data);
//   });
// };

// node.btc_wallet_check = function (address, callback) {
//   socket.emit('btc_wallet_check', address, function (data) {
//     callback(data);
//   });
// };

// node.bch_wallet_check = function (address, callback) {
//   socket.emit('bch_wallet_check', address, function (data) {
//     callback(data);
//   });
// };

// node.ltc_wallet_check = function (address, callback) {
//   socket.emit('ltc_wallet_check', address, function (data) {
//     callback(data);
//   });
// };

// node.user_login = function (userIdx, coinType, marketType) {
//   console.log(marketType);

//   socket.emit('user_login', userIdx, coinType, marketType);
// };

// node.order_updata = function (callback) {
//   socket.on('order_updata', callback);
// };

// node.order_out_updata = function (callback) {
//   socket.on('order_out_updata', function (data) {
//     callback(data);
//   });
// };

// node.data_updata = function (coinType, market, userIdx) {
//   socket.emit('data_update', coinType, market, userIdx);
// };

// node.get_coin_price_info = function (callback) {
//   socket.emit('get_coin_price_info', function (data) {
//     callback(data);
//   });
//   // socket.on('get_coin_price_info', function (data) {
//   //   callback(data);
//   // });
// };

// node.get_coin_price_info_one = function (callback) {
//   socket.on('get_coin_price_info_one', function (data) {
//     callback(data);
//   });
// };

// node.order_cancel = function (coinType, odIdx) {
//   socket.emit('order_cancel', coinType, odIdx);
// };

// node.new_coin = function (emitData, callback) {
//   if (typeof emitData === 'undefined') alert('비정상적인 접근입니다.');

//   socket.emit('new_coin', emitData, function (data) {
//     callback(data);
//   });
// };

// node.get_coin_info = function (emitData, callback) {
//   if (typeof emitData === 'undefined') alert('비정상적인 접근입니다.');

//   socket.emit('get_coin_info', emitData, function (data) {
//     callback(data);
//   });
// };

// // socket.on('wallet_send', function(app_id,private,callback) {
// node.coin_send = function (appId, private_, callback) {
//   socket.emit('wallet_send', appId, private_, callback);
// };

export default node;
