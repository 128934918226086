import React from 'react';
import WalletHeader from '../components/WalletHeader';

function RecordContainer() {
  return (
    <>
      <WalletHeader />
      <div>recore</div>
    </>
  );
}
export default RecordContainer;
