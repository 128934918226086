import React from 'react';
import WbdContainer from '../container/WbdContainer';
import FooterLayout from '../layout/FooterLayout';
import HeaderLayout from '../layout/HeaderLayout';

function Wbd() {
  return (
    <>
      <HeaderLayout />
      <WbdContainer />
      <FooterLayout />
    </>
  );
}

export default Wbd;
