import styled from 'styled-components';

export const ChartBox = styled.div`
  ${({ theme }) => theme.defaultLayout};
  padding: 30px 0;

  & > p {
    font-size: ${({ theme }) => theme.fontSizes.xxxl};
    font-weight: ${({ theme }) => theme.fontWeights.lg};
    margin-bottom: 20px;
    color: ${props =>
      props.title === 'Hot Artist'
        ? props.theme.colors.pink1
        : props.theme.colors.blue1};
  }
`;

export const Charts = styled.ul`
  display: flex;
  justify-content: space-between;

  li {
    width: 18%;
    height: 95px;
    background-color: white;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  }
`;

export const ChartContent = styled.div`
  background-image: url(/images/${({ img }) => img});
  background-position: 95%;
  background-repeat: no-repeat;
  background-size: auto 70%;
  height: 95px;
  padding: 13px 20px;
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);

  span:nth-of-type(1) {
    font-size: ${({ theme }) => theme.fontSizes.small};
    font-weight: ${({ theme }) => theme.fontWeights.lg};
    color: ${({ theme }) => theme.colors.black3};
    padding-right: 5px;
  }
  span:nth-of-type(2) {
    font-size: ${({ theme }) => theme.fontSizes.xs};
    color: ${({ theme }) => theme.colors.gray4};
  }

  p {
    color: ${({ theme }) => theme.colors.pink1};
    font-size: ${({ theme }) => theme.fontSizes.xxl};
    font-weight: ${({ theme }) => theme.fontWeights.xl};
    margin-top: 8px;
    margin-bottom: 8px;
  }

  span:nth-of-type(3) {
    font-size: ${({ theme }) => theme.fontSizes.xs};
    color: ${({ theme }) => theme.colors.white1};
    background-color: ${({ theme }) => theme.backgroundColors.pink1};
    border-radius: 6px;
    padding: 2px;
  }
`;
