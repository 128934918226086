import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as S from '../styles/MypageHeader.style';

function MypageHeader() {
  const { t } = useTranslation();
  return (
    <S.HeaderWrap>
      <S.Header>
        <Link to="/mypage/info">{t('mypage40')}</Link>
        <Link to="/mypage/confirm">{t('mypage41')}</Link>
        <Link to="/mypage/security">{t('mypage42')}</Link>
        <Link to="/mypage/openapi">Open API</Link>
      </S.Header>
    </S.HeaderWrap>
  );
}

export default MypageHeader;
