/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';

const layoutTitle = css`
  width: 200px;
  color: ${({ theme }) => theme.colors.blue3};
  font-size: ${({ theme }) => theme.fontSizes.xxxl};
  font-weight: ${({ theme }) => theme.fontWeights.lg};
  padding-bottom: 10px;
`;

const layoutDiv = css`
  display: flex;
  margin-top: 80px;
  border-bottom: 1px solid #777;
  padding-bottom: 10px;
`;

const layoutInfo = css`
  display: flex;
  align-items: center;
  margin-left: 50px;
  padding-bottom: 10px;
  border-bottom: 1px solid #777;
`;
const layoutInfoText = css`
  width: 150px;
  font-size: ${({ theme }) => theme.fontSizes.lg};
  font-weight: ${({ theme }) => theme.fontWeights.lg};
`;

export const MemberInfo = styled.div`
  p {
    ${layoutTitle}
    width:100%;
    border-bottom: 1px solid #777;
  }

  .email_info {
    ${layoutInfo}
    margin-top: 40px;
    h4 {
      ${layoutInfoText}
    }
  }

  .nickname_wrap {
    ${layoutInfo}
    margin-top: 20px;

    h4 {
      ${layoutInfoText}
    }

    input {
      border-radius: 10px;
      padding-left: 8px;
      height: 30px;
      margin-right: 5px;
    }

    button {
      border-radius: 5px;
      height: 30px;
      padding: 0 10px;
    }
  }
`;

export const PasswordChange = styled.div`
  ${layoutDiv}
  p {
    ${layoutTitle}
  }
`;

export const MemberOut = styled.div`
  ${layoutDiv}
  p {
    ${layoutTitle}
  }
`;

export const WarningText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.small} !important;
  color: ${({ theme }) => theme.colors.red1} !important;
  border: 0 !important;
  padding: 0 !important;
  margin-left: 50px;
  margin-top: 10px;
`;
