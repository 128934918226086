/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const HeaderBox = styled.div`
  ${({ theme }) => theme.defaultLayout()}
  border-bottom: 1px solid #ccc;
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.black3};
  padding: 15px 0;

  .header_info {
    display: flex;

    li {
      margin-right: 50px;

      .title {
        font-size: ${({ theme }) => theme.fontSizes.base};
        font-weight: ${({ theme }) => theme.fontWeights.xl};
      }

      button {
        border: 0;
        padding: 0;
        background-color: transparent;
        margin-top: 5px;
      }

      p:nth-of-type(2) {
        color: ${({ theme }) => theme.colors.black1};
        font-weight: ${({ theme }) => theme.fontWeights.xxl};
        margin-top: 10px;
      }

      .font_red {
        color: ${({ theme }) => theme.colors.pink1} !important;
      }
    }
  }
`;
