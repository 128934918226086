import React from 'react';
import LoginContainer from '../container/LoginContainer';
import FooterLayout from '../layout/FooterLayout';
import HeaderLayout from '../layout/HeaderLayout';

function Login() {
  return (
    <>
      <HeaderLayout />
      <LoginContainer />
      <FooterLayout />
    </>
  );
}
export default Login;
