import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const GlobalStyle = createGlobalStyle`
    ${reset};

    *{
        box-sizing:border-box;
    }
    body {
        margin:0;
        font-family:'Noto Sans KR', sans-serif;
    }
    a {
        text-decoration:none;
        color:black;
    }
    label {
        cursor:pointer;
    }
    input[type='text'],
    input[type='password'] {
        padding-left:10px;
    }
    input[type='text']::placeholder  {
        color:#949494;
    }
    button {
        font-family:'Noto Sans KR', sans-serif;
        cursor:pointer;
    }
`;

export default GlobalStyle;
