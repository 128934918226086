import styled from 'styled-components';

export const MyOpenApiWrap = styled.div`
  padding: 60px;

  .create_api_wrap {
    display: flex;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 60px;

    button {
      width: 120px;
      height: 30px;
      border: 0;
      margin-left: 30px;
    }
  }

  .title {
    font-size: ${({ theme }) => theme.fontSizes.xxxl};
    color: ${({ theme }) => theme.colors.blue3};
  }

  .my_apikey_title {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
  }
`;

export const MyOpenApi = styled.div`
  margin-top: 20px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    span {
      font-size: ${({ theme }) => theme.fontSizes.xxl};
      color: ${({ theme }) => theme.colors.blue3};
    }

    button {
      color: white;
      border-radius: 5px;
      padding: 5px 10px;
      background-color: ${({ theme }) => theme.backgroundColors.blue2};
    }
  }

  .info {
    display: flex;
    height: 100px;

    .key_wrap {
      border: 1px solid #ccc;
      flex: 4;

      h4 {
        margin-left: 8px;
        padding: 10px 0;
        border-bottom: 1px solid #ccc;
        font-size: ${({ theme }) => theme.fontSizes.xl};
        color: ${({ theme }) => theme.colors.gray2};
      }

      span {
        display: inline-block;
        margin-left: 8px;
        padding-top: 10px;
      }
    }
    .checkboxs {
      border: 1px solid #ccc;
      flex: 6;
      padding: 15px;

      label {
        display: inline-block;
        margin-right: 20px;

        input {
          vertical-align: -2px;
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .ip_info {
    margin-top: 10px;

    & > span {
      color: ${({ theme }) => theme.colors.gray3};
    }

    button {
      padding: 5px 10px;
      border: 0;
      border-radius: 5px;
      margin-left: 10px;
    }

    .ips {
      margin-top: 5px;
      span {
        display: inline-block;
        width: 500px;
        padding: 5px 10px;
        border: 1px solid #ccc;
      }
    }
  }
`;
