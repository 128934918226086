/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as S from '../styles/ExchangeOrderBook.style';
import reg from '../utils/regexp';

function ExchangeOrderBook() {
  const { orderBook } = useSelector(state => state.exchange);

  const { t } = useTranslation();

  return (
    <S.OrderBookBox>
      <div className="tab">
        <button type="button">{t('exchange29')}</button>
      </div>
      <div className="list_wrap">
        <div className="list_header">
          <span>{t('exchange30')}(TRA)</span>
          <span>{t('exchange31')}</span>
        </div>
        <div className="list">
          {orderBook &&
            orderBook.sell.map((item, idx) => (
              <ul className="sell_box" key={idx}>
                <li className="price">
                  {reg.comma(item.hoga_price.toFixed(2))}
                </li>
                {/* <li className="change_percent">+0.08%</li> */}
                <li className="count">{reg.comma(item.quantity.toFixed(2))}</li>
              </ul>
            ))}
          {orderBook &&
            orderBook.buy.map((item, idx) => (
              <ul className="buy_box" key={idx}>
                <li className="price">
                  {reg.comma(item.hoga_price.toFixed(2))}
                </li>
                {/* <li className="change_percent">+0.08%</li> */}
                <li className="count">{reg.comma(item.quantity.toFixed(2))}</li>
              </ul>
            ))}
        </div>
      </div>
    </S.OrderBookBox>
  );
}

export default ExchangeOrderBook;
