/* eslint-disable no-useless-escape */
/* eslint-disable no-restricted-globals */
/* eslint-disable import/prefer-default-export */
// import node from './socket';

export function getParameterByName(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(location.search);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

// 오더북, 코인리스트, 거래기록
// const datas = {};

// node.get_real_coin_info(
//   'bts',
//   'tra',
//   function (data) {
//     datas.orderBook = data;
//     node.get_coin_price_info(function (data2) {
//       datas.coinList = data2;
//     });
//   },
//   function (data) {
//     datas.transactionLog = data;
//   },
// );
// export default datas;
