import styled from 'styled-components';

export const HeaderWrap = styled.section`
  background-color: ${({ theme }) => theme.backgroundColors.blue2};
`;
export const Header = styled.div`
  max-width: ${({ theme }) => theme.layoutWidths.defaultWidth};
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 50px;
  line-height: 50px;
  a {
    width: 25%;
    height: 100%;
    text-align: center;
    color: white;
    font-size: ${({ theme }) => theme.fontSizes.lg};
    font-weight: ${({ theme }) => theme.fontWeights.lg};
  }
`;
