/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Header = styled.div`
  background-color: ${({ theme }) => theme.backgroundColors.blue2};
  height: 50px;

  .header_text {
    ${({ theme }) => theme.defaultLayout()};

    span {
      line-height: 50px;
      color: white;
      font-size: ${({ theme }) => theme.fontSizes.xxxl};
    }
  }
`;

export const SelectAndSearch = styled.div`
  ${({ theme }) => theme.defaultLayout()};
  margin-top: 30px;
  display: flex;
  justify-content: space-between;

  select {
    width: 80px;
    height: 30px;
    padding-left: 10px;
  }

  .search_wrap {
    display: flex;
    gap: 10px;

    input {
      width: 170px;
      height: 30px;
    }

    button {
      background-color: ${({ theme }) => theme.backgroundColors.blue2};
      color: white;
      border: 0;
      padding: 5px 15px;
    }
  }
`;

export const TableWrap = styled.div`
  ${({ theme }) => theme.defaultLayout()};
  margin-top: 30px;
  table {
    width: 100%;
    color: ${({ theme }) => theme.colors.black3};

    thead {
      background-color: ${({ theme }) => theme.backgroundColors.gray2};
      height: 40px;
      line-height: 40px;
      text-align: center;
    }

    tbody tr{
      height: 50px;
      line-height: 50px;
      text-align: center;

      .coin_name {
        height:100%;
        display: flex;
        align-items: center;
        padding-left:30px;

        .coin_img {
          height: 30px;
          vertical-align:middle;
        }

        .text {
            margin-left:5px;
            line-height:1.3;
          }
        }
      }
    }
    td:nth-of-type(1) {
      width: 20%;
    }
    td:nth-of-type(2) {
      width: 60%;
    }
    td:nth-of-type(3) {
      width: 20%;
    }
  }
`;
