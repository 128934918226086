/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => theme.defaultLayout()}
  display:grid;
  grid-template-columns: 1.3fr 1fr 1fr 1.2fr;
  gap:10px;
  grid-template-areas:
    "chart    chart      chart           searchmarket"
    "trade    orderbook  transactionlog  searchmarket"
    "coininfo coininfo   orderlog        orderlog";
  }
  margin-bottom:30px;

 & > div:nth-of-type(1) {
    grid-area:chart;
  }
 & > div:nth-of-type(2) {
    grid-area:trade;
  }
 & > div:nth-of-type(3) {
    grid-area:orderbook;
  }
 & > div:nth-of-type(4) {
    grid-area:transactionlog;
  }
 & > div:nth-of-type(5) {
    grid-area:searchmarket;
  }
 & > div:nth-of-type(6) {
    grid-area:coininfo;
  }
 & > div:nth-of-type(7) {
    grid-area:orderlog;
  }
`;
