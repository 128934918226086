/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const slidePost = createAsyncThunk(
  'post/slide',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `post/get_post?brd_key=disclosure&select=title`,
      );
      return response.data.result.list;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
