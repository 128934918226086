import React from 'react';
import StakingContainer from '../container/StakingContainer';
import FooterLayout from '../layout/FooterLayout';
import HeaderLayout from '../layout/HeaderLayout';

function Staking() {
  return (
    <>
      <HeaderLayout />
      <StakingContainer />
      <FooterLayout />
    </>
  );
}

export default Staking;
