/* eslint-disable consistent-return */
import React from 'react';
import { useLocation } from 'react-router-dom';
import FooterLayout from '../layout/FooterLayout';
import HeaderLayout from '../layout/HeaderLayout';
import InfoContainer from '../container/InfoContainer';
import ConfirmContainer from '../container/ConfirmContainer';
import SecurityContainer from '../container/SecurityContainer';
import OpenapiContainer from '../container/OpenapiContainer';

function Mypage() {
  const location = useLocation();

  const childpage = () => {
    switch (location.pathname) {
      case '/mypage/info':
        return <InfoContainer />;
      case '/mypage/confirm':
        return <ConfirmContainer />;
      case '/mypage/security':
        return <SecurityContainer />;
      case '/mypage/openapi':
        return <OpenapiContainer />;
      default:
        break;
    }
  };
  return (
    <>
      <HeaderLayout />
      {childpage()}
      <FooterLayout />
    </>
  );
}

export default Mypage;

// 개인정보 : info
// 인증센터 : confirm
// 보안센터 : security
// open api : openapi
