/* eslint-disable no-useless-escape */
const reg = {
  email:
    /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i,
  password:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?+=&])[A-Za-z\d@$!%*?+=&]{8,20}$/,
  nickname: /^[0-9a-z]{3,20}$/,
  ipAdress:
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
  number: /[^.0-9]/g,
  comma: (num = '0') => {
    return num.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  },
};

export default reg;
