/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import {
  addOrder,
  closeOrder,
  coinIntroduce,
  cryptocurrency,
  getFavorInfo,
  getUserInfo,
  updateFavor,
} from '../actions/exchange';

export const initialState = {
  currentCoin: '',
  orderBook: '',
  coinList: '',
  tapCoinList: '',
  completeCoinInfo: '',
  transactionLog: '',
  conclusionOrder: '',
  notConclusionOrder: '',
  userInfo: '',
  userInfoLoading: false,
  userInfoDone: false,
  userInfoError: null,
  orderLoading: false,
  orderDone: false,
  orderError: null,
  tradeInfoLoading: false,
  tradeInfoDone: false,
  tradeInfoError: null,
  coinInfoLoading: false,
  coinInfoDone: false,
  coinInfoError: null,
  closeOrderLoading: false,
  closeOrderDone: false,
  closeOrderError: null,
  updateFavorLoading: false,
  updateFavorDone: false,
  updateFavorError: null,
  allUpdateFavorLoading: false,
  allUpdateFavorDone: false,
  allUpdateFavorError: null,
};

const exchangeSlice = createSlice({
  name: 'exchange',
  initialState,
  reducers: {
    setCurrentCoin: (state, action) => {
      state.currentCoin =
        state.coinList &&
        state.coinList.filter(item => item.type === action.payload)[0];
    },
    setOrderBook: (state, action) => {
      state.orderBook = action.payload;
    },
    setCoinList: (state, action) => {
      state.coinList = action.payload.data;
      state.tapCoinList = action.payload.data;
      state.currentCoin =
        state.currentCoin === ''
          ? action.payload.data.filter(
              item => item.type === action.payload.type,
            )[0]
          : state.currentCoin;
    },
    setTapCoinList: (state, action) => {
      if (action.payload === 'ALL') {
        state.tapCoinList = state.coinList;
      } else if (action.payload === '즐겨찾기') {
        state.tapCoinList = state.coinList.filter(item => item.favor === '1');
      } else {
        state.tapCoinList = state.coinList.filter(
          item => item.ccs_code === action.payload,
        );
      }
    },
    setTapFavorList: (state, action) => {
      const idx = state.tapCoinList.findIndex(
        item => item.coin_name === action.payload,
      );
      state.coinList[idx].favor = state.coinList[idx].favor === '1' ? '0' : '1';
    },
    setCompleteCoinInfo: (state, action) => {
      state.coinList = '';
      state.completeCoinInfo = action.payload;
      const idx = state.tapCoinList.findIndex(
        item => item.coin_name === action.payload.coin_name,
      );
      state.tapCoinList[idx] = action.payload;
    },
    setTransactionLog: (state, action) => {
      state.transactionLog = action.payload;
    },
    setConclusionOrder: (state, action) => {
      state.conclusionOrder = action.payload;
    },
    setNotConclusionOrder: (state, action) => {
      state.notConclusionOrder = action.payload;
    },
  },
  extraReducers: builder =>
    builder
      // 매수 or 매도 요청
      .addCase(addOrder.pending, state => {
        state.orderLoading = true;
        state.orderDone = false;
        state.orderError = null;
      })
      .addCase(addOrder.fulfilled, (state, action) => {
        state.orderLoading = false;
        state.orderDone = action.payload;
      })
      .addCase(addOrder.rejected, (state, action) => {
        state.orderLoading = false;
        state.orderError = action.payload;
      })
      // 거래소 유저 정보 요청
      .addCase(getUserInfo.pending, state => {
        state.userInfoLoading = true;
        state.userInfoDone = false;
        state.userInfoError = null;
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.userInfoLoading = false;
        state.userInfoDone = action.payload;
      })
      .addCase(getUserInfo.rejected, (state, action) => {
        state.userInfoLoading = false;
        state.userInfoError = action.payload;
      })
      // 소수점, 수수료 정보 요청
      .addCase(cryptocurrency.pending, state => {
        state.tradeInfoLoading = true;
        state.tradeInfoDone = false;
        state.tradeInfoError = null;
      })
      .addCase(cryptocurrency.fulfilled, (state, action) => {
        state.tradeInfoLoading = false;
        state.tradeInfoDone = action.payload;
      })
      .addCase(cryptocurrency.rejected, (state, action) => {
        state.tradeInfoLoading = false;
        state.tradeInfoError = action.payload;
      })
      // 코인 소개 데이터 요청
      .addCase(coinIntroduce.pending, state => {
        state.coinInfoLoading = true;
        state.coinInfoDone = false;
        state.coinInfoError = null;
      })
      .addCase(coinIntroduce.fulfilled, (state, action) => {
        state.coinInfoLoading = false;
        state.coinInfoDone = action.payload;
      })
      .addCase(coinIntroduce.rejected, (state, action) => {
        state.coinInfoLoading = false;
        state.coinInfoError = action.payload;
      })
      // 미체결주문 취소 요청
      .addCase(closeOrder.pending, state => {
        state.closeOrderLoading = true;
        state.closeOrderDone = false;
        state.closeOrderError = null;
      })
      .addCase(closeOrder.fulfilled, (state, action) => {
        state.closeOrderLoading = false;
        state.closeOrderDone = action.payload;
      })
      .addCase(closeOrder.rejected, (state, action) => {
        state.closeOrderLoading = false;
        state.closeOrderError = action.payload;
      })
      // 코인 즐겨찾기  요청
      .addCase(updateFavor.pending, state => {
        state.updateFavorLoading = true;
        state.updateFavorDone = false;
        state.updateFavorError = null;
      })
      .addCase(updateFavor.fulfilled, (state, action) => {
        state.updateFavorLoading = false;
        state.updateFavorDone = action.payload;
      })
      .addCase(updateFavor.rejected, (state, action) => {
        state.updateFavorLoading = false;
        state.updateFavorError = action.payload;
      })
      // 코인 즐겨찾기 전체 리스트 요청
      .addCase(getFavorInfo.pending, state => {
        state.allUpdateFavorLoading = true;
        state.allUpdateFavorDone = false;
        state.allUpdateFavorError = null;
      })
      .addCase(getFavorInfo.fulfilled, (state, action) => {
        state.allUpdateFavorLoading = false;
        state.allUpdateFavorDone = action.payload;
      })
      .addCase(getFavorInfo.rejected, (state, action) => {
        state.allUpdateFavorLoading = false;
        state.allUpdateFavorError = action.payload;
      }),
});

export const {
  setCurrentCoin,
  setOrderBook,
  setCoinList,
  setTapCoinList,
  setTapFavorList,
  setCompleteCoinInfo,
  setTransactionLog,
  setConclusionOrder,
  setNotConclusionOrder,
} = exchangeSlice.actions;
export default exchangeSlice.reducer;
