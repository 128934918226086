/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const ContentHeader = styled.div`
  padding: 10px;
  background-color: ${({ theme }) => theme.backgroundColors.lightGray3};

  span {
    display: inline-block;
    text-align: center;
    width: 20%;

    &:nth-of-type(1) {
      width: 60%;
    }
  }
`;

export const Content = styled.div`
  ul {
    padding: 10px;
    border-bottom: 1px solid #ccc;

    li {
      display: inline-block;
      text-align: center;
      width: 20%;

      &:nth-of-type(1) {
        width: 60%;
      }
    }

    .text_left {
      text-align: left;
    }
  }
`;
