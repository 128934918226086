/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const OtpFormWrap = styled.div`
  width: 500px;
  border: 1px solid #ccc;
  padding: 30px;
  margin: 0 auto;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    p {
      font-size: ${({ theme }) => theme.fontSizes.title};
      font-weight: ${({ theme }) => theme.fontWeights.xl};
      color: ${({ theme }) => theme.colors.blue3};
    }

    img {
      height: 25px;
    }
  }

  .subtitle {
    font-size: ${({ theme }) => theme.fontSizes.xxxl};
    margin-bottom: 20px;
  }

  .guide_text {
    font-size: ${({ theme }) => theme.fontSizes.lg};
    color: ${({ theme }) => theme.colors.gray2};
    margin-bottom: 30px;
    padding-left: 30px;
  }

  .qr_wrap {
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      margin-bottom: 20px;
    }

    .qrcode {
      width: 100%;
      height: 40px;
      text-align: center;
      line-height: 40px;
      background-color: ${({ theme }) => theme.backgroundColors.gray1};
      align-self: flex-end;
    }
  }

  .input_text {
    margin-top: 30px;
    margin-bottom: 5px;
    color: ${({ theme }) => theme.colors.gray2};
  }

  .email_input {
    width: 40%;
    height: 40px;
    vertical-align: 1px;
  }

  .regist_email {
    height: 40px;
    border: 0;
    color: white;
    padding: 0 15px;
    background-color: ${({ theme }) => theme.backgroundColors.blue2};
  }

  .otp_input {
    width: 100%;
    height: 40px;
  }

  .copy_otpcode {
    width: 60%;
    height: 40px;
    vertical-align: 1px;
  }

  .btns {
    margin-top: 20px;
    text-align: right;

    button {
      width: 70px;
      height: 30px;
      border: 0;
      color: white;
      background-color: ${({ theme }) => theme.backgroundColors.blue2};
      margin-left: 5px;
    }
  }
`;
