import React from 'react';
import { useParams } from 'react-router-dom';

import ExchangeChart from '../components/ExchangeChart';
import ExchangeCoinInfo from '../components/ExchangeCoinInfo';
import ExchangeDatas from '../components/ExchangeDatas';
import ExchangeHeader from '../components/ExchangeHeader';
import ExchangeOrderBook from '../components/ExchangeOrderBook';
import ExchangeOrderLog from '../components/ExchangeOrderLog';
import ExchangeSearchMarket from '../components/ExchangeSearchMarket';
import ExchangeTrade from '../components/ExchangeTrade';
import ExchangeTransactionLog from '../components/ExchangeTransactionLog';
import SlidePost from '../components/SlidePost';
import * as S from '../styles/ExchangeContainer.style';

function ExchangeContainer() {
  const { coinname } = useParams();
  const [market, coin] = coinname.split('_');
  return (
    <>
      <ExchangeDatas coin={coin} market={market} />
      <SlidePost />
      <ExchangeHeader />
      <S.Container>
        <ExchangeChart coin={coin} market={market} />
        <ExchangeTrade coin={coin} />
        <ExchangeOrderBook />
        <ExchangeTransactionLog />
        <ExchangeSearchMarket />
        <ExchangeCoinInfo />
        <ExchangeOrderLog coin={coin} />
      </S.Container>
    </>
  );
}

export default ExchangeContainer;
