/* eslint-disable eqeqeq */
/* eslint-disable consistent-return */
import React from 'react';
import { useLocation } from 'react-router-dom';
import AssetContainer from '../container/AssetContainer';
import DepositContainer from '../container/DepositContainer';
import WithdrawContainer from '../container/WithdrawContainer';
import RecordContainer from '../container/RecordContainer';
import DwHistoryContainer from '../container/DwHistoryContainer';
import FooterLayout from '../layout/FooterLayout';
import HeaderLayout from '../layout/HeaderLayout';
import NotFound from './NotFound';

function Wallet() {
  return (
    <>
      <HeaderLayout />
      <Childpage />
      <FooterLayout />
    </>
  );
}

function Childpage() {
  const location = useLocation();

  switch (location.pathname) {
    case '/wallet/asset':
      return <AssetContainer />;
    case '/wallet/deposit':
      return <DepositContainer />;
    case '/wallet/withdraw':
      return <WithdrawContainer />;
    case '/wallet/record':
      return <RecordContainer />;
    case '/wallet/dwhistory':
      return <DwHistoryContainer />;
    default:
      return <NotFound />;
  }
}
export default Wallet;

// 자산현황 : asset
// 입금 : deposit
// 출금 : withdraw
// 거래내역 : record
// 입출금내역 : dwhistory
