import styled, { css } from 'styled-components';

export const TradeBox = styled.div`
  height: 430px;
  border: 1px solid black;
  padding: 10px 15px;
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

export const TabList = styled.div`
  button {
    border: 0;
    background-color: transparent;
    padding: 8px 15px;
    font-size: ${({ theme }) => theme.fontSizes.base};

    ${({ tradeState }) =>
      tradeState === 'Buy'
        ? css`
            &:nth-of-type(1) {
              color: ${({ theme }) => theme.colors.pink1};
            }
          `
        : css`
            &:nth-of-type(2) {
              color: ${({ theme }) => theme.colors.blue1};
            }
          `}
  }
`;

export const OrderForm = styled.div`
  margin-top: 20px;

  .content {
    display: flex;
    margin-bottom: 20px;
    align-items: center;

    span:nth-of-type(1) {
      display: inline-block;
      width: 80px !important;
      color: ${({ theme }) => theme.colors.gray2};
    }

    .input_wrap {
      width: 78%;
      position: relative;

      input {
        border: 1px solid #ccc;
      }

      .order_price {
        width: 100%;
        height: 35px;
        border-radius: 5px;
      }

      .order_count {
        width: 150px;
        height: 35px;
        padding-right: 35px;
        border-radius: 5px;
      }

      span {
        position: absolute;
        left: 115px;
        top: 11px;
      }
    }

    .percent {
      width: 78%;
      border: 2px solid #ccc;
      padding: 5px;
      border-radius: 5px;

      button {
        width: 25%;
        border: 0;
        background-color: transparent;

        &:not(:last-child) {
          border-right: 1px solid #ccc;
        }

        &: nth-of-type(${({ percent }) => percent}) {
          background-color: ${({ theme }) => theme.backgroundColors.gray2};
        }
      }
    }
  }

  .max_count {
    display: inline-block;
    color: ${({ theme }) => theme.colors.gray2};
    margin-bottom: 20px;
  }
`;

export const SubmitInfo = styled.div`
  margin-top: 15px;

  .info {
    display: flex;
    margin-bottom: 15px;

    span:nth-of-type(1) {
      display: inline-block;
      width: 80px;
      color: ${({ theme }) => theme.colors.gray2};
    }
  }

  .guide_text {
    display: inline-block;
    width: 100%;
    color: ${({ theme }) => theme.colors.gray2};
    font-size: ${({ theme }) => theme.fontSizes.xs};
    margin-bottom: 5px;
    text-align: right;
  }

  .submit {
    width: 100%;
    height: 42px;
    border: 0;
    color: white;
    font-size: ${({ theme }) => theme.fontSizes.base};
    font-weight: ${({ theme }) => theme.fontWeights.lg};
    border-radius: 5px;
    ${({ tradeState }) =>
      tradeState === 'Buy'
        ? css`
            background-color: ${({ theme }) => theme.backgroundColors.pink1};
          `
        : css`
            background-color: ${({ theme }) => theme.backgroundColors.blue1};
          `}
  }
`;
