import { createSlice } from '@reduxjs/toolkit';
import { getMenuList } from '../actions/menu';

export const initialState = {
  menuList: [],
  loadListDone: false,
  loadListError: null,
  loadListLoading: false,
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setSlideList: (state, action) => {
      state.slideList = action.payload;
    },
  },
  extraReducers: builder =>
    builder
      // 메인페이지 대메뉴 소메뉴
      .addCase(getMenuList.pending, state => {
        state.loadListLoading = true;
        state.loadListDone = false;
        state.loadListError = null;
      })
      .addCase(getMenuList.fulfilled, (state, action) => {
        state.loadListLoading = false;
        state.loadListDone = true;
        state.menuList = action.payload.info;
      })
      .addCase(getMenuList.rejected, (state, action) => {
        state.loadListLoading = false;
        state.loadListError = action.error.message;
      }),
});

export const { setSlideList } = menuSlice.actions;
export default menuSlice.reducer;
