import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from '../styles/CoinInfoCommunity.style';

function CoinInfoCommunity() {
  const { t } = useTranslation();

  return (
    <>
      <S.ContentHeader>
        <span className="text_left">{t('exchange1')}</span>
        <span>{t('exchange2')}</span>
        <span>{t('exchange3')}</span>
        <span>{t('exchange4')}</span>
        <span>{t('exchange5')}</span>
      </S.ContentHeader>
      <S.Content>
        <ul>
          <li className="text_left">커뮤니티 글3</li>
          <li>닉네임</li>
          <li>22/08/01</li>
          <li>1234</li>
          <li>123456</li>
        </ul>
      </S.Content>
      <S.WriteBtn>{t('exchange6')}</S.WriteBtn>
    </>
  );
}

export default CoinInfoCommunity;
