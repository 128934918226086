import { css } from 'styled-components';

const calcRem = size => `${size / 16}rem`;

const fontSizes = {
  xs: calcRem(12),
  small: calcRem(14),
  base: calcRem(16),
  lg: calcRem(18),
  xl: calcRem(20),
  xxl: calcRem(22),
  xxxl: calcRem(24),
  title: calcRem(28),
  titleL: calcRem(32),
  titleXL: calcRem(36),
};
const fontWeights = {
  small: '300',
  base: '400',
  lg: '500',
  xl: '600',
  xxl: '800',
};
const colors = {
  black1: '#000000',
  black2: '#2b2b2b',
  black3: '#616161',
  blue1: '#02a4ee',
  blue2: '#1c3149',
  blue3: '#070cc1',
  pink1: '#ff5b6e',
  white1: '#ffffff',
  gray1: '#a4a4a4',
  gray2: '#aeb3bb',
  gray3: '#999',
  gray4: '#a7a7a7',
  orange1: '#ff8435',
  red1: '#fc1c1c',
};

const backgroundColors = {
  lightGray1: '#f7f9fb',
  lightGray2: '#f8f8f9',
  lightGray3: '#f9f9f9',
  lightGray4: '#fbfbfb',
  gray1: '#dbdbdb',
  gray2: '#efefef',
  black1: '#808080',
  blue1: '#0d7de7',
  blue2: '#2e307c',
  blue3: 'rgba(18, 97, 196, 0.08)',
  pink1: '#f75467',
  pink2: 'rgba(200, 74, 49, 0.08)',
  beige1: '#fff3d2',
  beige2: '#fffcf4',
};

const layoutWidths = {
  defaultWidth: '1360px',
};

const defaultLayout = () => {
  return css`
    max-width: ${layoutWidths.defaultWidth};
    margin: 0 auto;
  `;
};

const deviceSizes = {
  mobile: '375px',
  tablet: '768px',
  tabletL: '1024px',
  laptop: '1440px',
  laptopL: '1919px',
};

const device = {
  mobile: `screen and (max-width:${deviceSizes.mobile})`,
  tablet: `screen and (max-width:${deviceSizes.tablet})`,
  tabletL: `screen and (max-width:${deviceSizes.tabletL})`,
  laptop: `screen and (max-width:${deviceSizes.laptop})`,
  laptopL: `screen and (max-width:${deviceSizes.laptopL})`,
};

const theme = {
  fontSizes,
  colors,
  fontWeights,
  layoutWidths,
  backgroundColors,
  deviceSizes,
  device,
  defaultLayout,
};

export default theme;
