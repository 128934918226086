/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Contents = styled.div`
  color: ${({ theme }) => theme.colors.black3};
  white-space: pre-line;
  .top_content {
    display: flex;

    .token_img {
      width: 50%;
    }

    .content {
      .text_wrap {
        display: flex;
        align-items: center;

        &:nth-of-type(1) {
          height: 40%;
        }

        &:nth-of-type(2) {
          height: 60%;
        }

        h4,
        span {
          padding: 15px;
        }

        h4 {
          width: 80px;
        }

        .nation {
          width: 90px;
        }

        .margin_left {
          margin-left: 10px;
        }
      }
    }
  }

  .bottom_content {
    .text_wrap {
      display: flex;
      align-items: center;

      .left_text,
      .right_text {
        display: flex;
        align-items: center;
        flex: 5;
      }

      h4,
      span {
        padding: 15px;
      }

      h4 {
        width: 150px;
      }
    }
  }

  .btns {
    text-align: right;

    button {
      padding: 5px 20px;
      margin: 0 5px;
      border-radius: 5px;
      border: 1px solid #ccc;
    }
  }

  .detail {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);

    .detail_content {
      position: absolute;
      top: 50%;
      left: 50%;
      white-space: pre-line;
      transform: translate(-50%, -50%);
      width: 800px;
      background-color: white;

      .top_content_wrap {
        overflow-y: scroll;
        height: 800px;
      }
      .text_wrap {
        padding: 20px 0;
      }

      .close {
        width: 100%;
        text-align: right;
        padding: 10px; 0;

        button {
          padding: 8px 30px;
          margin: 0 5px;
          border-radius: 5px;
          border: 1px solid #ccc;
        }
      }
    }
  }
`;
