import React from 'react';
import WalletHeader from '../components/WalletHeader';

function DwHistoryContainer() {
  return (
    <>
      <WalletHeader />
      <div>Dw</div>
    </>
  );
}
export default DwHistoryContainer;
